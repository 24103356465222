var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        to: { name: _vm.projectPage, params: { projectSlug: _vm.project.slug } }
      }
    },
    [
      _c(
        "v-layout",
        { staticClass: "mx-0", attrs: { wrap: "", row: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", lg6: "" } },
            [
              _vm.project.imageUrl
                ? _c(
                    "v-img",
                    {
                      staticClass: "white--text",
                      attrs: { height: "230px", src: _vm.project.imageUrl }
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline align-end fill-height" },
                        [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.project.name) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "pa-4",
              staticStyle: { "text-align": "center" },
              attrs: { xs12: "", lg6: "" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "flex-direction": "column",
                    height: "100%"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.projectStatsAreFetched,
                          expression: "!projectStatsAreFetched"
                        }
                      ]
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("project.card.loading_stats")) +
                          "... "
                      )
                    ]
                  ),
                  _c(
                    "v-fade-transition",
                    [
                      _vm.projectStatsAreFetched
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "mx-0",
                              staticStyle: { "align-content": "start" },
                              attrs: { wrap: "", row: "" }
                            },
                            _vm._l(_vm.stats, function(value, name) {
                              return _c(
                                "v-flex",
                                {
                                  key: name,
                                  staticStyle: { "flex-shrink": "1" },
                                  attrs: { xs6: "", "py-2": "" }
                                },
                                [
                                  _c("h3", { staticClass: "subtitle-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.getTranslatedStatsName(name))
                                    )
                                  ]),
                                  _c("h3", { staticClass: "title" }, [
                                    _vm._v(_vm._s(value))
                                  ])
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    _vm._l(_vm.availableRoutes, function(route) {
                      return _c(
                        "v-btn",
                        {
                          key: route.name,
                          attrs: {
                            icon: "",
                            to: {
                              name: route.name,
                              params: { projectSlug: _vm.project.slug }
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(_vm._s(route.icon))])],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }