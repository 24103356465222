var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state.loggedIn
    ? _c(
        "v-layout",
        { staticClass: "pa-4", attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-4 pa-4" },
                [_c("user-profile")],
                1
              )
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "" } }, [_c("projects")], 1)
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }