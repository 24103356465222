










































































import Vue from 'vue';
import { state } from '@/store';
import { login } from '@/utils/auth';
import {
  handleErrors,
  showSuccessNotification, showWarningNotification,
} from '@/utils/notifications';
import PasswordField from '@/components/PasswordField.vue';
import EmailField from '@/components/EmailField.vue';
import client from '@/client';

export default Vue.extend({
  name: 'LoginDialog',

  components: { EmailField, PasswordField },

  data() {
    return {
      state,
      failedLogin: false,
      email: '',
      password: '',
      errors: [],
    };
  },

  computed: {
    show(): boolean {
      return state.loggedIn === false
        && this.$router.currentRoute.name !== 'reset-password'
        && this.$router.currentRoute.name !== 'create-account';
    }
  },

  methods: {
    async resetPassword() {
      //@ts-ignore
      if (!this.email || this.$validation.email.filter(fn => fn(this.email) !== true).length > 0) {
        showWarningNotification('Fill a valid email address');
        return;
      }

      const { errors, ok } = await client.resetPassword(this.email);
      if (ok) {
        showSuccessNotification(this.$t('authentication.login_form.password_reset_sent'), 5000);
      } else {
        handleErrors(errors);
        this.failedLogin = true;
      }
    },

    async submit() {
      //@ts-ignore
      if (await this.$refs.form.validate()) {
        await login(this.email, this.password);
      }
    },
  },
});
