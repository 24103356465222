var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    _vm._b(
      {
        style:
          "background-color: " +
          _vm.tag.color +
          " !important; color: " +
          _vm.textColor +
          " !important;"
      },
      "v-chip",
      _vm.$attrs,
      false
    ),
    [_vm._t("default", [_c("b", [_vm._v(_vm._s(_vm.tag.text))])])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }