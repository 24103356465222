import intersection from 'lodash/intersection';
import {captureException} from "@/utils/debugging";
const STORE_KEY = "textmanager.00001.prefs.locale";

function sortFromLongToShort(array: any[]) {
  return [...array].sort((a, b) => b.length - a.length);
}

function getBrowserLanguages() {
  // See https://stackoverflow.com/a/46514247/8469046
  const nav = window.navigator;
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  let language;
  let i;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages) && nav.languages.length) {
    return sortFromLongToShort(nav.languages);
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    // @ts-ignore
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return [language];
    }
  }

  return [];
}

function findMatch(a1: string[], a2: string[]): string | undefined {
  return intersection(a1, a2).find(v => v);
}

function findMatchContains(a1: string[], a2: string[]): string | undefined {
  return a1.filter(s1 => a2.find(s2 => s1.includes(s2) || s2.includes(s1)))
    .find(v => v)
}

function getLanguageCodeFromBrowser(options: string[]) {
  const upperArray = (a: string[]): string[] => a.map(s => s.toUpperCase());
  const browserLanguages = upperArray(getBrowserLanguages());
  const optionsUpper = upperArray(options);
  return findMatch(browserLanguages, optionsUpper)
    || findMatchContains(optionsUpper, browserLanguages);
}

// const loadMessages = (function() {
//   const loadedLanguages = [config.fallbackLocale];
//   return async locale => {
//     if (loadedLanguages.includes(locale)) {
//       return Promise.resolve()
//     }
//
//     const module = await import(`../messages/${locale}`);
//     const importedMessages = module.default;
//     vueI18n.setLocaleMessage(locale, importedMessages);
//     loadedLanguages.push(locale);
//     return Promise.resolve();
//   }
// })();

function setLocaleToHtml(locale: string) {
  try {
    // @ts-ignore
    document.querySelector('html').setAttribute('lang', locale)
  } catch (e) {
    console.warn(e);
  }
}

function setLocaleToStorage(locale: string) {
  localStorage.setItem(STORE_KEY, locale);
}

function getLocaleFromStorage(): string | null {
  return localStorage.getItem(STORE_KEY);
}

function getISO639Locale(code) {
  if (!code) {
    captureException(new Error("getISO639Locale was called without code"));
    return ""
  }
  return code.toLowerCase().split("-")[0]
}

export {
  getISO639Locale,
  getLocaleFromStorage,
  setLocaleToStorage,
  getLanguageCodeFromBrowser,
  setLocaleToHtml,
}
