var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "language_select" },
    [
      _vm.label
        ? _c("v-label", [_vm._v(" " + _vm._s(_vm.label) + " ")])
        : _vm._e(),
      _c("v-select", {
        staticClass: "language-with-select-on-click",
        attrs: {
          value: _vm.value,
          items: _vm.items || _vm.defaultItems,
          "item-value": _vm.itemValue,
          "hide-details": "",
          dense: "",
          solo: ""
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(data) {
              return [_c("g-language-chip", { attrs: { language: data.item } })]
            }
          },
          {
            key: "selection",
            fn: function(data) {
              return [
                _c("g-language-chip", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: data.item.code,
                      expression: "data.item.code"
                    }
                  ],
                  attrs: { language: data.item }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }