var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: {
        "max-width": "400",
        label: _vm.$t("project_settings.users.add"),
        icon: "person_add",
        value: _vm.value
      },
      on: {
        input: function($event) {
          return _vm.$emit("input", false)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.roles.length === 0 || !_vm.email
                  },
                  on: { click: _vm.submit }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("project_settings.users.invite")) + " "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("input", false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("actions.close")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              required: "",
              "validate-on-blur": "",
              label: _vm.$t("users.email"),
              rules: _vm.$validation.email
            },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          }),
          _c("g-chip-select", {
            attrs: {
              label: _vm.$t("project_settings.users.roles"),
              items: _vm.items.map(function(i) {
                return { value: i, text: i }
              })
            },
            model: {
              value: _vm.roles,
              callback: function($$v) {
                _vm.roles = $$v
              },
              expression: "roles"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }