var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { top: "", disabled: !_vm.tooltipText },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "toggle-icon mx-1",
                        class: { pressed: _vm.value },
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("input", !_vm.value)
                          }
                        }
                      },
                      "v-btn",
                      _vm.$attrs,
                      false
                    ),
                    on
                  ),
                  [_c("v-icon", [_vm._t("default")], 2)],
                  1
                )
              ]
            }
          }
        ],
        null,
        true
      )
    },
    [_vm.tooltipText ? _c("span", [_vm._v(_vm._s(_vm.tooltipText))]) : _vm._e()]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }