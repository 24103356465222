<template>
  <g-modal
    :value="true"
    max-width="600"
    @input="hideModal"
  >
    <v-carousel
      height="360"
      hide-delimiter-background
      hide-delimiters
      touchless
      :show-arrows="false"
      :value="secret ? 2 : 1"
    >
      <v-carousel-item :value="1">
        <v-form @submit.prevent="submit">
          <v-card-title>{{ $t('api_keys.create') }}</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="formData.name"
              style="max-width: 350px"
              class="mt-4"
              :dark="this.$vuetify.theme.isDark"
              :light="!this.$vuetify.theme.isDark"
              :label="$t('global.name')"
            />
          </v-card-text>

          <button
            type="submit"
            style="display: none"
          />
        </v-form>
      </v-carousel-item>

      <v-carousel-item :value="2">
        <display-secret :secret="secret" />
      </v-carousel-item>
    </v-carousel>

    <template #actions>
      <div class="actions">
        <v-btn
          v-if="secret"
          color="primary"
          @click.prevent="$emit('input', false)"
        >
          {{ $t('actions.close') }}
        </v-btn>

        <v-btn
          v-else
          color="primary"
          type="submit"
          @click.prevent="submit"
        >
          {{ $t('actions.add') }}
        </v-btn>
      </div>
    </template>
  </g-modal>
</template>

<script>
import { handleErrors } from '@/utils/notifications';
import DisplaySecret from '@/components/develop/DisplaySecret';
import client from '@/client';
import events from '@/events';

export default {
  name: 'CreateApiKeyDialog',

  components: { DisplaySecret },

  data() {
    return {
      secret: null,
      formData: {
        name: '',
        roles: ['admin'],
      },
    }
  },

  computed: {
    routes() {
      return this.$router.options.routes
        .find(route => route.name === 'project-wrapper').children
    },

    items() {
      return this.routes
        .flatMap(({meta}) => meta && meta.roles)
        // Filter empty, duplicates and admin (admin is not a role)
        .filter((v, i, arr) =>  !!v && arr.indexOf(v) === i)
    },
  },

  methods: {
    hideModal() {
      setTimeout(() => {
        this.$emit('input', false)
      }, 500)
    },

    async submit() {
      this.secret = null; // unmount modal
      const { ok, secret, errors } = await client.createApiKey({
        name: this.formData.name,
        roles: this.formData.roles,
      });
      if (ok && secret) {
        this.secret = secret;
      } else {
        handleErrors(errors);
      }

      events.createdApiKey.emit();
    },
  },
};
</script>

<style>
  .v-carousel__controls {
    display: none;
  }
</style>
