import { showSuccessNotification } from '@/utils/notifications';

export default function displayAddAssetTip() {
  const KEY = "textmanager.000100.displayed-asset-tip";
  const value = localStorage.getItem(KEY);
  const lastDisplayed = value && new Date(value);
  const now = new Date();
  const day = 86400000;
  const diplayAfterXDays = 1;

  if (!lastDisplayed || lastDisplayed.getTime() < (now.getTime() - (day * diplayAfterXDays))) {
    localStorage.setItem(KEY, now.toISOString());
    showSuccessNotification("tip: Press Enter to add a new asset");
  }
}
