<template>
  <g-modal
    :value="showDialog"
    max-width="500"
    :title="title"
    @input="close"
  >
    <v-textarea
      v-model="message"
      :label="label || $t('global.missing_something')"
      rows="10"
    />
    <template #actions>
      <v-btn
        class="mr-2 ml-auto"
        @click="close"
      >
        {{ $t('actions.cancel') }}
      </v-btn>

      <v-btn
        type="button"
        class="primary"
        @click="confirm"
      >
        {{ $t('actions.send') }}
      </v-btn>
    </template>
  </g-modal>
</template>

<script>
import sendMessage from '@/utils/sendMessage';
import events from '@/events';

export default {
  name: 'UserMessageDialog',

  data() {
    return {
      showDialog: false,
      title: null,
      message: null,
      label: null,
    };
  },

  created() {
    this.$handleOff(events.openMessageModal.on(options => {
      this.title = options && options.title;
      this.label = options && options.label;
      this.showDialog = true;
    }));
  },

  methods: {
    confirm() {
      sendMessage(this.message);
      this.close();
    },

    close() {
      this.showDialog = false;
      this.title = null;
      this.message = null;
      this.label = null;
    },
  },
};
</script>
