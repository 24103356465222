var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    _vm._b(
      {
        staticStyle: { "max-height": "500px" },
        attrs: { value: _vm.displayModal },
        on: {
          input: _vm.close,
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.close($event)
          }
        }
      },
      "v-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-tabs",
        {
          attrs: { dark: "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab", { attrs: { href: "#1" } }, [
            _vm._v(_vm._s(_vm.$t("assets.edit")))
          ]),
          _c("v-tab", { attrs: { href: "#2" } }, [
            _vm._v(_vm._s(_vm.$t("history.title")))
          ]),
          _c("v-tab", { attrs: { href: "#3" } }, [
            _vm._v(_vm._s(_vm.$t("comments.title")))
          ])
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "tab-items",
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { staticClass: "tab-items", attrs: { value: "1" } },
            [
              _vm.displayModal
                ? _c("edit-asset-form", {
                    staticClass: "tab-items",
                    attrs: { asset: _vm.asset },
                    on: { close: _vm.close }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "tab-items", attrs: { value: "2" } },
            [
              _c("history", {
                key: _vm.asset.id,
                staticClass: "tab-items",
                attrs: { "asset-id": _vm.asset.id }
              })
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "tab-items", attrs: { value: "3" } },
            [
              _c("comments", {
                key: _vm.asset.id,
                staticClass: "tab-items",
                attrs: { "asset-id": _vm.asset.id }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }