/// <reference types="typed-graphql" />
import gql from 'graphql-tag';
import fragments from '@/graphql/fragments';

const mutations = {

  login: gql`
    mutation login($input: LoginInput!) {
      login(loginInput: $input) {
        ok
        errors {
          field
          messages
        }
      }
    }`,

  resetPassword: gql`
    mutation resetPassword($input: ResetPasswordInput!) {
      resetPassword(resetPassword: $input) {
        ok
        errors {
          field
          messages
        }
      }
    }
  `,

  logout: gql`
    mutation logoutUser {
      logout {
        ok

        errors {
          field
          messages
        }
      }
    }
  `,

  updateUser: gql`
    mutation updateUser($input: UserUpdateGenericType!){
      updateUser(userInput: $input) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  createTranslation: gql`
    mutation createTranslation($input: TranslationCreateGenericType!){
      createTranslation(translationInput: $input) {
        ok

        translation {
          id
          value
          language {
            id
            code
          }
        }

        errors {
          field
          messages
        }
      }
    }`,

  updateTranslation: gql`
    mutation updateTranslation($input: TranslationUpdateGenericType!){
      updateTranslation(translationInput: $input) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  updateAsset: gql`
    mutation updateAsset($input: AssetUpdateGenericType!){
      updateAsset(assetInput: $input) {
        ok

        asset {
          id
          tags {
            id
          }
        }

        errors {
          field
          messages
        }
      }
    }`,

  deleteApiKey: gql`
    mutation deleteApiKey($id: ID!){
      deleteApikey(id: $id) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  deleteAsset: gql`
    mutation deleteAsset($id: ID!){
      deleteAsset(id: $id) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  createAsset: gql`
    mutation createAsset($input: AssetCreateGenericType!){
      createAsset(assetInput: $input) {
        ok
        asset {
          id
        }

        errors {
          field
          messages
        }
      }
    }`,

  setPassword: gql`
    mutation setPassword($password: String!){
      setPassword(password: $password) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  createTag: gql`
    mutation createTag($input: TagCreateGenericType!){
      createTag(tagInput: $input) {
        ok

        tag {
          ...tagFields
        }

        errors {
          field
          messages
        }
      }
    }
  ${fragments.tag}`,

  deleteTag: gql`
    mutation deleteTag($tagId: ID!){
      deleteTag(id: $tagId) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  createCreditCard: gql`
    mutation createCreditCard($input: CreateCreditCardInput!){
      createCreditCard(input: $input) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  confirmResetPassword: gql`
    mutation confirmResetPassword($input: ConfirmResetPasswordInput!){
      confirmResetPassword(input: $input) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  createAccount: gql`
    mutation createAccount($input: CreateAccountInput!){
      createAccount(input: $input) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  updateUserrole: gql`
    mutation updateUserrole($input: UserRoleUpdateGenericType!){
      updateUserrole(userRoleInput: $input) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  deleteUserRole: gql`
    mutation deleteUserRole($id: ID!){
      deleteUserrole(id: $id) {
        ok

        errors {
          field
          messages
        }
      }
    }`,

  importData: gql`
    mutation importData($input: ImportDataInput!) {
      importData(input: $input){
        ok

        errors {
          field
          messages
        }
      }
    }
  `,

  exportData: gql`
    mutation exportData($input: ExportDataInput!) {
      exportData(input: $input){
        ok
        errors {
          field
          messages
        }
        exportedData {
          url
        }
      }
    }
  `,

  inviteUser: gql`
    mutation inviteUser($input: InviteUserGenericType!){
      inviteUser(inviteInput: $input) {
        ok
        errors {
          field
          messages
        }
      }
    }`,

  createApiKey: gql`
    mutation createApiKey($input: CreateApiKeyInput!){
      createApiKey(input: $input){
        secret
        ok
        errors {
          field
          messages
        }
      }
    }
  `,

  createComment: gql`
    mutation createComment($input: CommentCreateGenericType!){
      createComment(commentInput: $input) {
        ok
        errors {
          field
          messages
        }
      }
    }`,

  orderMachineTranslation: gql`
    mutation orderMachineTranslation($input: OrderMachineTranslationsInput!){
      orderMachineTranslations(input: $input) {
        ok
        errors {
          field
          messages
        }
      }
    }`,

  createProject: gql`
    mutation createProject {
      createProject {
        project {
          ...projectDetailFields
        }

        ok
        errors {
          field
          messages
        }
      }
    }
  ${fragments.projectDetail}`,

  updateProject: gql`
    mutation updateProject ($input: ProjectUpdateGenericType!) {
      updateProject(projectInput: $input) {
        ok
        errors {
          field
          messages
        }
        project {
          ...projectDetailFields
        }
      }
    }
    ${fragments.projectDetail}`,

  obtainToken: gql`
    mutation obtainToken($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        token
        refreshToken
        refreshExpiresIn
      }
    }
  `,

  refreshToken: gql`
    mutation refreshToken($refreshToken: String){
      refreshJwt(refreshToken: $refreshToken) {
        token
        refreshExpiresIn
      }
    }
  `,

  revokeRefreshToken: gql`
    mutation revokeRefreshToken($refreshToken: String){
      revokeRefreshJwt(refreshToken: $refreshToken) {
        revoked
      }
    }
  `,
};

export default mutations;
