var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    ref: "email",
    attrs: {
      id: "email",
      value: _vm.value,
      "prepend-icon": "email",
      label: _vm.$t("users.email"),
      type: "text",
      name: "email",
      rules: _vm.$validation.email,
      "validate-on-blur": ""
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }