<template>
  <v-layout wrap>
    <v-flex
      xs12
      md8
      lg6
    >
      <translate-from :asset.sync="asset" />
      <translate-to
        :asset.sync="asset"
        @set-translation-id="translationId = $event"
      />
    </v-flex>

    <v-flex
      xs12
      md4
      lg6
      class="tab-section"
      style="max-height: 85vh"
    >
      <translate-tabs :translation-id="translationId" />
    </v-flex>
  </v-layout>
</template>

<script>
import MODELS from '@/constants/models';
import TranslateTo from '@/components/translate/TranslateTo';
import TranslateFrom from '@/components/translate/TranslateFrom';
import TranslateTabs from '@/components/translate/TranslateTabs';
import vueI18n from '@/plugins/i18n';

export default {
  name: 'Translate',

  metaInfo: {
    get title() { return vueI18n.t("view_meta.translate.title") },
  },

  components: { TranslateTabs, TranslateFrom, TranslateTo },

  data() {
    return {
      // Asset currently being translated
      asset: MODELS.asset,
      // id of current edited translation
      translationId: null,
    }
  },
};
</script>
