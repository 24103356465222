











































import Vue from 'vue';
import { state } from '@/store';
import { timeAgoInWords } from '@/utils/datetime';
import client from "@/client";
import {exportedDataFields} from "@/graphql/types/exportedDataFields";
import events from "@/events";

export default Vue.extend({
  name: 'PreviousExports',

  data() {
    return {
      exportedData: [] as exportedDataFields[],
      headers: [
        { text: 'Name' },
        { text: 'Created' },
        { text: 'Type' },
        { text: '' },
      ],
    };
  },

  created() {
    this.fetchExportedData();
    //@ts-ignore
    this.$handleOff(events.exportCreated.on(this.fetchExportedData));
  },

  methods: {
    timeAgoInWords,

    async fetchExportedData() {
      this.exportedData = await client.getExportedData(state.project.id);
    },
  },
});
