<template>
  <div class="language_select">
    <v-label v-if="label">
      {{ label }}
    </v-label>

    <v-select
      :value="value"
      :items="items || defaultItems"
      :item-value="itemValue"
      hide-details
      dense
      solo
      class="language-with-select-on-click"
      @input="$emit('input', $event)"
    >
      <template
        slot="item"
        slot-scope="data"
      >
        <g-language-chip :language="data.item" />
      </template>

      <template
        slot="selection"
        slot-scope="data"
      >
        <g-language-chip
          v-show="data.item.code"
          :language="data.item"
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import store, { state } from "@/store";

export default {
  name: 'GInlineLanguageSelect',

  props: {
    items: { type: Array, required: false, default: null },
    value: { type: String, required: false, default: null },
    itemValue: { type: String, required: false, default: "id" },
    label: { type: String, required: false, default: null },
    disableDefault: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      defaultItems: [],
    }
  },

  created() {
    store.onStateIsFetched(() => {
      this.defaultItems = state.project.languages;

      if (!this.value || this.defaultItems.length > 0 && !this.disableDefault) {
        this.$emit('input', this.defaultItems[0].id);
      }
    })
  }
};
</script>

<style lang="scss">
  .language_select {
    display: flex;
    align-items: center;
  }
</style>
