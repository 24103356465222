import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import MODELS from '@/constants/models';
import { captureException } from './utils/debugging';
import { getDarkMode } from '@/utils/darkMode';
import { GQLLanguageType, GQLProjectType, GQLUserRoleType } from "../types";
import { projectDetailFields } from "@/graphql/types/projectDetailFields";
import { tagFields } from "@/graphql/types/tagFields";
import chatclient from "@/utils/chatclient";
import client from "@/client";
import {getISO639Locale, setLocaleToHtml, setLocaleToStorage} from "@/utils/language";
import vueI18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import analytics from "@/plugins/analytics";
import { userFields } from "@/graphql/types/userFields";

const store = {
  state: {
    get isAdmin(): boolean {
      return store.getUserRolesForCurrentRoute().includes("admin")
    },

    loggedIn: null,

    showChangePassword: false,

    isMobile: false,

    darkMode: getDarkMode(),

    // All available languages
    languages: [] as GQLLanguageType[],

    // All available file formats (for export and soon import)
    fileFormats: [],

    // Currently selected project
    project: MODELS.project as projectDetailFields,

    // Current user
    user: MODELS.user,

    // Roles of current user for current project
    rolesForProject: [],
  } as Record<string, any>,

  initialState: {} as Record<string, any>,

  addTag(tag: tagFields) {
    return this.state.project.tags.push(tag);
  },

  setTagOptions(tags: tagFields[]) {
    this.state.project.tags = tags;
  },

  setLocale(locale: string) {
    if (!locale) {
      captureException(new Error(`locale is required, found ${locale}`));
      return;
    }

    if (!vueI18n.availableLocales.includes(locale)) {
      captureException(new Error(`locale must be one of ${vueI18n.availableLocales}, found ${locale}`));
      return;
    }

    const ISO639Locale = getISO639Locale(locale);
    vueI18n.locale = locale;
    vuetify.framework.lang.current = ISO639Locale;
    chatclient.setup(locale);
    const { id } = store.getUser();
    client.changeUserLocale(locale, id);
    setLocaleToStorage(locale);
    setLocaleToHtml(locale);
  },

  getTagOptions(): tagFields[] {
    return this.state.project.tags;
  },

  getProject(): GQLProjectType {
    return <GQLProjectType>this.state.project;
  },

  getUser() {
    return this.state.user;
  },

  getProjectId() {
    return this.getProject().id;
  },

  getAllLanguages(): GQLLanguageType[] {
    return this.state.languages;
  },

  getLanguageByCode(languageCode: string): GQLLanguageType {
    return store.getAllLanguages()
      .find((language: GQLLanguageType) => language.code === languageCode) || {} as GQLLanguageType
  },

  getLanguage(languageId: string): GQLLanguageType {
    return this.getAllLanguages()
      .find((language: GQLLanguageType) => language.id === languageId) || {} as GQLLanguageType
  },

  stateIsSet(includingProjectState = true) {
    return (!includingProjectState || this.state.project.id) &&
    this.state.user.id &&
    this.state.languages.length > 0 &&
    this.state.fileFormats.length > 0
  },

  onStateIsFetched(callback: Function, includingProjectState = true, tries: number = 0) {
    if (this.stateIsSet(includingProjectState)) {
      callback(this.state.project, this.state.user);
    } else {
      const TIMEOUT = 1000 * 0.1;
      const MAX_TIME = 1000 * 30;
      if (tries < (MAX_TIME / TIMEOUT)) {
        setTimeout(() => this.onStateIsFetched(callback, includingProjectState, tries + 1), TIMEOUT);
      } else {
        console.error("onStateIsFetched: COULDNT FETCH STATE");
        callback(this.state.project, this.state.user);
      }
    }
  },

  openChangePasswordModal() {
    this.state.showChangePassword = true;
  },

  closeChangePasswordModal() {
    this.state.showChangePassword = false;
  },

  resetState(key: string) {
    this.state[key] = this.initialState[key];
  },

  addProject(project: projectDetailFields) {
    this.state.user.projects.push({
      name: project.name,
      imageUrl: project.imageUrl,
      slug: project.slug,
      id: project.id,
    });
    this.state.user.roles.push({ roles: ['admin'], project, });
    this.setProject(project);
  },

  setProject(project: projectDetailFields | null) {
    if (!project || !project.slug) {
      captureException(new Error(`store.setProject' was called without 'project.slug'. Found ${JSON.stringify(project)}`));
      return;
    }
    analytics.setProject(project);
    this.state.project = project;
  },

  login(user: userFields) {
    this.state.user = user;
    this.state.loggedIn = true;
    this.state.showChangePassword = !this.state.user.isPasswordSet;
    this.setLocale(user.locale || 'en-GB');
    analytics.setUser(user);
  },

  logout() {
    this.resetState('user');
    this.resetState('project');
    this.resetState('rolesForProject');
    this.state.loggedIn = false;
  },

  getUserRolesForCurrentRoute() {
    return this.getUserRoles(this.state.project.slug);
  },

  getUserRoles(projectSlug: string) {
    if (!this.state.user.roles) {
      return []
    }

    return this.state.user.roles
      .filter((rolesPerProject: GQLUserRoleType) => rolesPerProject.project && rolesPerProject.project.slug === projectSlug)
      .flatMap((rolesPerProject: GQLUserRoleType) => rolesPerProject.roles);
  },

  updateProjectSlug(previousSlug: string, newSlug: string) {
    // update project role
    {
      const index = this.state.user.roles.findIndex((roles: GQLUserRoleType) => roles.project && roles.project.slug === previousSlug);
      this.state.user.roles[index].project.slug = newSlug;
    }
    // update project
    const index = this.state.user.projects.findIndex((project: any) => project.slug === previousSlug);
    this.state.user.projects[index].slug = newSlug;
  },

  getUserIsAdmin(projectSlug: string) {
    return this.getUserRoles(projectSlug).includes('admin');
  },

  setWindowWidth() {
    this.state.isMobile = window.innerWidth < 900;
  },
};

store.initialState = cloneDeep(store.state);
store.setWindowWidth();
window.addEventListener('resize', debounce(() => store.setWindowWidth()));

export default store;
export const { state } = store;
