<template>
  <div style="max-width: 100%">
    <v-label v-if="label">
      <div :style="`min-width: ${minLabelWidth}; z-index: 999`">{{ label }}:</div>
    </v-label>

    <div style="display: flex; flex-direction: row; align-items: center;">
      <div :style="`min-height: 51px; min-width: ${minInputWidth}; `">
        <v-text-field
          v-if="edit"
          ref="input"
          :value="value"
          dense
          text
          autofocus
          style="margin-top: -2px"
          v-bind="$attrs"
          @keypress.enter="toggleEdit()"
          @input="$emit('input', $event)"
        />
        <span
          v-else
          style="text-overflow: ellipsis"
        >{{ value || $t('global.unset') }}</span>
      </div>

      <v-btn
        icon
        style="margin-bottom: 26px"
        :disabled="edit && !fieldIsValid"
        @click="toggleEdit()"
      >
        <v-icon
          v-if="edit"
          small
        >
          checkmark
        </v-icon>
        <v-icon
          v-else
          small
        >
          edit
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GToggleTextField',

  props: {
    value: String,
    label: String,
    minLabelWidth: { type: String, default: "200px" },
    minInputWidth: { type: String, default: "200px" },
  },

  data() {
    return {
      edit: false,
      fieldIsValid: true,
    }
  },

  watch: {
    value() {
      this.fieldIsValid = this.$refs.input.validate();
    }
  },

  methods: {
    toggleEdit() {
      if (this.edit) {
        this.$emit('close')
      }

      this.edit = !this.edit
    },
  },
};
</script>
