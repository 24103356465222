var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", [
      _c("tbody", [
        _c("tr", [
          _c("td", [
            _vm._v(_vm._s(_vm.$t("usage.monthly_machine_limit")) + ":")
          ]),
          _c("td", { staticClass: "pl-4" }, [
            _vm._v(_vm._s(_vm.$n(_vm.usage.machineTranslationsLimit)))
          ])
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("usage.left_this_month")) + ":")]),
          _c("td", { staticClass: "pl-4" }, [
            _vm._v(_vm._s(_vm.$n(_vm.usage.machineTranslationsLeft)))
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }