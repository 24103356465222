var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: { value: _vm.showDialog, "max-width": "500", title: _vm.title },
      on: { input: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                { staticClass: "mr-2 ml-auto", on: { click: _vm.close } },
                [_vm._v(" " + _vm._s(_vm.$t("actions.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { type: "button" },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("actions.send")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-textarea", {
        attrs: {
          label: _vm.label || _vm.$t("global.missing_something"),
          rows: "10"
        },
        model: {
          value: _vm.message,
          callback: function($$v) {
            _vm.message = $$v
          },
          expression: "message"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }