import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {getLanguageCodeFromBrowser, getLocaleFromStorage} from '@/utils/language';
// @ts-ignore
import ENMessages from "@/messages/EN-US";
// @ts-ignore
import NLMessages from "@/messages/NL-NL";
// @ts-ignore
import TRMessages from "@/messages/TR";
import config from "@/constants/config";

Vue.use(VueI18n);

const DateFormat = {
  short: {
    year: 'numeric', month: 'short', day: 'numeric'
  },
  long: {
    year: 'numeric', month: 'short', day: 'numeric',
    weekday: 'short', hour: 'numeric', minute: 'numeric'
  }
};

const dateTimeFormats = {
  'EN-GB': DateFormat,
};

const numberFormat = {
  currency: {
    style: 'currency', currency: 'EUR'
  },
  percent: {
    style: 'percent', maximumFractionDigits: 0,
  },
};

const numberFormats = {
  'EN-GB': numberFormat,
};

const messages = {
  'EN-GB': ENMessages,
  'NL-NL': NLMessages,
  'TR': TRMessages,
};

// Get first browser language that exists in options
const languageOptions: string[] = Object.keys(messages);
function getLocale(options: string[]) {
  return getLocaleFromStorage() || getLanguageCodeFromBrowser(options) || options[0];
}

const vueI18n = new VueI18n({
  locale: getLocale(languageOptions),
  fallbackLocale: config.fallbackLocale,
  dateTimeFormats,
  numberFormats,
  messages,
});

export function $t(v) {
  return vueI18n.t(v);
}

export default vueI18n;
