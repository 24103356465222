import gql from 'graphql-tag';

import fragments from './fragments';

const queries = {
  currentUser: gql`
    query currentUser {
      currentUser {
        ...userFields
      }
    } ${fragments.user}`,

  assetSearchSchema: gql`
    query assetSearchSchema($projectId: ID!) {
      assetSearchSchema(project: $projectId)
    }
  `,

  creditCards: gql`
    query getCards($projectId: ID!) {
      creditCards(projects: [$projectId]) {
        id
        month
        year
        lastDigits
        holderName
        typeName
      }
    }
  `,

  machineTranslationOrders: gql`
    query machineTranslationOrders($projectId: ID!) {
      machineTranslationOrders(project: $projectId) {
        ...machineTranslationsOrderFields
      }
    }
    ${fragments.machineTranslationsOrder}
  `,

  projectOfUser: gql`
    query projectOfUser {
      currentUser {
        projects (isDeleted: false) {
          ...projectFields
        }
      }
    }
    ${fragments.project}
  `,

  stats: gql`
    query stats($projectId: ID!) {
      projectStats(project: $projectId) {
        ...statsFields
      }
    }
    ${fragments.stats}
  `,

  usageStats: gql`
    query stats($projectId: ID!) {
      projectStats(project: $projectId) {
        users
        translations
      }
    }`,

  usage: gql`
    query usage($projectId: ID!) {
      project(id: $projectId) {
        currentMonthUsage {
          ...usageFields
        }
      }
    }
    ${fragments.usage}
  `,

  translateTable: gql`
    query translateTable(
      $projectId: ID!,
      $limit: Int,
      $offset: Int,
      $ordering: String,
      $translatedInLanguageId: String,
      $untranslatedInLanguageId: String,
      $excludeTags: String,
      $includeTags: String,
      $search: String,
      $archived: Boolean,
    ) {
      paginatedAssets (
        projectId: $projectId,
        languageId_In: $translatedInLanguageId,
        languageId_Out: $untranslatedInLanguageId,
        tagId_In: $includeTags,
        tagId_Out: $excludeTags
        search: $search,
        archived: $archived,
      ) {
        totalCount
        results (
          limit: $limit,
          offset: $offset,
          ordering: $ordering,
        ) {
          id
          value
          description
          createdTimestamp
          editedTimestamp
          archived

          tags {
            id
          }

          translations {
            value
            id
            language {
              id
              code
            }
          }
        }
      }
    }
  `,

  assetsForPoject: gql`
    query assetsForPoject($projectId: ID!) {
      assets (projectId: $projectId, archived: false) { value }
    }
  `,

  assetSearch: gql`
    query assetSearch(
      $projectId: ID!,
      $search: String,
    ) {
      assets (
        projectId: $projectId,
        search: $search,
        archived: false,
      ) {
        id
        value
      }
    }
  `,

  paginatedAssetSearch: gql`
    query paginatedAssetSearch(
      $projectId: ID!,
      $limit: Int,
      $offset: Int,
      $ordering: String,
      $search: String,
    ) {
      paginatedAssets (
        projectId: $projectId,
        search: $search,
        archived: false,
      ) {
        totalCount
        results (
          limit: $limit,
          offset: $offset,
          ordering: $ordering,
        ) {
          id
          value
        }
      }
    }
  `,

  translations: gql`
    query translations ($projectId: String, $languageId: ID) {
      translations (project: $projectId, language: $languageId){
        id
        value
        asset {
          id
        }
      }
    }
  `,

  translate: gql`
    query translateAssets(
      $projectId: ID!,
      $limit: Int,
      $offset: Int,
      $translatedInLanguageId: String,
      $untranslatedInLanguageId: String,
    ) {
      paginatedAssets (
        projectId: $projectId,
        languageId_In: $translatedInLanguageId,
        languageId_Out: $untranslatedInLanguageId,
        archived: false,
      ) {
        totalCount
        results (
          limit: $limit,
          offset: $offset,
        ) {
          id
          value
          description
          translations {
            value
            id
            language {
              id
              code
            }
          }
        }
      }
    }
  `,

  assets: gql`
    query assets($projectId: ID!) {
      assets (projectId: $projectId, archived: false) {
        id
        value
      }
    }
  `,

  machineTranslationOrder: gql`
    query machineTranslationOrder($id: ID!) {
      machineTranslationOrder (id: $id) {
        ...machineTranslationOrderDetailsFields
      }
    }
    ${fragments.machineTranslationOrderDetails}
  `,

  asset: gql`
    query asset($id: ID!) {
      asset (id: $id) {
        id
        value
        description
        translations {
          id
          value
          language {
            id
            code
          }
        }
      }
    }
  `,


  assetWithComments: gql`
    query asset($id: ID!) {
      asset (id: $id) {
        comments {
          ...commentFields
        }
      }
    }
    ${fragments.comment}
  `,


  assetWithHistory: gql`
    query asset($id: ID!) {
      asset (id: $id) {
        history {
          ...assetHistoryFields
        }
      }
    }
    ${fragments.assetHistory}
  `,

  translationWithHistory: gql`
    query translation($id: ID!) {
      translation (id: $id) {
        history {
          ...translationHistoryFields
        }
      }
    }
    ${fragments.translationHistory}
  `,

  translationWithComments: gql`
    query translation($id: ID!) {
      translation (id: $id) {
        comments {
          ...commentFields
        }
      }
    }
    ${fragments.comment}
  `,

  projectWithExportedData: gql`
    query getProjectWithExportedData($projectId: ID!){
      project (id: $projectId) {
        exportedData {
          ...exportedDataFields
        }
      }
    }
  ${fragments.exportedData}`,

  languages: gql`
    query languages {
      languages {
        ...languageFields
      }
    } ${fragments.language}`,

  tags: gql`
    query tags {
      tags {
        ...tagFields
      }
    } ${fragments.tag}`,

  fileFormats: gql`
    query fileFormats {
      fileFormats {
        value
        description
      }
    }
  `,

  projectDetails: gql`
    query getProject($slug: String!){
      project(slug: $slug) {
        ...projectDetailFields
      }
    } ${fragments.projectDetail}`,

  getOrCreateUserRole: gql`
    query getOrCreateUserRole($projectId: ID!, $userId: ID!){
      getOrCreateUserRole(project: $projectId, user: $userId) {
        id
        roles
      }
    }
  `,

  getApiKeys: gql`
    query getApiKeys($projectId: ID!){
      apiKeys(project: $projectId) {
        id
        createdTimestamp
        lastOnlineTimestamp
        prefix
        name
      }
    }
  `,
}

export default queries;
