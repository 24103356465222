import * as jwt from 'jsonwebtoken'
import { postGraphql } from '@/plugins/graphql'
import mutations from '@/graphql/mutations'

export const saveAccessToken = (token) => {
  localStorage.setItem('access-token', token)
}

export const getAccessToken = () => (
  localStorage.getItem('access-token')
)

export const saveRefreshToken = (token, refreshExpiresIn) => {
  localStorage.setItem('refresh-token', token)
  localStorage.setItem('refresh-expires-in', refreshExpiresIn)
}

export const getRefreshToken = () => (
  localStorage.getItem('refresh-token')
)

export const getRefreshExpiresIn = () => (
  localStorage.getItem('refresh-expires-in')
)

export const clearToken = () => {
  localStorage.removeItem('access-token')
  localStorage.removeItem('refresh-token')
}

const getTokenExpiresTimeStamp = (token) => {
  const decoded = jwt.decode(token)
  if (!decoded) return null
  return decoded.exp
}

const getExpiresInFromJWT = (token) => {
  const exp = getTokenExpiresTimeStamp(token)
  if (exp) return exp - Date.now() / 1000
  return -1
}

// a little time before expiration to try refresh (seconds)
const EXPIRE_FUDGE = 10
export const isTokenExpired = (token) => {
  if (!token) return true
  const expin = getExpiresInFromJWT(token) - EXPIRE_FUDGE
  return !expin || expin < 0
}

// We have to use refreshExpiresIn from obtainJWT instead of getExpiresInFromJWT(refreshToken)
// Because the refresh token here is not the JWT format ([Header].[Payload].[Signature]),
// so it don't include information, and can't be decoded.
export const isRefreshTokenExpired = () => {
  const refreshExpiresIn = parseInt(getRefreshExpiresIn())
  return (refreshExpiresIn - Date.now() / 1000) <= 0
}

export const refreshTokenIfNeeded = async () => {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()
  const hasValidRefreshToken = refreshToken && !isRefreshTokenExpired()
  const hasExpiredAccessToken = !accessToken || isTokenExpired(accessToken)

  if (hasValidRefreshToken && hasExpiredAccessToken) {
    const refreshedToken = await postGraphql(
      mutations.refreshToken,
      { refreshToken },
      { disableAuth: true }
    )
    const { refreshJwt } = refreshedToken
    saveAccessToken(refreshJwt.token)
    return refreshJwt.token
  }

  return accessToken
}

export const authTokenInterceptor = async (config) => {
  if (config.disableAuth) {
    return config
  }
  
  const accessToken = await refreshTokenIfNeeded()
  if (accessToken) {
    // eslint-disable-next-line require-atomic-updates
    config.headers.Authorization = `JWT ${accessToken}`
  }
  return config
}
