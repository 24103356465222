
















































import Vue from 'vue';
import client from '@/client';
import { handleOptionalErrors, showWarningNotification } from '@/utils/notifications';
import UsageInfo from "@/components/machinetranslations/UsageInfo.vue";
import store, {state} from "@/store";

export default Vue.extend({
  name: 'NewOrderModal',

  components: { UsageInfo },

  props: {
    value: { type: Boolean, required: true, default: false, }
  },

  data() {
    return {
      fromLanguage: null,
      toLanguage: null,
    };
  },

  created() {
    store.onStateIsFetched(() => {
      // If current selected language is not a valid option, set to null
      if (!state.project.languages.map(({id}) => id).includes(this.toLanguage)) {
        this.toLanguage = null
      }

      try {
        this.toLanguage = this.toLanguage || state.project.languages[1].id;
      } catch (e) {}
    });
  },

  methods: {
    async save() {
      if (this.fromLanguage === this.toLanguage) {
        showWarningNotification(this.$t("errors.language_to_and_from_are_the_same"));
        return;
      }

      client.orderMachineTranslation({
        source: this.fromLanguage || '',
        to: this.toLanguage || '',
      }).then(({errors}) => handleOptionalErrors(errors));
      this.close();
    },

    close() {
      this.$emit('input', false);
    },
  },
});
