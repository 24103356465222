<template>
  <v-layout
    wrap
    style="justify-content: space-between;"
  >
    <h3 class="title">{{ $t('users.title') }}</h3>

    <v-btn
      outlined
      @click="addUsersModal = true"
    >
      {{ $t('project_settings.users.add') }}
      <v-icon class="ml-2">add</v-icon>
    </v-btn>

    <v-flex
      class="mt-4 scroll-on-mobile"
      xs12
    >
      <template>
        <v-data-table
          :headers="tableHeaders"
          :items="users"
          :expanded="expandedItems"
          hide-default-footer
          style="min-width: 400px;"
          :mobile-breakpoint="1"
          disable-pagination
        >
          <template v-slot:item="{item}">
            <tr @click="toggleExpandAsset(item)">
              <td>
                <v-avatar
                  size="36"
                  class="mr-4 hide-below-1000"
                >
                  <v-img
                    v-if="item.imageUrl"
                    :src="item.imageUrl"
                  />
                </v-avatar>

                <span>
                  {{ item.fullName }}
                </span>
              </td>

              <td class="hide-below-800">
                {{ item.email }}
              </td>

              <td>
                {{ timeAgoInWords(item.lastOnlineTimestamp) }}
              </td>

              <td>
                <v-btn
                  icon
                  @click.prevent="confirmDeleteUser(item)"
                >
                  <v-icon class="mx-auto">
                    delete
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>

          <template v-slot:expanded-item="{item, headers}">
            <td
              :colspan="headers.length"
              class="table-row"
            >
              <manage-user-roles
                :key="item.id"
                :user="item"
              />
            </td>
          </template>
        </v-data-table>
      </template>
    </v-flex>

    <add-user-modal v-model="addUsersModal" />
  </v-layout>
</template>

<script>
import sortBy from 'lodash/sortBy';

import { state } from '@/store';
import { timeAgoInWords } from '@/utils/datetime';
import ManageUserRoles from '@/components/projectsettings/ManageUserRoles';
import AddUserModal from '@/components/projectsettings/AddUserModal';
import { removeIfExists } from '@/utils/array';
import client from '@/client';
import events from '@/events';

export default {
  name: 'Users',

  components: { AddUserModal, ManageUserRoles },

  props: {
    value: { type: Object, required: true }, // project object
  },

  data() {
    return {
      tableHeaders: [
        {
          text: this.$t('global.name'),
          value: 'user',
        },
        {
          text: this.$t('users.email'),
          value: 'email',
          class: 'hide-below-800',
        },
        {
          text: this.$t('users.last_seen'),
          value: 'lastOnlineTimestamp',
        },
        {
          text: this.$t('actions.delete'),
          value: 'delete',
          width: 50,
          align: 'center',
        },
      ],
      addUsersModal: false,
      expandedItems: [],
    };
  },

  computed: {
    users() {
      return sortBy(this.value.users, 'id');
    },
  },

  methods: {
    timeAgoInWords,

    toggleExpandAsset(item) {
      if (this.expandedItems.find(v => v.id === item.id)) {
        this.expandedItems = [];
      } else {
        this.expandedItems = [item];
      }
    },

    confirmDeleteUser(user) {
      const userWillDeleteItself = user.id === state.user.id;

      events.openConfirmDeleteDialog.emit({
        callback: () => this.deleteUser(user),
        title: userWillDeleteItself && this.$t('global.caution'),
        text: userWillDeleteItself && this.$t('project_settings.users.remove_self_warning'),
      });
    },

    deleteUserRoles({ id }) {
      client.deleteUserRoles(id);
    },

    deleteUser(user) {
      const project = { ...this.value };
      project.users = removeIfExists(project.users, user);
      this.$emit('input', project);
      this.deleteUserRoles(user);
    },
  },
};
</script>

<style lang="scss">
  .theme--light .table-row {
    background-color: #fafafa
  }

  .fix-checkbox-alignment .v-input--selection-controls__input {
    margin-bottom: 18px;
  }

  .scroll-on-mobile {
    @media only screen and (max-width: 600px) {
      overflow-x: scroll;
    }
  }

  @media only screen and (max-width: 800px) {
    .hide-below-800 {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1000px) {
    .hide-below-1000 {
      display: none !important;
    }
  }
</style>
