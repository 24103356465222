var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.tags, function(tag) {
        return _c(
          "tag",
          {
            key: tag.id,
            staticClass: "ma-1",
            staticStyle: { cursor: "pointer" },
            attrs: { tag: tag, small: "" }
          },
          [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.confirmDeleteTag(tag)
                  }
                }
              },
              [
                _c(
                  "v-icon",
                  { staticClass: "py-1 mr-1", attrs: { small: "" } },
                  [_vm._v("close")]
                ),
                _c("b", [_vm._v(_vm._s(tag.text))])
              ],
              1
            )
          ]
        )
      }),
      _c(
        "v-chip",
        {
          attrs: { small: "" },
          on: {
            click: function($event) {
              _vm.showAddNewTagModal = true
            }
          }
        },
        [
          _c("v-icon", { staticClass: "py-1 mr-1", attrs: { small: "" } }, [
            _vm._v(" add ")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.$t("tags.add_text")))])
        ],
        1
      ),
      _vm.showAddNewTagModal
        ? _c("add-tag-modal", {
            model: {
              value: _vm.showAddNewTagModal,
              callback: function($$v) {
                _vm.showAddNewTagModal = $$v
              },
              expression: "showAddNewTagModal"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }