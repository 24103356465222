import { configureScope } from '@sentry/browser';
import store  from '../store';
import { mutations, postGraphql, queries } from '@/plugins/graphql';
import { handleOptionalErrors } from './notifications';
import router from '@/router';
import chatclient from "@/utils/chatclient";
import events from "@/events";
import {userFields} from "@/graphql/types/userFields";
import { clearToken, getRefreshToken, saveAccessToken, saveRefreshToken } from './jwt';

export function setUserToDebugger(user: userFields = {} as userFields) {
  configureScope((scope) => {
    scope.setUser({
      username: user && (user.fullName || 'guest'),
      id: user && user.id,
    });
  });
}

export function handleUnauthenticated() {
  store.logout();
  clearToken(); // Clear access-token & refreshToken in localStorage
  getRefreshToken() && postGraphql( // Revoke refresh-token
    mutations.revokeRefreshToken, { refreshToken: getRefreshToken() },
  );
  setUserToDebugger();
  events.unauthenticated.emit();
}

export function handleAuthenticated(user: userFields = {} as userFields) {
  store.login(user);
  chatclient.setEmail(user.email);
  setUserToDebugger(user);
  events.authenticated.emit();
}

export async function logout(redirect=true) {
  if (redirect) {
    // no need to await here
    router.push('/');
  }

  await postGraphql(mutations.logout);
  handleUnauthenticated();
}

export async function login(email: string, password: string) {
  const { tokenAuth } = await postGraphql(mutations.obtainToken, { username: email, password }, { disableAuth: true });
  const { login, errors } = await postGraphql(mutations.login, { input: { email, password } });
  handleOptionalErrors(errors);

  if (tokenAuth && tokenAuth.token) {
    saveAccessToken(tokenAuth.token);
    saveRefreshToken(tokenAuth.refreshToken, tokenAuth.refreshExpiresIn);
  }
  
  if (login && login.ok) {
    authenticate();
  } else {
    handleUnauthenticated();
  }
}

export async function authenticate() {
  const { currentUser } = await postGraphql(queries.currentUser);

  if (currentUser) {
    handleAuthenticated(currentUser);
  } else {
    handleUnauthenticated();
  }
}
