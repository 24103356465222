var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-4" },
    [
      _vm.label
        ? _c(
            "span",
            { staticClass: "v-label mb-4", staticStyle: { display: "block" } },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
      _vm.items.length === 0
        ? _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("global.no_items_found")) + " ")
          ])
        : _c(
            "v-chip-group",
            { attrs: { multiple: "", column: "", mandatory: _vm.mandatory } },
            _vm._l(_vm.items, function(item, id) {
              return _c(
                "v-chip",
                {
                  key: id,
                  staticClass: "mr-2",
                  style: _vm.getItemStyle(item, _vm.value),
                  attrs: {
                    disabled:
                      _vm.mandatory &&
                      _vm.value.length === 1 &&
                      _vm.isSelected(item, _vm.value)
                  },
                  on: {
                    click: function($event) {
                      return _vm.toggle(item)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.getText(item)) + " ")]
              )
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }