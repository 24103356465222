<template>
  <v-app id="locale">
    <mobile-navigation v-if="state.isMobile" />
    <sidebar v-else />

    <v-content>
      <router-view />
    </v-content>

    <load-screen />
    <notifications />
    <user-message-dialog />
    <confirm-delete-dialog />
    <!-- Add key to force rerender on route change - because of an issue in computed value in logindialog -->
    <login-dialog :key="this.$router.currentRoute.name" />
    <set-password-modal v-if="state.showChangePassword" />
    <portal-target name="modal" />
  </v-app>
</template>

<script>
import { state } from '@/store';
import Notifications from '@/components/Notifications.vue';
import LoginDialog from './components/auth/LoginDialog.vue';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog.vue';
import SetPasswordModal from './components/auth/SetPasswordModal.vue';
import { authenticate } from './utils/auth';
import { showWarningNotification } from '@/utils/notifications';
import UserMessageDialog from '@/components/UserMessageDialog';
import MobileNavigation from '@/components/navigation/MobileNavigation';
import Sidebar from '@/components/navigation/Sidebar';
import vueI18n from '@/plugins/i18n';
import client from '@/client';
import events from '@/events';
import LoadScreen from "@/components/LoadScreen";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    get title() {
      try {
        return `${vueI18n.t("view_meta.default.title")}`;
      } catch (e) {
        return "Translations"
      }
    },
    // all titles will be injected into this template
    titleTemplate: '%s | TranslationHut'
  },

  components: {
    LoadScreen,
    Sidebar,
    MobileNavigation,
    UserMessageDialog,
    LoginDialog,
    ConfirmDeleteDialog,
    Notifications,
    SetPasswordModal
  },

  data() {
    return {
      state,
    };
  },

  created() {
    this.setEvents();
    this.fetchAppData();
  },

  methods: {
    async fetchAppData() {
      authenticate();
      this.fetchLanguages();
      state.fileFormats = await client.getFileFormats();
    },

    async fetchLanguages() {
      const languages = (await client.getLanguages()) || [];
      state.languages.splice(0, state.languages.length, ...languages);
    },

    setEvents() {
      events.refetchState.on(authenticate);
    },
  },
};
</script>

<style lang="scss">
@import 'sass/globals';
</style>
