











import Vue from 'vue';
import { getContrastColor } from '@/utils/color.ts';

export default Vue.extend({
  name: 'Tag',

  props: {
    tag: { type: Object, required: true },
  },

  computed: {
    textColor() {
      return getContrastColor(this.tag.color);
    },
  },
});
