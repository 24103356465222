<template>
  <div
    class="px-4 py-1"
    style="display: flex; flex-direction: column;"
  >
    <div style="display: flex; justify-content: flex-start">
      <g-inline-language-select
        v-model="translateFrom"
        :items="state.project.languages"
        disable-default
        :label="$t('translate.from') + ':'"
      />
    </div>

    <div
      style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              flex-grow: 1;
            "
      class="py-2"
    >
      <div style="display: flex; flex-direction: column; min-height: 160px;">
        <h3
          v-if="translateFromValue"
          class="subtitle-1 font-italic"
        >
          {{ translateFromValue }}
        </h3>
        <v-label v-else>
          <span
            style="opacity: 0.5;"
            class="font-italic"
          >
            {{ $t('translate.no_from_value') }}
          </span>
        </v-label>

        <v-autocomplete
          :value="selectedAssetId || asset.id"
          :items="assetSearchResult"
          item-text="value"
          item-value="id"
          hide-details
          flat
          dense
          solo
          no-filter
          :no-data-text="loadingAssetSearch ? 'Loading' : assetSearch ? 'No assets found' : 'No assets available'"
          :loading="!!(assetSearch && loadingAssetSearch)"
          :placeholder="$t('filters.search')"
          class="text-with-select-on-click"
          style="margin-left: -12px; flex-grow: unset; flex-shrink: 1"
          :search-input.sync="assetSearch"
          @focus="assetSearch = ''"
          @focusout="assetSearch = null"
          @keypress.enter.prevent="() => {}"
          @input="selectAssetId"
        >
          <template
            slot="selection"
            slot-scope="{selected}"
          >
            <v-label
              v-if="!selected && assetSearch === null && asset.value"
              style="cursor: pointer"
            >
              {{ asset.value }} <a>({{ $t('translate.change_asset_id') }})</a>
            </v-label>
          </template>
        </v-autocomplete>

        <div class="description_scroll">
          <p
            v-if="asset.description"
            class="py-4 subtitle-2"
            style="text-align: justify; white-space:pre-line;"
          >
            {{ asset.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store, { state } from '@/store';
import debounce from 'lodash/debounce';
import syncStateWithStorage from '@/mixins/sync-state-with-storage';
import MODELS from '@/constants/models';
import client from '@/client';

export default {
  name: 'TranslateFrom',

  mixins: [syncStateWithStorage(['translateFrom'])],

  props: {
    asset: { type: Object, required: false, default: () => MODELS.asset }
  },

  data() {
    return {
      state,
      assetSearch: null,
      loadingAssetSearch: false,
      assetSearchResult: [],
      translateFrom: null, // language ID
      selectedAssetId: null,
    }
  },

  computed: {
    translateFromValue() {
      const translation = this.getTranslation(this.translateFrom);
      return translation && translation.value;
    },
  },

  watch: {
    assetSearch() {
      this.loadingAssetSearch = true;
      this.searchAssets();
    },
  },

  created() {
    this.searchAssets();
    store.onStateIsFetched(() => {
      // If current selected language is not a valid option, set to null
      if (!state.project.languages.map(({id}) => id).includes(this.translateFrom)) {
        this.translateFrom = null
      }

      try {
        this.translateFrom = this.translateFrom || this.state.project.languages[0].id;
      } catch (e) {}
    });
  },

  methods: {
    getTranslation(languageId) {
      return this.asset.translations.find(({language}) => language.id === languageId);
    },

    searchAssets: debounce(async function () {
      if (!state.project.id) {
        return;
      }

      this.loadingAssetSearch = true;
      this.assetSearchResult = await client.simpleAssetSearch(state.project.id, this.assetSearch);
      this.loadingAssetSearch = false;
    }, 500),

    async selectAssetId(id) {
      this.selectedAssetId = id;
      this.$emit('update:asset', await client.getAsset(id));
    },
  },
};
</script>

<style scoped>
  .description_scroll {
    max-height: 100px;
    overflow-y: auto;
    width: 100%;
  }
</style>
