<template>
  <g-modal
    v-model="showDialog"
    max-width="600"
    :title="title"
  >
    {{ text || $t('global.delete_confirmation') }}

    <template #actions>
      <v-btn @click="cancel">
        {{ cancelText || $t('actions.cancel') }}
      </v-btn>

      <v-btn
        type="button"
        class="error"
        @click="confirm"
      >
        {{ deleteText || $t('actions.delete') }}
      </v-btn>
    </template>
  </g-modal>
</template>

<script>
import events from '@/events';

export default {
  name: 'ConfirmDeleteDialog',

  data() {
    return {
      showDialog: false,
      callback: null,
      title: null,
      cancelText: null,
      deleteText: null,
      text: null,
    };
  },

  created() {
    events.openConfirmDeleteDialog.on(({
      // options:
      callback,
      title,
      cancelText,
      deleteText,
      text,
    }) => {
      if (!callback) {
        throw Error("'confirm-delete-dialog' event was emitted without callback");
      }

      this.callback = callback;
      this.title = title;
      this.cancelText = cancelText;
      this.deleteText = deleteText;
      this.text = text;
      this.showDialog = true;
    });
  },

  methods: {
    confirm() {
      this.showDialog = false; // close modal first because callback might cause errors
      this.callback();
      this.resetState();
    },

    cancel() {
      this.resetState();
    },

    resetState() {
      this.showDialog = false;
      this.callback = null;
      this.title = null;
      this.cancelText = null;
      this.deleteText = null;
      this.text = null;
    },
  },
};
</script>
