























import Vue from "vue";
export default Vue.extend({
  name: 'DisplaySecret',

  props: {
    secret: {
      type: String, required: false, default: ''
    }
  },
});
