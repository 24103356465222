var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "100%", "overflow-x": "auto" } },
    [
      _c("table", { staticClass: "my-4 py-4" }, [
        _c(
          "tbody",
          [
            _c("th", { staticStyle: { width: "150px" } }),
            _vm._l(_vm.headers, function(header) {
              return _c(
                "th",
                {
                  key: header + "th",
                  staticClass: "narrow_col",
                  staticStyle: { width: "80px" }
                },
                [_vm._v(" " + _vm._s(header) + " ")]
              )
            }),
            _vm._l(_vm.roleOptions, function(role) {
              return _c(
                "tr",
                { key: role },
                [
                  _c(
                    "td",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "max-width": "150px"
                      }
                    },
                    [
                      _c("v-checkbox", {
                        staticStyle: { height: "50px" },
                        attrs: {
                          "hide-details": "",
                          value: role,
                          disabled:
                            _vm.roles.length === 1 && _vm.roles.includes(role)
                        },
                        on: { change: _vm.sendToServer },
                        model: {
                          value: _vm.roles,
                          callback: function($$v) {
                            _vm.roles = $$v
                          },
                          expression: "roles"
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(role))])
                    ],
                    1
                  ),
                  _vm._l(_vm.routes, function(route) {
                    return _c(
                      "td",
                      { key: route.title, staticClass: "narrow_col" },
                      [
                        route.meta.roles.includes(role)
                          ? _c(
                              "v-icon",
                              {
                                attrs: {
                                  color: _vm.userHasRole(role)
                                    ? "green"
                                    : "#cccccc"
                                }
                              },
                              [_vm._v(" check ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  _c(
                    "td",
                    { staticClass: "narrow_col" },
                    [
                      role === "admin"
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                color: _vm.isAdmin ? "green" : "#cccccc"
                              }
                            },
                            [_vm._v(" check ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }