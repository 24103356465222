var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "generic_price_table" } }, [
    _c("br"),
    _c("br"),
    _c("section", [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-card generic_content clearfix" }, [
              _vm._m(0),
              _vm._m(1),
              _c(
                "div",
                { staticClass: "generic_price_btn clearfix" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "price_button",
                      class: {
                        selected_pricing: _vm.value === _vm.plans[0].value
                      },
                      on: {
                        click: function($event) {
                          return _vm.setValue(_vm.plans[0].value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.plans[0].text) + " ")]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-card generic_content clearfix" }, [
              _vm._m(2),
              _vm._m(3),
              _c(
                "div",
                { staticClass: "generic_price_btn clearfix" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "price_button",
                      class: {
                        selected_pricing: _vm.value === _vm.plans[1].value
                      },
                      on: {
                        click: function($event) {
                          return _vm.setValue(_vm.plans[1].value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.plans[1].text) + " ")]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-card generic_content clearfix" }, [
              _vm._m(4),
              _vm._m(5),
              _c(
                "div",
                { staticClass: "generic_price_btn clearfix" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "price_button",
                      class: {
                        selected_pricing: _vm.value === _vm.plans[2].value
                      },
                      on: {
                        click: function($event) {
                          return _vm.setValue(_vm.plans[2].value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.plans[2].text) + " ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._m(6)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "generic_head_price clearfix" }, [
      _c("div", { staticClass: "generic_head_content clearfix" }, [
        _c("div", { staticClass: "head_bg" }),
        _c("div", { staticClass: "head" }, [_c("span", [_vm._v("Basic")])])
      ]),
      _c("div", { staticClass: "generic_price_tag clearfix" }, [
        _c("span", { staticClass: "price" }, [
          _c("span", { staticClass: "free-price" }, [_vm._v("Free")]),
          _c("span", { staticClass: "free-price-prefix" }, [_vm._v("forever")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "generic_feature_list" }, [
      _c("ul", [
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("3")]),
          _vm._v(" Users")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("5.000")]),
          _vm._v(" Translations")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Languages")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Api-keys")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Exports")
        ]),
        _c("br"),
        _c("li", [_vm._v("No Credit Card required")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "generic_head_price clearfix" }, [
      _c("div", { staticClass: "generic_head_content clearfix" }, [
        _c("div", { staticClass: "head_bg" }),
        _c("div", { staticClass: "head" }, [_c("span", [_vm._v("Pro")])])
      ]),
      _c("div", { staticClass: "generic_price_tag clearfix" }, [
        _c("span", { staticClass: "price" }, [
          _c("span", { staticClass: "sign" }, [_vm._v("€")]),
          _c("span", { staticClass: "currency" }, [_vm._v("29")]),
          _c("span", { staticClass: "month" }, [_vm._v("/MON")])
        ]),
        _c("span", { staticClass: "price" }, [
          _c("span", { staticClass: "cents" }, [_vm._v("€ 290 / YEAR")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "generic_feature_list" }, [
      _c("ul", [
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("10")]),
          _vm._v(" Users")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("50.000")]),
          _vm._v(" Translations")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Languages")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Api-keys")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Exports")
        ]),
        _c("br"),
        _c("li", [_vm._v("First 3 months "), _c("b", [_vm._v("free")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "generic_head_price clearfix" }, [
      _c("div", { staticClass: "generic_head_content clearfix" }, [
        _c("div", { staticClass: "head_bg" }),
        _c("div", { staticClass: "head" }, [_c("span", [_vm._v("Premium")])])
      ]),
      _c("div", { staticClass: "generic_price_tag clearfix" }, [
        _c("span", { staticClass: "price" }, [
          _c("span", { staticClass: "sign" }, [_vm._v("€")]),
          _c("span", { staticClass: "currency" }, [_vm._v("60")]),
          _c("span", { staticClass: "month" }, [_vm._v("/MON")])
        ]),
        _c("span", { staticClass: "price" }, [
          _c("span", { staticClass: "cents" }, [_vm._v("€ 600 / YEAR")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "generic_feature_list" }, [
      _c("ul", [
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("20 ")]),
          _vm._v(" Users")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("100.000 ")]),
          _vm._v(" Translations")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Languages")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Api-keys")
        ]),
        _c("li", [
          _c("span", { staticClass: "feature_amount" }, [_vm._v("Unlimited")]),
          _vm._v(" Exports")
        ]),
        _c("br"),
        _c("li", [_vm._v("First 3 months "), _c("b", [_vm._v("free")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container py-4 my-4 text--secondary",
        staticStyle: { "text-align": "center" }
      },
      [
        _vm._v(" Needs not covered by these plans?"),
        _c("br"),
        _vm._v(" Email joey@madooz.com. ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }