var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: { value: _vm.value, "max-width": "348" },
      on: { input: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c("v-btn", { on: { click: _vm.close } }, [
                _vm._v(" " + _vm._s(_vm.$t("actions.cancel")) + " ")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { type: "button" },
                  on: { click: _vm.save }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("orders.order_translations")) + " "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.save($event)
            }
          }
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t("orders.order_machine_translations_explanation")
                  )
                )
              ]),
              _c("g-inline-language-select", {
                attrs: { label: _vm.$t("translate.from") },
                model: {
                  value: _vm.fromLanguage,
                  callback: function($$v) {
                    _vm.fromLanguage = $$v
                  },
                  expression: "fromLanguage"
                }
              }),
              _c("g-inline-language-select", {
                staticClass: "mb-4",
                attrs: { "disable-default": "", label: _vm.$t("translate.to") },
                model: {
                  value: _vm.toLanguage,
                  callback: function($$v) {
                    _vm.toLanguage = $$v
                  },
                  expression: "toLanguage"
                }
              }),
              _c("usage-info"),
              _c("button", {
                staticStyle: { display: "none" },
                attrs: { type: "submit" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }