import {mutate, post} from "@/utils/graphql";
import mutations from "@/graphql/mutations";
import queries from "@/graphql/queries";
import random from "lodash/random"

import { updateAsset_updateAsset } from "@/graphql/types/updateAsset";
import { updateTranslation_updateTranslation } from "@/graphql/types/updateTranslation";
import {
  AssetCreateGenericType,
  AssetUpdateGenericType, CommentCreateGenericType,
  ConfirmResetPasswordInput, CreateAccountInput,
  CreateApiKeyInput,
  ExportDataInput,
  ImportDataInput,
  InviteUserGenericType,
  OrderMachineTranslationsInput,
  ProjectUpdateGenericType,
  TagCreateGenericType,
  TranslationCreateGenericType,
  TranslationUpdateGenericType, UserRoleUpdateGenericType,
  UserUpdateGenericType
} from "../types/globalTypes";
import {
  createTranslation_createTranslation,
} from "@/graphql/types/createTranslation";
import { assetsForPoject_assets } from "@/graphql/types/assetsForPoject";
import { orderMachineTranslation_orderMachineTranslations } from "@/graphql/types/orderMachineTranslation";
import { createTag_createTag } from "@/graphql/types/createTag";
import { deleteTag_deleteTag } from "@/graphql/types/deleteTag";
import { updateUser_updateUser } from "@/graphql/types/updateUser";
import { machineTranslationsOrderFields } from "@/graphql/types/machineTranslationsOrderFields";
import { usageFields } from "@/graphql/types/usageFields";
import { createAccount_createAccount } from "@/graphql/types/createAccount";
import { inviteUser_inviteUser } from "@/graphql/types/inviteUser";
import { updateProject_updateProject } from "@/graphql/types/updateProject";
import withAbortSignal from "@/utils/requests";
import { asset_asset_history } from "@/graphql/types/asset";
import { languages_languages } from "@/graphql/types/languages";
import { deleteApiKey_deleteApikey } from "@/graphql/types/deleteApiKey";
import { getApiKeys } from "@/graphql/types/getApiKeys";
import { fileFormats_fileFormats } from "@/graphql/types/fileFormats";
import { assetFields } from "@/graphql/types/assetFields";
import {
  paginatedAssetSearch_paginatedAssets,
  paginatedAssetSearchVariables
} from "@/graphql/types/paginatedAssetSearch";
import {
  translateAssets_paginatedAssets,
  translateAssetsVariables
} from "@/graphql/types/translateAssets";
import { projectFields } from "@/graphql/types/projectFields";
import { projectDetailFields } from "@/graphql/types/projectDetailFields";
import { createProject_createProject } from "@/graphql/types/createProject";
import { importData_importData } from "@/graphql/types/importData";
import { exportData_exportData } from "@/graphql/types/exportData";
import { resetPassword_resetPassword } from "@/graphql/types/resetPassword";
import { createAsset_createAsset } from "@/graphql/types/createAsset";
import { setPassword_setPassword } from "@/graphql/types/setPassword";
import { exportedDataFields } from "@/graphql/types/exportedDataFields";
import { confirmResetPassword_confirmResetPassword } from "@/graphql/types/confirmResetPassword";
import { statsFields } from "@/graphql/types/statsFields";
import { createApiKey_createApiKey } from "@/graphql/types/createApiKey";
import { assetHistoryFields } from "@/graphql/types/assetHistoryFields";
import { commentFields } from "@/graphql/types/commentFields";
import { Result } from "../types/customTypes";
import {
  translateTable,
  translateTableVariables
} from "@/graphql/types/translateTable";
import { updateUserrole_updateUserrole } from "@/graphql/types/updateUserrole";
import { deleteUserRole_deleteUserrole } from "@/graphql/types/deleteUserRole";
import {
  getOrCreateUserRole,
  getOrCreateUserRoleVariables
} from "@/graphql/types/getOrCreateUserRole";
import { machineTranslationOrderDetailsFields } from "@/graphql/types/machineTranslationOrderDetailsFields";
import {GQLCreateCreditCardInput} from "../types";
import {createCreditCard_createCreditCard} from "@/graphql/types/createCreditCard";
// https://medium.com/@crucialfelix/bridging-the-server-client-gap-graphql-typescript-and-apollo-codegen-e5b54fa96ae2

export default {
  /**
   * Queries
   */
  async getAssetHistory(assetId: string): Promise<assetHistoryFields[]> {
    const { asset } = await post(queries.assetWithHistory, { id: assetId });
    return asset.history;
  },

  async getAssetComments(assetId: string): Promise<commentFields[]> {
    const { asset } = await post(queries.assetWithComments, { id: assetId });
    return asset.comments;
  },

  async getTranslationComments(assetId: string): Promise<commentFields[]> {
    const { translation } = await post(queries.translationWithComments, { id: assetId });
    return translation.comments;
  },

  async getLanguages(): Promise<languages_languages> {
    const { languages } = await post(queries.languages, {});
    return languages;
  },

  async getFileFormats(): Promise<fileFormats_fileFormats> {
    const { fileFormats } = await post(queries.fileFormats, {});
    return fileFormats;
  },

  async getApiKeys(projectId: string): Promise<getApiKeys> {
    return await post(queries.getApiKeys, { projectId });
  },

  async getProject(slug: string): Promise<projectDetailFields> {
    const { project } = await post(queries.projectDetails, { slug });
    return project
  },

  async getCurrentUsersProjects(): Promise<projectFields[]> {
    const { currentUser } = await post(queries.projectOfUser, {});

    if (currentUser && currentUser.projects) {
      return currentUser.projects.filter(v => !!v)
    }

    return [];
  },

  async getAsset(id: string): Promise<assetFields> {
    const { asset } = await post(queries.asset, { id });
    return asset;
  },

  async getMachineTranslationOrder(id: string): Promise<machineTranslationOrderDetailsFields> {
    const { machineTranslationOrder } = await post(queries.machineTranslationOrder, { id });
    return machineTranslationOrder;
  },

  async getTranslations(projectId: string, languageId: string): Promise<assetFields> {
    const { translations } = await post(queries.translations, {
      projectId,
      languageId,
    });
    return translations
  },

  async getPaginatedAssets(input: paginatedAssetSearchVariables): Promise<paginatedAssetSearch_paginatedAssets> {
    const { paginatedAssets } = await post(queries.paginatedAssetSearch, input);
    return paginatedAssets
  },

  async getTranslateAsset(input: translateAssetsVariables): Promise<translateAssets_paginatedAssets> {
    const { paginatedAssets } = await post(queries.translate, input);
    return paginatedAssets
  },

  async getTranslationHistory(translationId: string): Promise<asset_asset_history[]> {
    const resp = await post(queries.translationWithHistory, { id: translationId });
    return (resp && resp.translation && resp.translation.history) || [];
  },

  async getAssetsValuesForProject(projectId: string): Promise<string[]> {
    const { assets } = await post(queries.assetsForPoject, { projectId });
    return assets.map((asset: assetsForPoject_assets) => asset.value);
  },

  async simpleAssetSearch(projectId: string, search: string): Promise<string[]> {
    const { assets } = await post(queries.assetSearch, { projectId, search });
    return assets || [] ;
  },

  async getTranslateTable(input: translateTableVariables): Promise<Result | translateTable> {
    const signal = withAbortSignal("translateTable");
    return await post(queries.translateTable, input, { signal });
  },

  async getExportedData(projectId: string): Promise<exportedDataFields[]> {
    const { project } = await post(queries.projectWithExportedData, { projectId });
    return (project && project.exportedData) || [] ;
  },

  async getMachineTranslationOrders(projectId: string): Promise<machineTranslationsOrderFields[]> {
    const { machineTranslationOrders } = await post(queries.machineTranslationOrders, { projectId });
    return machineTranslationOrders;
  },

  async getUsageForProject(projectId: string): Promise<usageFields> {
    const { project } = await post(queries.usage, { projectId });
    return project.currentMonthUsage;
  },

  async getStatsForProject(projectId: string): Promise<statsFields> {
    const { projectStats } = await post(queries.stats, { projectId });
    return projectStats;
  },

  async getUsageStatsForProject(projectId: string): Promise<statsFields> {
    const { projectStats } = await post(queries.usageStats, { projectId });
    return projectStats;
  },

  async getSearchSchema(projectId: string): Promise<any> {
    const response = await post(queries.assetSearchSchema, { projectId });
    const assetSearchSchema: string = response.assetSearchSchema;
    return JSON.parse(assetSearchSchema);
  },

  async getCreditCards(projectId: string): Promise<any> {
    const { creditCards } = await post(queries.creditCards, { projectId });
    return creditCards;
  },

  /**
   * Mutations
   */
  importData(input: ImportDataInput): Promise<importData_importData> {
    return mutate(mutations.importData, { input });
  },

  createAsset(input: AssetCreateGenericType): Promise<createAsset_createAsset> {
    return mutate(mutations.createAsset, { input });
  },

  exportData(input: ExportDataInput): Promise<exportData_exportData> {
    return mutate(mutations.exportData, { input });
  },

  resetPassword(email: string): Promise<resetPassword_resetPassword> {
    return mutate(mutations.resetPassword, { input: { email } });
  },

  confirmPasswordReset(input: ConfirmResetPasswordInput): Promise<confirmResetPassword_confirmResetPassword> {
    return mutate(mutations.confirmResetPassword, { input });
  },

  updateOrCreateTranslation(translation: { value?: any; id?: string; language?: string; asset?: string; }): Promise<any> {
    return translation.id
      ? this.updateTranslation(translation as TranslationUpdateGenericType)
      : this.createTranslation(translation as TranslationCreateGenericType);
  },

  deleteApiKey(id: string): Promise<deleteApiKey_deleteApikey> {
    return mutate(mutations.deleteApiKey, { id });
  },

  getOrCreateUserRole(input: getOrCreateUserRoleVariables): Promise<getOrCreateUserRole> {
    return mutate(queries.getOrCreateUserRole, input);
  },

  deleteUserRoles(id: string): Promise<deleteUserRole_deleteUserrole> {
    return mutate(mutations.deleteUserRole, { id });
  },

  updateUserRoles(input: UserRoleUpdateGenericType): Promise<updateUserrole_updateUserrole> {
    return mutate(mutations.updateUserrole, { input });
  },

  createApiKey(input: CreateApiKeyInput): Promise<createApiKey_createApiKey> {
    return mutate(mutations.createApiKey, { input });
  },

  createComment(input: CommentCreateGenericType): Promise<createApiKey_createApiKey> {
    return mutate(mutations.createComment, { input });
  },

  updateProjectWithAbort(input: ProjectUpdateGenericType): Promise<updateProject_updateProject> {
    const signal = withAbortSignal("saveProjectSettings");
    return mutate(mutations.updateProject, { input }, { signal });
  },

  setPassword(password: string): Promise<setPassword_setPassword> {
    return mutate(mutations.setPassword, { password });
  },

  createProject(languageCode: string): Promise<createProject_createProject> {
    return mutate(mutations.createProject, { languageCode });
  },

  inviteUser(input: InviteUserGenericType): Promise<inviteUser_inviteUser> {
    return mutate(mutations.inviteUser, { input });
  },

  changeUserLocale(locale: string, id: string): Promise<updateUser_updateUser> {
    return mutate(mutations.updateUser, { input: { id, locale } });
  },

  updateUser(input: UserUpdateGenericType): Promise<updateProject_updateProject> {
    return mutate(mutations.updateUser, { input });
  },

  updateAsset(input: AssetUpdateGenericType): Promise<updateAsset_updateAsset> {
    // Prevent unique together issues...
    // Allow 2 of the same assetId to exist if its archived
    input.archivedId = input.archived ? random(-32767, 32767) : 0;
    return mutate(mutations.updateAsset, { input });
  },

  createAccount(input: CreateAccountInput): Promise<createAccount_createAccount> {
    return mutate(mutations.createAccount, { input });
  },

  updateTags(assetId: string, tagIds: string[]): Promise<any> {
    const input = { id: assetId, tags: tagIds };
    return mutate(mutations.updateAsset, { input });
  },

  updateTranslation(input: TranslationUpdateGenericType): Promise<updateTranslation_updateTranslation> {
    return mutate(mutations.updateTranslation, { input });
  },

  orderMachineTranslation(input: OrderMachineTranslationsInput): Promise<orderMachineTranslation_orderMachineTranslations> {
    return mutate(mutations.orderMachineTranslation, { input });
  },

  createTranslation(input: TranslationCreateGenericType): Promise<createTranslation_createTranslation> {
    return mutate(mutations.createTranslation, { input });
  },

  createTag(input: TagCreateGenericType): Promise<createTag_createTag> {
    return mutate(mutations.createTag, { input });
  },

  deleteTag(tagId: string): Promise<deleteTag_deleteTag> {
    return mutate(mutations.deleteTag, { tagId });
  },

  createCreditCard(input: GQLCreateCreditCardInput): Promise<createCreditCard_createCreditCard> {
    return mutate(mutations.createCreditCard, { input });
  },

  selectPlan(input: ProjectUpdateGenericType): Promise<updateProject_updateProject> {
    return mutate(mutations.updateProject, { input });
  }
};
