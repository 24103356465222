type CvsRows = Record<any, any>[]

const csvString = (items: CvsRows): string => {
  const SEPARATOR = ";"
  const replacer = (key, value) => [null, undefined].includes(value) ? '' : value
  const header = Object.keys(items[0])
  return [
    header.join(SEPARATOR),
    ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(SEPARATOR))
  ].join('\r\n')
}

function download(fileName: string, content: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const validateRows = (rows: CvsRows): string | undefined => {
  if (rows.length === 0) {
    return "No data provided"
  }
  if (typeof rows[0] !== "object") {
    return "Row data is incorrect"
  }
  return undefined;
}

export const downloadInCsv = (rows: CvsRows, fileName: string): string | undefined => {
  const error = validateRows(rows)
  if (error) { return error }
  download(
    `${fileName}.xls`,
    csvString(rows),
  )
  return undefined;
}
