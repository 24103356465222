var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications" },
    _vm._l(_vm.notifications, function(notification, key) {
      return _c(
        "v-alert",
        {
          key: key,
          staticClass: "notification",
          attrs: {
            type: notification.type,
            dismissible: "",
            value: notification.visible,
            transition: "scroll-y-reverse-transition"
          }
        },
        [_vm._v(" " + _vm._s(notification.message) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }