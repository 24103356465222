import events from "@/events";

function errorToString(strOrObj: any) {
  if (typeof strOrObj === 'string') {
    return strOrObj;
  }

  let result = '';

  if (strOrObj.messages) {
    result += strOrObj.messages.join(', ');
  }

  if (strOrObj.message) {
    if (result.length > 0) {
      result += ', ';
    }

    result += strOrObj.message;
  }

  if (strOrObj.field) {
    return `${strOrObj.field}: ${result}`;
  }

  return result;
}

// Always displays error.
// parses optional array argument.
// input allows:
// 'simple error'
// ['kaas, is baas', 'echt', 'waar']
// [{ message: 'kaas, is baas' }, { message: 'echt' }, { message: 'waar' }]
export function formatErrors(errors: any) {
  let message = errors;

  if (!errors || errors.length === 0) {
    return null;
  }

  if (errors.forEach) {
    message = errors
      .filter((v: any) => !!v)
      .map(errorToString)
      .join(' - ');
  }

  return message;
}

export function sendNotification(message: any, type = 'default', duration=10000) {
  if (message) {
    events.notification.emit(message, type, duration);
  }
}

export function showSavedNotification() {
  showSuccessNotification("Saved", 3000);
}

export function showErrorNotification(message: any=null, duration=10000) {
  sendNotification(message || 'Something went wrong...', 'error', duration);
}

export function showWarningNotification(message: any, duration=10000) {
  sendNotification(message || 'Something went wrong...', 'warning', duration);
}

export function showSuccessNotification(message: any, duration=10000) {
  sendNotification(message || 'Something went wrong...', 'success', duration);
}

export function handleErrors(errors: any) {
  showErrorNotification(formatErrors(errors));
}

// runs "handleErrors" if errors is defined and not empty list.
export function handleOptionalErrors(errors: any) {
  if (errors && errors.length !== 0) { handleErrors(errors); }
}
