var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: {
        value: _vm.value,
        label: _vm.$t("tags.add_text"),
        "max-width": "348"
      },
      on: { input: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c("v-btn", { on: { click: _vm.close } }, [
                _vm._v(" " + _vm._s(_vm.$t("actions.cancel")) + " ")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { type: "button" },
                  on: { click: _vm.save }
                },
                [_vm._v(" " + _vm._s(_vm.$t("actions.save")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.save($event)
            }
          }
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  rules: [_vm.$rules.required],
                  label: _vm.$t("tags.text_label")
                },
                model: {
                  value: _vm.text,
                  callback: function($$v) {
                    _vm.text = $$v
                  },
                  expression: "text"
                }
              }),
              _c("button", {
                staticStyle: { display: "none" },
                attrs: { type: "submit" }
              }),
              _c("v-color-picker", {
                staticClass: "my-4",
                attrs: { flat: "" },
                model: {
                  value: _vm.color,
                  callback: function($$v) {
                    _vm.color = $$v
                  },
                  expression: "color"
                }
              }),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("p", [_vm._v("Result:")]),
                  _c("tag", { attrs: { tag: _vm.tag } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }