







































import Vue from 'vue';
import store from '@/store';
import Tag from '@/components/tags/Tag.vue';
import AddTagModal from '@/components/tags/AddTagModal.vue';
import { tagFields } from "@/graphql/types/tagFields";
import { toggle } from "@/utils/array";
import client from "@/client";
import events from "@/events";

export default Vue.extend({
  name: 'ManageTags',

  components: { Tag, AddTagModal },

  data() {
    return {
      tags: [] as tagFields[],
      showAddNewTagModal: false,
    }
  },

  created() {
    const getTags = () => {
      this.tags = store.getTagOptions();
    };
    events.tagsChanged.on(getTags);
    store.onStateIsFetched(getTags)
  },

  methods: {
    confirmDeleteTag(tag) {
      events.openConfirmDeleteDialog.emit({
        callback: () => this.deleteTag(tag),
        text: this.$t('tags.confirm_delete_title'),
      });
    },

    async deleteTag(tag) {
      this.tags = toggle(this.tags, tag);
      store.setTagOptions(this.tags);
      await client.deleteTag(tag.id);
      this.$emit('changed-tags');
    },
  },
});
