



































import Vue from 'vue';
import client from "@/client";
import { machineTranslationOrderDetailsFields } from "@/graphql/types/machineTranslationOrderDetailsFields";
import { timeAgoInWords } from '@/utils/datetime';

export default Vue.extend({
  name: 'OrderInfoModal',

  props: {
    id: { type: String, required: true },
  },

  data() {
    return {
      order: {} as machineTranslationOrderDetailsFields,
    };
  },

  async created() {
    this.order = await client.getMachineTranslationOrder(this.id);
  },

  methods: { timeAgoInWords },
});
