import events from "@/events";

interface NewLoadScreenPayload {
  text: string
  title?: string
  persistent?: boolean
}

export interface LoadScreenPayload extends NewLoadScreenPayload {
  id: number
}

const openLoadScreen = (payload: LoadScreenPayload) => events.openLoadScreen.emit(payload)
const closeLoadScreen = (payload: LoadScreenPayload) => events.closeLoadScreen.emit(payload)
const createId = () => Math.round(Math.random() * 9999999)
const withLoadScreen = async (payload: NewLoadScreenPayload, callable: () => Promise<any>) => {
  const newPayload = { ...payload, id: createId() }
  openLoadScreen(newPayload)
  await callable()
  closeLoadScreen(newPayload)
}
export default withLoadScreen
