import get from 'lodash/get';
import intersection from 'lodash/intersection';

import store from '@/store';
import { Route } from "vue-router";

export default function getRoutePermissions(to: Route, currentProjectSlug: string) {
  const pathRoles = get(to, 'meta.roles');

  if (!pathRoles) {
    // No roles = everyone allowed
    return true;
  }

  if (currentProjectSlug === 'new-project') {
    return true;
  }

  const userRoles = store.getUserRoles(currentProjectSlug);
  return intersection(pathRoles, userRoles).length !== 0;
}
