import { Header } from "../../types/customTypes";
import vueI18n from "@/plugins/i18n";

export default Object.freeze({
  PRIORITY_OPTIONS: [
    { value: 0, text: 'Lowest priority', color: '#00aa16' },
    { value: 1, text: 'Low priority', color: '#44a400' },
    { value: 2, text: 'Medium priority', color: '#8d9d00' },
    { value: 3, text: 'High priority', color: '#9b5200' },
    { value: 4, text: 'Highest priority', color: '#9b0009' },
  ],
  get translateTableHeaders() {return{
    assetIdHeader: {text: vueI18n.t('assets.asset_id'), value: 'value'} as Header,
    tagsHeader: { text: vueI18n.t('tags.title'), value: 'tags', readOnly: true, preventClick: true, sortable: false } as Header,
    createdHeader: {text: vueI18n.t('global.created'), value: 'created', readOnly: true} as Header,
    editedHeader: {text: vueI18n.t('global.edited'), value: 'edited', readOnly: true} as Header,
    archiveHeader: { text: vueI18n.t('assets.archive'), value: 'archived', readOnly: true, preventClick: true, sortable: false } as Header,
    descriptionHeader: { text: "Description", value: 'description', readOnly: true, preventClick: true, sortable: false } as Header,
  }},
});
