<template>
  <div>
    <v-text-field
      v-model.trim="search"
      :label="$t('filters.search')"
    />

    <div
      v-for="language in pagedLanguageOptions"
      :key="language.id"
      class="flag-chip-wrapper"
    >
      <g-language-chip
        :language="language"
        class="v-card--hover"
        @click="toggleLanguage(language)"
      />
    </div>

    <v-pagination
      v-model="page"
      class="mt-4 mb-2"
      :length="amountOfPages"
    />
  </div>
</template>

<script>
import { state } from '@/store';
import { showWarningNotification } from '../utils/notifications';
import getIds from '../utils/getIds';

export default {
  props: {
    value: Array,
  },

  data() {
    return {
      state,
      loading: false,
      search: null,
      page: 1,
      FLAGS_PER_AGE: window.innerHeight < 1000 ? 16 : 32,
    };
  },

  computed: {
    amountOfPages() {
      return Math.ceil(this.filteredLanguageOptions.length / this.FLAGS_PER_AGE);
    },

    filteredLanguageOptions() {
      return this.state.languages
        .filter(language => !this.search
          || language.code.toLowerCase().includes(this.search.toLowerCase()))
        .filter(language => !getIds(this.value).includes(language.id));
    },

    pagedLanguageOptions() {
      const from = (this.page - 1) * this.FLAGS_PER_AGE;
      const until = this.page * this.FLAGS_PER_AGE;
      return this.filteredLanguageOptions.slice(from, until);
    },
  },

  watch: {
    amountOfPages(amountOfPages) {
      if (amountOfPages < this.page) {
        this.page = amountOfPages || 1; // page can never be 0;
      }
    },
  },

  methods: {
    toggleLanguage(language) {
      if (getIds(this.value).includes(language.id)) {
        this.deleteLanguage(language);
      } else {
        this.$emit('input', [...this.value, language]);
      }
    },

    deleteLanguage(language) {
      if (this.languageObjects.length < 2) {
        showWarningNotification(this.$t('project_settings.one_language_required'));
      } else {
        const index = this.project.languages.findIndex(v => v.id === language.id);
        this.project.languages.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .no-card li {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
  }

  .flag-chip-wrapper {
    display: inline-block;
    padding-right: 6px;
    width: 25%;

    @media only screen and (max-width: 600px) {
      width: 33%;
    }
  }
</style>
