import pick from 'lodash/pick'
import { state } from '@/store';

const URL = "https://hooks.slack.com/services/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";

export default function sendMessage(msg: string) {
  let text = "\n -- New message --";
  text += `\nFrom: ${JSON.stringify(pick(state.user, 'username', 'email', 'fullName'))}`;
  text += `\ncurrent path: ${JSON.stringify(window.location.pathname)}`;
  text += `\nproject: ${JSON.stringify(pick(state.project, 'id', 'slug', 'name'))}`;
  text += `\n\nmessage:\n${msg}`;
  const data = { text };
  fetch(URL, {
    method: 'POST',
    body: JSON.stringify(data)
  });
}
