<template>
  <div class="my-4">
    <span
      v-if="label"
      class="v-label mb-4"
      style="display: block"
    >
      {{ label }}
    </span>

    <span v-if="items.length === 0">
      {{ $t('global.no_items_found') }}
    </span>

    <v-chip-group
      v-else
      multiple
      column
      :mandatory="mandatory"
    >
      <v-chip
        v-for="(item, id) in items"
        :key="id"
        :disabled="mandatory && value.length === 1 && isSelected(item, value)"
        class="mr-2"
        :style="getItemStyle(item, value)"
        @click="toggle(item)"
      >
        {{ getText(item) }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import { getContrastColor } from '@/utils/color';

export default {
  name: 'GChipSelect',

  props: {
    items: { type: Array, required: true },
    value: { required: true, type: Array },
    label: { required: false, type: String, default: null },
    returnObject: { type: Boolean },
    mandatory: { type: Boolean, default: false, required: false },
    valueKey: { default: 'value', required: false, type: String },
    textKey: { default: 'text', required: false, type: String },
  },

  methods: {
    toggle(item) {
      const value = this.getValue(item);
      const newValue = [...this.value];
      const index = this.value.map(v => JSON.stringify(v)).indexOf(JSON.stringify(value));
      if (index > -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(value);
      }

      this.$emit('input', newValue);
    },

    getValue(item) {
      return this.returnObject
        ? item
        : item[this.valueKey];
    },

    getText(item) {
      return this.textKey
        ? item[this.textKey]
        : item;
    },

    isSelected(item, value) {
      return !!value.find(v => JSON.stringify(v) === JSON.stringify(this.getValue(item)));
    },

    getItemStyle(item, value) {
      const selectedColor = '#5479be';
      const unSelectedColor = 'rgba(160,190,189,0.47)';
      return this.isSelected(item, value)
        ? `color: ${getContrastColor(selectedColor)} !important; background-color: ${selectedColor} !important;`
        : `color: ${getContrastColor(unSelectedColor)} !important; background-color: ${unSelectedColor} !important;`;
    },
  },
};
</script>
