import Vue from 'vue';
import GLanguageChip from './GLanguageChip.vue';
import GLoader from './GLoader.vue';
import GEditImage from './GEditImage.vue';
import GModal from './GModal.vue';
import GSelectLanguage from './GSelectLanguage.vue';
import GChipSelect from './GChipSelect.vue';
import GToggleIcon from './GToggleIcon.vue';
import GInlineLanguageSelect from './GInlineLanguageSelect.vue';
import GToggleTextField from './GToggleTextField.vue';

Vue.component('g-language-chip', GLanguageChip);
Vue.component('g-loader', GLoader);
Vue.component('g-edit-image', GEditImage);
Vue.component('g-modal', GModal);
Vue.component('g-select-language', GSelectLanguage);
Vue.component('g-chip-select', GChipSelect);
Vue.component('g-toggle-icon', GToggleIcon);
Vue.component('g-inline-language-select', GInlineLanguageSelect);
Vue.component('g-toggle-text-field', GToggleTextField);
