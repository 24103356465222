export default (function(){
  window.$crisp=[]; // make sure its always defined
  const closedCallbacks = [];
  let isOpen = false;
  let storedEmail = null;

  const setEmail = email => {
    storedEmail = email;
    $crisp.push(["set", "user:email", email || storedEmail]);
  };

  const setup = (ISO639Locale, email) => {
    storedEmail = email;

    // Remove previous window
    try {
      $crisp.push(['do', 'chat:hide']);
      window.$crisp.splice(0, window.$crisp.length);
    } catch (e) {}

    // CRISP CODE
    window.CRISP_WEBSITE_ID="54ed777c-f647-4999-969d-d1d21f6b353f";
    window.$crisp=[];
    window.CRISP_RUNTIME_CONFIG = { locale: ISO639Locale };
    let d ,s;
    d=document;
    s=d.createElement("script");
    s.src="https://client.crisp.chat/l.js";
    s.async=1;
    d.getElementsByTagName("head")[0].appendChild(s);
    // END CRISP CODE

    // From console:
    // [WARNING] Crisp found shims of native JavaScript methods.
    // This can alter the chatbox behavior and break things.
    // Make sure not to override listed functions to ensure your
    // chatbox works as expected. You may be looking for other
    // JavaScript libraries in use on this page.
    //
    // You can disable this warning by adding: $crisp.push(["safe", true]) to your page JavaScript.
    $crisp.push(["safe", true]);
    setEmail(storedEmail);

    $crisp.push(["on", "chat:closed", () => {
      closedCallbacks.forEach(method => method())
    }]);

    if (isOpen) {
      show();
    } else {
      hide();
    }
  };

  const show = () => {
    isOpen = true;
    $crisp.push(['do', 'chat:show']);
    $crisp.push(["do", "chat:open"]);
  };

  const hide = () => {
    isOpen = false;
    $crisp.push(['do', 'chat:close']);
    // first animate close, then hide crisp chat button
    setTimeout(() => {
      $crisp.push(['do', 'chat:hide']);
    }, 250);
  };

  return {
    setup,
    setEmail,
    show,
    hide,

    addClosedCallback(closedCallback) {
      closedCallbacks.push(closedCallback);
    },
  }
})();
