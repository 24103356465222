var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state.project.id
    ? _c(
        "v-data-table",
        _vm._b(
          {
            staticClass: "translation-table",
            style: "min-width: " + _vm.tableHeaders.length * 150 + "px;",
            attrs: {
              headers: _vm.tableHeaders,
              items: _vm.tableItems,
              loading: _vm.isLoading,
              options: _vm.options,
              "server-items-length": _vm.amountOfItems,
              "mobile-breakpoint": 1,
              "multi-sort": "",
              "item-key": "id",
              "footer-props": {
                "items-per-page-options": [10, 20, 50, 200, 500]
              },
              dense: _vm.dense
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body.prepend",
                  fn: function(ref) {
                    return [
                      _vm.editRows
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "edit-row-td",
                                attrs: { colspan: _vm.tableHeaders.length }
                              },
                              [
                                _c("input", {
                                  ref: "new-asset",
                                  staticClass: "edit-row-field px-4",
                                  attrs: {
                                    placeholder: "New Asset",
                                    type: "text"
                                  },
                                  on: {
                                    input: _vm.displayAddAssetTip,
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "down",
                                          40,
                                          $event.key,
                                          ["Down", "ArrowDown"]
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.moveToFirstRef()
                                    },
                                    keypress: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      return _vm.addNewAsset($event)
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    var select = ref.select
                    var headers = ref.headers
                    var index = ref.index
                    return [
                      _c(
                        "tr",
                        {
                          class: {
                            edited_row:
                              !_vm.editRows && item.id === _vm.lastOpenedItemId
                          },
                          style: _vm.dense ? "" : "height: 30px"
                        },
                        _vm._l(headers, function(header, xIndex) {
                          return _c(
                            "td",
                            {
                              key: header.value,
                              staticClass: "edit-row-td",
                              style: "width: " + 100 / headers.length + "%;",
                              on: {
                                click: function($event) {
                                  header.preventClick
                                    ? function() {}
                                    : _vm.toggleOpenAsset(item)
                                }
                              }
                            },
                            [
                              _vm.isArchiveHeader(header)
                                ? _c(
                                    "span",
                                    { staticStyle: { "margin-left": "24px" } },
                                    [
                                      item.archived
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: { small: _vm.dense },
                                              on: {
                                                click: function($event) {
                                                  return _vm.unarchive(item.id)
                                                }
                                              }
                                            },
                                            [_vm._v(" unarchive ")]
                                          )
                                        : _c(
                                            "v-icon",
                                            {
                                              attrs: { small: _vm.dense },
                                              on: {
                                                click: function($event) {
                                                  return _vm.archive(item.id)
                                                }
                                              }
                                            },
                                            [_vm._v(" archive ")]
                                          )
                                    ],
                                    1
                                  )
                                : _vm.isTagsHeader(header)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "my-auto",
                                      attrs: { name: "tagitem" }
                                    },
                                    [
                                      _c("tag-select", {
                                        staticClass: "my-auto",
                                        attrs: {
                                          small: !_vm.dense,
                                          "x-small": _vm.dense,
                                          value: item.tags
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.changeTags($event, item)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm.editRows && !header.readOnly
                                ? _c("input", {
                                    ref: "field-" + xIndex + "-" + index,
                                    refInFor: true,
                                    staticClass: "edit-row-field px-4",
                                    attrs: { type: "text" },
                                    domProps: { value: item[header.value] },
                                    on: {
                                      keydown: [
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "down",
                                              40,
                                              $event.key,
                                              ["Down", "ArrowDown"]
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.moveToInputBelow(
                                            xIndex,
                                            index
                                          )
                                        },
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "up",
                                              38,
                                              $event.key,
                                              ["Up", "ArrowUp"]
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.moveToInputAbove(
                                            xIndex,
                                            index
                                          )
                                        },
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.moveToNextField(
                                            xIndex,
                                            index
                                          )
                                        }
                                      ],
                                      change: function($event) {
                                        return _vm.editField(
                                          $event,
                                          header,
                                          item
                                        )
                                      }
                                    }
                                  })
                                : _c("span", { staticClass: "px-4" }, [
                                    _vm._v(_vm._s(item[header.value] || " - "))
                                  ])
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1145481310
            )
          },
          "v-data-table",
          _vm.$attrs,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }