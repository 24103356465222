



































































































import Vue from "vue";
import debounce from "lodash/debounce";
import { state } from '@/store';
import AssetSearchHelp from '@/components/assets/AssetSearchHelp.vue';
import ChipTagSelect from '@/components/tags/ChipTagSelect.vue';
import {languageFields} from "@/graphql/types/languageFields";
import AssetSearch from "@/components/assets/AssetSearch.vue";
import events from "@/events";

  interface FilterType {
    search: string,
    translatedInLanguage: string[],
    untranslatedInLanguage: string[],
    excludeTags: string[],
    includeTags: string[],
    showArchivedOnly: boolean,
  }

export default Vue.extend({
  name: 'AssetFilters',

  components: { AssetSearch, AssetSearchHelp, ChipTagSelect },

  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      search: null,
      searchError: null,
      displaySearchHelp: false,
      filters: this.value as FilterType,
      state,
    }
  },

  computed: {
    languageOptions(): languageFields[] {
      return this.state.project.languages
        .map(language => ({
          text: language.code,
          value: language.id,
        }));
    },
  },

  methods: {
    //@ts-ignore
    setSearch: debounce(function() { this.filters.search = this.search }, 1000),
  },

  watch: {
    filters: {
      handler (value) {
        this.$emit('input', value);
      },
      deep: true,
    },

    search(search) {
      this.setSearch();
      this.searchError = null;
    },

    'filters.excludeTags': {
      handler (value) {
        const newTags = this.filters.includeTags.filter(v => !this.filters.excludeTags.includes(v));
        if (newTags.length !== this.filters.excludeTags.length) {
          this.filters.includeTags = newTags;
        }
      },
    },

    'filters.includeTags': {
      handler () {
        const newTags = this.filters.excludeTags.filter(v => !this.filters.includeTags.includes(v));
        if (newTags.length !== this.filters.excludeTags.length) {
          this.filters.excludeTags = newTags;
        }
      },
    },

    'filters.untranslatedInLanguage': {
      handler () {
        const newTags = this.filters.translatedInLanguage.filter(v => !this.filters.untranslatedInLanguage.includes(v));
        if (newTags.length !== this.filters.translatedInLanguage.length) {
          this.filters.translatedInLanguage = newTags;
        }
      },
    },

    'filters.translatedInLanguage': {
      handler () {
        const newTags = this.filters.untranslatedInLanguage.filter(v => !this.filters.translatedInLanguage.includes(v));
        if (newTags.length !== this.filters.untranslatedInLanguage.length) {
          this.filters.untranslatedInLanguage = newTags;
        }
      },
    },
  },

  created() {
    //@ts-ignore
    this.$handleOff(events.searchError.on(message => {
      this.searchError = message;
    }));
  },

});
