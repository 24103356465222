var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch",
          value: {
            left: function() {
              return (_vm.smallSidebar = true)
            },
            right: function() {
              return (_vm.smallSidebar = false)
            }
          },
          expression:
            "{\n    left: () => smallSidebar = true,\n    right: () => smallSidebar = false\n  }"
        }
      ],
      staticClass: "drawer-wrapper",
      class: { collapsed: _vm.smallSidebar }
    },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "drawer pa-2",
          class: { collapsed: _vm.smallSidebar },
          staticStyle: { "min-height": "640px" },
          attrs: {
            "mini-variant": _vm.smallSidebar,
            fixed: "",
            permanent: "",
            dark: "",
            stateless: "",
            app: ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "sidebar-toggle",
              class: { collapsed: _vm.smallSidebar }
            },
            [
              _c(
                "div",
                { staticClass: "sidebar-toggle--inner" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "sidebar-toggle--button",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.smallSidebar = !_vm.smallSidebar
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.smallSidebar
                                ? "mdi-chevron-right"
                                : "mdi-chevron-left"
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "v-list",
            { staticClass: "my-0 sidebar", attrs: { dark: "" } },
            [
              _vm.state.loggedIn
                ? _c(
                    "v-list-item",
                    {
                      attrs: {
                        to: { name: "home" },
                        exact: "",
                        "active-class": "hide"
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        { staticClass: "white--text" },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "30" } },
                            [
                              _vm.state.project.imageUrl
                                ? _c("v-img", {
                                    attrs: { src: _vm.state.project.imageUrl }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        {
                          staticClass: "white--text",
                          staticStyle: { "text-overflow": "ellipsis" }
                        },
                        [
                          _c("v-list-item-title", [
                            _c(
                              "h3",
                              { staticClass: "capitalize_first_letter" },
                              [_vm._v(_vm._s(_vm.state.project.name))]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.state.loggedIn
                ? _c(
                    "v-list-item",
                    { attrs: { exact: "", to: { name: "home" } } },
                    [
                      _c(
                        "v-list-item-action",
                        { staticClass: "white--text" },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "30" } },
                            [
                              _vm.state.user.imageUrl
                                ? _c("v-img", {
                                    attrs: { src: _vm.state.user.imageUrl }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "white--text" },
                        [
                          _c("v-list-item-title", [
                            _c("h3", [_vm._v(_vm._s(_vm.state.user.firstName))])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.params.projectSlug
                ? _vm._l(_vm.availableRoutes, function(route) {
                    return _c(
                      "v-list-item",
                      { key: route.name, attrs: { to: { name: route.name } } },
                      [
                        _c(
                          "v-list-item-action",
                          {
                            staticClass: "white--text",
                            staticStyle: { padding: "4px" }
                          },
                          [
                            route.icon
                              ? _c("v-icon", { attrs: { medium: "" } }, [
                                  _vm._v(" " + _vm._s(route.icon) + " ")
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        route.title
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "white--text" },
                              [
                                _c("v-list-item-title", [
                                  _c("h3", [_vm._v(_vm._s(route.title))])
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _c(
                "v-list-item",
                {
                  staticClass: "chat-item",
                  class: { "v-list-item--active": _vm.chat },
                  on: {
                    click: function($event) {
                      _vm.chat = !_vm.chat
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    {
                      staticClass: "white--text",
                      staticStyle: { padding: "4px" }
                    },
                    [_c("v-icon", { attrs: { medium: "" } }, [_vm._v("chat")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "white--text" },
                    [
                      _c("v-list-item-title", [
                        _c("h3", [_vm._v(_vm._s(_vm.$t("global.chat")))])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "logout-item", on: { click: _vm.logout } },
                [
                  _c(
                    "v-list-item-action",
                    {
                      staticClass: "white--text",
                      staticStyle: { padding: "4px" }
                    },
                    [
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v("logout")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "white--text" },
                    [
                      _c("v-list-item-title", [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.$t("authentication.logout")))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }