<template>
  <v-btn
    class="item"
    :to="to"
    exact
  >
    <slot>
      <v-icon>{{ icon }}</v-icon>
    </slot>

    <span
      v-if="text"
      class="text"
    >{{ text }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'MobileItem',
  props: {
    text: { type: String, required: false, default: null },
    icon: { type: String, required: false, default: null },
    to: { type: Object, required: false, default: null, },
    active: { type: Boolean, required: false, default: false, },
  },
};
</script>
<style lang="scss">
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70px;
    margin-bottom: 3px;
  }

  .item {
    margin-left: auto;
    margin-right: auto;
  }
</style>
