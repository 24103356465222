<template>
  <v-dialog
    :value="value && show"
    :persistent="persistent"
    :no-click-animation="persistent"
    v-bind="$attrs"
    @input="close"
    @keydown.esc="close"
  >
    <v-toolbar v-if="title">
      <span class="v-toolbar__title">{{ title }}</span>
      <v-icon
        v-if="icon"
        class="mr-2 ml-auto"
        color="white"
      >
        {{ icon }}
      </v-icon>
    </v-toolbar>

    <v-card :class="{has_toolbar: !!title}">
      <v-card-title>
        <slot name="title" />
      </v-card-title>

      <v-card-text>
        <slot />
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <slot name="actions">
          <v-btn
            class="mr-0 ml-auto"
            color="primary"
            @click="close"
          >
            {{ $t('actions.close') }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GModal',

  props: {
    title: { required: false, type: String, default: null },
    icon: { required: false, type: String, default: null },
    value: { default: true, type: Boolean },
    persistent: { default: false, type: Boolean },
  },

  data() {
    return {
      show: false,
    }
  },

  watch: {
    value(v) {
      if (v) {
        this.show = true;
      }
    }
  },

  mounted() {
    this.show = true;
  },

  methods: {
    close() {
      if (this.persistent) {
        return;
      }

      // https://medium.com/js-dojo/vue-js-manage-your-modal-window-s-effortlessly-using-eventbus-518977195eed
      this.show = false;
      setTimeout(() => {
        this.$emit('input', false);
      }, 250);
    }
  },
};
</script>

<style lang="scss" scoped>
  .v-toolbar__content {
    flex-grow: 1;
    display: flex !important;
    justify-content: space-between !important;
  }

  .has_toolbar {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
</style>
