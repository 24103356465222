var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.loadScreenList, function(payload) {
      return _c(
        "g-modal",
        {
          key: payload.id,
          attrs: {
            persistent: payload.persistent,
            "max-width": "600",
            title: payload.title
          },
          model: {
            value: _vm.opened[payload.id],
            callback: function($$v) {
              _vm.$set(_vm.opened, payload.id, $$v)
            },
            expression: "opened[payload.id]"
          }
        },
        [
          _vm._v(
            " " + _vm._s(payload.text || _vm.$t("global.wait_a_second")) + " "
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }