
const abortStore = {} as Record<string, Function>;

export default function withAbortSignal(key: string) {
  if (abortStore[key]) {
    abortStore[key]();
  }

  const controller = new AbortController();
  const signal = controller.signal;
  function abort() {
    controller.abort()
  }

  abortStore[key] = abort;
  return signal
}
