var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.options || _vm.options.length === 0
    ? _c("span", { staticStyle: { opacity: ".6" } }, [
        _vm._v(" " + _vm._s(_vm.$t("tags.not_found")) + " ")
      ])
    : _c(
        "span",
        { staticStyle: { display: "inline-block" } },
        _vm._l(_vm.options, function(tag) {
          return _c("tag", {
            key: tag.id,
            staticClass: "ma-1",
            class: {
              black_and_white: !_vm.value.includes(tag.id),
              "v-chip--active": _vm.value.includes(tag.id)
            },
            staticStyle: { cursor: "pointer" },
            attrs: { tag: tag },
            nativeOn: {
              click: function($event) {
                $event.preventDefault()
                return _vm.toggleTag(tag)
              }
            }
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }