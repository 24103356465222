var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "py-4", attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md4: "" } },
        [
          _c("g-chip-select", {
            attrs: {
              label: _vm.$t("filters.translated_in"),
              items: _vm.languageOptions
            },
            model: {
              value: _vm.filters.translatedInLanguage,
              callback: function($$v) {
                _vm.$set(_vm.filters, "translatedInLanguage", $$v)
              },
              expression: "filters.translatedInLanguage"
            }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "", md4: "" } },
        [
          _c("g-chip-select", {
            attrs: {
              label: _vm.$t("filters.untranslated_in"),
              items: _vm.languageOptions
            },
            model: {
              value: _vm.filters.untranslatedInLanguage,
              callback: function($$v) {
                _vm.$set(_vm.filters, "untranslatedInLanguage", $$v)
              },
              expression: "filters.untranslatedInLanguage"
            }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { staticClass: "my-4", attrs: { xs12: "", md4: "" } },
        [
          _c(
            "div",
            {
              staticClass: "v-label mb-0",
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "space-between",
                "align-items": "center"
              }
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("filters.search")) + ":")])]
          ),
          _c("asset-search", {
            staticClass: "mt-0 pt-0",
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c("p", { staticClass: "error--text" }, [
            _vm._v(_vm._s(_vm.searchError))
          ])
        ],
        1
      ),
      _c(
        "v-flex",
        { staticClass: "py-4", attrs: { xs12: "", md4: "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "span",
                {
                  staticClass: "v-label mb-4",
                  staticStyle: { display: "block" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("filters.include_tags")) + " ")]
              ),
              _c("chip-tag-select", {
                model: {
                  value: _vm.filters.includeTags,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "includeTags", $$v)
                  },
                  expression: "filters.includeTags"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { staticClass: "py-4", attrs: { xs12: "", md4: "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "span",
                {
                  staticClass: "v-label mb-4",
                  staticStyle: { display: "block" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("filters.exclude_tags")) + " ")]
              ),
              _c("chip-tag-select", {
                model: {
                  value: _vm.filters.excludeTags,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "excludeTags", $$v)
                  },
                  expression: "filters.excludeTags"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { staticClass: "py-4", attrs: { xs12: "", sm4: "" } },
        [
          _c("v-switch", {
            attrs: { label: _vm.$t("filters.show_archived_only") },
            model: {
              value: _vm.filters.showArchivedOnly,
              callback: function($$v) {
                _vm.$set(_vm.filters, "showArchivedOnly", $$v)
              },
              expression: "filters.showArchivedOnly"
            }
          })
        ],
        1
      ),
      _vm.displaySearchHelp
        ? _c("asset-search-help", {
            on: {
              input: function($event) {
                _vm.displaySearchHelp = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }