









import Vue from 'vue'
import client from "@/client";
import store from "@/store";

export default Vue.extend({
  name: 'AssetSearch',

  props: {
    value: { type: String, required: false, default: '' }
  },

  async mounted() {
    const schema = await client.getSearchSchema(store.getProjectId());
    const { djangoQL } = await import('@/utils/djangoQL');
    djangoQL(`textarea[id=assetsearch]`, schema);
  },
})
