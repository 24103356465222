export default function callbackOnCondition(condition: Function, callback: Function, tries = 0) {
  if (condition()) {
    callback();
  } else {
    const TIMEOUT = 1000 * 0.1;
    const MAX_TIME = 1000 * 30;
    if (tries < (MAX_TIME / TIMEOUT)) {
      setTimeout(() => callbackOnCondition(condition, callback, tries + 1), TIMEOUT);
    } else {
      console.error("callbackOnCondition failed");
      callback();
    }
  }
}
