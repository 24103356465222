var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    { staticClass: "item", attrs: { to: _vm.to, exact: "" } },
    [
      _vm._t("default", [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])]),
      _vm.text
        ? _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }