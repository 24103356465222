var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.displayAddSecret
        ? _c("create-api-key-dialog", {
            on: {
              input: function($event) {
                _vm.displayAddSecret = false
              }
            }
          })
        : _vm._e(),
      _c(
        "v-card-title",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("api_keys.title")) + " "),
          _c(
            "v-btn",
            {
              staticClass: "ml-1",
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  _vm.displayAddSecret = true
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("api_keys.add")) + " "),
              _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-text"),
      _c("v-data-table", {
        attrs: {
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.apiKeys,
          "hide-default-footer": "",
          options: { itemsPerPage: 500 },
          "mobile-breakpoint": 1
        },
        scopedSlots: _vm._u([
          {
            key: "item.createdTimestamp",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm.timeAgoInWords(item.createdTimestamp)) + " "
                )
              ]
            }
          },
          {
            key: "item.lastOnlineTimestamp",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      item.lastOnlineTimestamp
                        ? _vm.timeAgoInWords(item.lastOnlineTimestamp)
                        : _vm.$t("api_keys.unused")
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.delete",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "px-2",
                    on: {
                      click: function($event) {
                        return _vm.deleteItem(item)
                      }
                    }
                  },
                  [_vm._v(" close ")]
                )
              ]
            }
          }
        ])
      }),
      _c("v-card-actions")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }