<template>
  <div class="container">
    <v-card class="ma-4">
      <api-keys />
    </v-card>

    <v-card class="ma-4 pa-4">
      <h2 class="title mb-4">Getting started</h2>

      <v-divider class="divider" />
      <h1 class="title">Export data</h1>

      <p class="mt-0 py-4 mb-0">Curl Example:</p>
      <pre class="mb-4"><code class="multiline">curl '{{ $apiUrl }}/export/xliff/EN-US?&include_empty=true&base_language=EN-US'\
     -H 'Authorization: Api-Key YOURSECRET --output EN-US.xliff'</code></pre>

      <p>Export files are directly available with <code>GET</code> requests. Simply...</p>
      <ul>
        <li>Include your authorization header <code>Authorization: Api-Key YOURSECRET</code>, these are always project-bound</li>
        <li>
          Query the url <code>{{ $apiUrl }}/export/po/ES-ES</code>, where <code>ES-ES</code> is the language code and <code>po</code> can be any of the file types:
          <ul>
            <li
              v-for="format in fileFormats"
              :key="format"
            >
              <code>{{ format }}</code>
            </li>
          </ul>
        </li>
        <li>Optionally include the search parameter <code>include_empty=true</code> to include missing translations with an empty value</li>
        <li>Optionally include a base language <code>base_language=NL-NL</code> to use translation values as keys, instead of Asset IDs</li>
        <li>Optionally include a search parameter <code>languages=AF,EN-US</code> to export multiple languages. Leave out the language code in the URL. If you export multiple language files the response will be a zipped file.</li>
      </ul>

      <v-divider class="divider" />

      <h1 class="title">GraphQL API</h1>
      <p>
        Our API uses <a
          href="https://graphql.org/"
          target="_blank"
        >GraphQL</a>. For some this
        might take some getting used to, but don't worry, you can simply give it a try in our
        <a
          :href="$graphiqlUrl"
          target="_blank"
        >GraphiQL environment</a>.
        Here you can login with your email and password, and tryout most queries and mutations
        available for your API Key.
      </p>

      <p>Use the following mutation to login:</p>
      <pre class="my-4"><code class="multiline">mutation {
  login(loginInput: { email: "YOUREMAIL", password:"YOURPASSWORD" }) {
    ok
    errors {
      field
      messages
    }
  }
}</code></pre>

      <p>And, for example, query all available assets with their translations:</p>
      <pre class="my-4"><code class="multiline">{
  assets {
    id
    value
    translations {
      value
      languageCode
    }
  }
}</code></pre>

      <a
        :href="graphiqlLink"
        target="_blank"
      >
        <v-btn color="primary">Go to GraphiQL</v-btn>
      </a>

      <a
        class="not_clear_link"
        @click="openMessageModal"
      >Something not clear? Let us know</a>
    </v-card>
  </div>
</template>

<script>
import ApiKeys from '@/components/develop/ApiKeys';
import store from '@/store';
import events from '@/events';

export default {
  name: "Develop",
  components: { ApiKeys },
  computed: {
    graphiqlLink() {
      return `${this.$graphiqlUrl}#query=mutation%20%7B%0A%20%20login(loginInput%3A%20%7B%20email%3A%20%22YOUREMAIL%22%2C%20password%3A%22YOURPASSWORD%22%20%7D)%20%7B%0A%20%20%20%20ok%0A%20%20%20%20errors%20%7B%0A%20%20%20%20%20%20field%0A%20%20%20%20%20%20messages%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A`
    },

    fileFormats() {
      return store.state.fileFormats.map(({value}) => value.toLowerCase());
    },
  },

  methods: {
    openMessageModal() {
      events.openMessageModal.emit();
    },
  },
};
</script>

<style scoped lang="scss">
  @media only screen and (min-width: 700px) {
    .not_clear_link {
      float: right;
      display: block;
      margin-right: 16px;
    }
  }

  .not_clear_link {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .divider {
    margin-top: 24px;
    margin-bottom: 30px;
  }

  .max-width {
    width: 100%;
  }

  li {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  code span {
    display:block;
  }
</style>
