

















































import Vue from 'vue';
import store from '@/store';
import CreateApiKeyDialog from '@/components/develop/CreateApiKeyDialog.vue';
import { handleOptionalErrors } from '@/utils/notifications';
import { timeAgoInWords } from '@/utils/datetime';
import client from "@/client";
import { getApiKeys_apiKeys } from "@/graphql/types/getApiKeys";
import events from "@/events";

export default Vue.extend({
  name: 'ApiKeys',

  components: { CreateApiKeyDialog },

  data() {
    return {
      apiKeys: [] as getApiKeys_apiKeys[],
      loading: false,
      displayAddSecret: false,
      headers: [
        { value: 'name', text: this.$t('global.name') },
        { value: 'prefix', text: this.$t('api_keys.prefix') },
        { value: 'createdTimestamp', text: this.$t('global.created') },
        { value: 'lastOnlineTimestamp', text: this.$t('api_keys.last_used') },
        { value: 'delete', text: this.$t('actions.revoke') },
      ]
    }
  },

  created() {
    this.fetchKeys();
    //@ts-ignore
    this.$handleOff(events.createdApiKey.on(this.fetchKeys));
  },

  methods: {
    timeAgoInWords,

    async deleteItem({id}) {
      const { errors } = await client.deleteApiKey(id);
      handleOptionalErrors(errors);
      this.loading = true;
      this.fetchKeys();
    },

    async fetchKeys() {
      this.loading = true;
      const projectId = store.state.project.id;
      const { apiKeys } = await client.getApiKeys(projectId);
      if (apiKeys) {
        //@ts-ignore
        this.apiKeys = apiKeys.filter(v => !!v);
      }
      this.loading = false;
    },
  },

});
