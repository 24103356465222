

















import Vue from 'vue';
import Project from '@/components/home/Project.vue';
import client from "@/client";
import { projectFields } from "@/graphql/types/projectFields";

export default Vue.extend({
  name: 'Projects',

  components: { Project },

  data() {
    return {
      projects: [] as projectFields[],
    }
  },

  async created() {
    this.projects = await client.getCurrentUsersProjects();
  },
});
