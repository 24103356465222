<template>
  <v-text-field
    id="email"
    ref="email"
    :value="value"
    prepend-icon="email"
    :label="$t('users.email')"
    type="text"
    name="email"
    :rules="$validation.email"
    validate-on-blur
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'EmailField',

  props: {
    value: String,
  },
};
</script>
