var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.exportData($event)
        }
      }
    },
    [
      _c(
        "div",
        { ref: "innerForm", staticClass: "inner_form pa-4" },
        [
          _c("h5", { staticClass: "subtitle-1 mb-2" }, [
            _vm._v(_vm._s(_vm.$t("export.select_languages")))
          ]),
          _c(
            "div",
            { staticClass: "language-checkbox-wrapper mb-4" },
            _vm._l(_vm.state.project.languages, function(language) {
              return _c("v-checkbox", {
                key: language.id,
                staticClass: "language-checkbox",
                attrs: { value: language.id },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _c("g-language-chip", {
                            attrs: { language: language }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.languages,
                  callback: function($$v) {
                    _vm.languages = $$v
                  },
                  expression: "languages"
                }
              })
            }),
            1
          ),
          _c("v-divider"),
          _c("h5", { staticClass: "subtitle-1 mt-4" }, [
            _vm._v(_vm._s(_vm.$t("formats.format")))
          ]),
          _c(
            "v-radio-group",
            {
              attrs: { mandatory: true },
              model: {
                value: _vm.fileFormat,
                callback: function($$v) {
                  _vm.fileFormat = $$v
                },
                expression: "fileFormat"
              }
            },
            _vm._l(_vm.state.fileFormats, function(option) {
              return _c("v-radio", {
                key: option.value,
                attrs: { label: option.description, value: option.value }
              })
            }),
            1
          ),
          _c("a", { on: { click: _vm.openMessageModal } }, [
            _vm._v(" " + _vm._s(_vm.$t("formats.missing_a_format")) + " ")
          ]),
          _c("v-divider", { staticClass: "my-4" }),
          _c("h5", { staticClass: "subtitle-1" }, [
            _vm._v(_vm._s(_vm.$t("export.empty_translations_title")))
          ]),
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("export.include_empty_explanation")) + ". "
            )
          ]),
          _c("v-switch", {
            attrs: { label: _vm.$t("export.include_empty_label") },
            model: {
              value: _vm.includeEmpty,
              callback: function($$v) {
                _vm.includeEmpty = $$v
              },
              expression: "includeEmpty"
            }
          }),
          _c("v-divider"),
          _c("h5", { staticClass: "subtitle-1 mb-2 mt-4" }, [
            _vm._v(_vm._s(_vm.$t("export.select_key")))
          ]),
          _vm._v(" " + _vm._s(_vm.$t("export.key_explanation")) + ". "),
          _c("v-switch", {
            staticClass: "pb-0",
            attrs: { label: _vm.$t("export.use_language_as_key") },
            model: {
              value: _vm.languageAsKey,
              callback: function($$v) {
                _vm.languageAsKey = $$v
              },
              expression: "languageAsKey"
            }
          }),
          _vm.languageAsKey
            ? _c("g-inline-language-select", {
                staticClass: "mb-4",
                attrs: { label: _vm.$t("export.language_to_use_as_key") },
                model: {
                  value: _vm.fromLanguage,
                  callback: function($$v) {
                    _vm.fromLanguage = $$v
                  },
                  expression: "fromLanguage"
                }
              })
            : _vm._e(),
          _c("v-btn", {
            staticStyle: { display: "none" },
            attrs: { type: "submit" },
            on: { click: _vm.exportData }
          })
        ],
        1
      ),
      _c("div", { staticClass: "pa-4" }, [
        _c(
          "div",
          { staticClass: "button-wrapper" },
          [
            _c(
              "a",
              {
                staticClass: "mr-2 ml-auto",
                class: { disabled: !_vm.downloadUrl },
                attrs: { href: _vm.downloadUrl, download: "", target: "_blank" }
              },
              [
                _c(
                  "v-btn",
                  { attrs: { disabled: !_vm.downloadUrl, color: "secondary" } },
                  [
                    _c("v-icon", [_vm._v("mdi-download")]),
                    _vm._v(" " + _vm._s(_vm.$t("export.download")) + " ")
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "mr-0",
                attrs: { loading: _vm.loading, color: "primary" },
                on: { click: _vm.exportData }
              },
              [_vm._v(" " + _vm._s(_vm.$t("export.create_export")) + " ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }