import axios from 'axios'

export type QueryResponse<T> = {
	errors: string[]
	data: T
}

export function createResponse<T>(response): QueryResponse<T> {
	const responseData = response && response.data
	const data = responseData && responseData.data
	const errors = response.errors || (responseData && responseData.errors) || []

	if (!data) {
		return {
			data: undefined,
			errors: errors.length ? errors.map((e) => e.message) : ['Something went wrong'],
		}
	}

	return { errors, ...data }
}

axios.defaults.withCredentials = true

const axiosApiInstance = axios.create({
	baseURL: `${process.env.VUE_APP_GRAPHIQL_URL}/`,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
})

export default axiosApiInstance;
