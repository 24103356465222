<template>
  <v-btn icon>
    <v-icon
      v-if="state.darkMode"
      small
      @click="setLight"
    >
      mdi-moon-waning-crescent
    </v-icon>
    <v-icon
      v-else
      small
      @click="setDark"
    >
      mdi-white-balance-sunny
    </v-icon>
  </v-btn>
</template>

<script>
import { state } from "@/store"
import { setDarkMode } from '@/utils/darkMode';

export default {
  name: 'DarkModeToggle',

  data() {
    return {
      state,
    }
  },

  methods: {
    setDark() {
      this.state.darkMode = true;
      this.$vuetify.theme.dark = true;
      setDarkMode(this.state.darkMode);
    },

    setLight() {
      this.state.darkMode = false;
      this.$vuetify.theme.dark = false;
      setDarkMode(this.state.darkMode);
    },
  }
};
</script>
