export default function getCategoriesFromStrings(values: string[]): string[] {
  const result = new Set();
  (values || []).forEach((value: string) => {
    const path = (value || '').split('.');
    for (let i = 1; i < path.length; i++) {
      path.slice(0, path.length - i)
        .forEach((_, index, arr) => {
          result.add(arr.slice(0, path.length - i).join("."));
        });
    }
  });

  return Array.from(result) as string[];
}
