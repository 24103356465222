<template>
  <div class="center-page pa-2">
    <v-card class="tab-section">
      <v-tabs
        v-model="tab"
        centered
        dark
        icons-and-text
      >
        <v-tab href="#tab-1">
          {{ $t('exports.create_export_tab') }}
          <v-icon>mdi-download</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          {{ $t('exports.previous_exports_tab') }}
          <v-icon>mdi-history</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <export-data />
        </v-tab-item>

        <v-tab-item value="tab-2">
          <previous-exports />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ExportData from '@/components/export/ExportData.vue';
import PreviousExports from '@/components/export/PreviousExports.vue';
import vueI18n from '@/plugins/i18n';

export default {
  components: { ExportData, PreviousExports },

  metaInfo: {
    get title() { return vueI18n.t("view_meta.export.title") },
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style lang="scss" scoped>
  .v-card {
    max-width: 100%;
    margin: auto;

    @media only screen and (min-width: 700px) {
      width: 600px + 36px; // 36 is padding
    }
  }
</style>
