<template>
  <div
    style="height: 100%; width: 100%;"
    @click="$refs.file.click()"
  >
    <v-img
      v-if="imageInBytes"
      class="edit_image"
      style="height: 100%; width: 100%"
      :src="imageInBytes"
      :class="{loading: uploading}"
    >
      <p
        v-if="uploading"
        class="title text--black text-center"
        style="margin-top: 45%"
      >
        ...{{ $t('images.uploading') }}
      </p>
    </v-img>

    <v-img
      v-if="value.imageUrl"
      :style="`display: ${ imageInBytes ? 'none' : ''}`"
      class="edit_image white--text"
      style="height: 100%; width: 100%"
      :src="value.imageUrl"
      @load="onFinishedLoading"
    >
      <slot />
    </v-img>

    <input
      id="file"
      ref="file"
      accept="image/*"
      type="file"
      name="file"
      style="display:none"
      @change="upload"
    >
  </div>
</template>

<script>
import { handleErrors, showErrorNotification } from '../../utils/notifications';

const imageUploadUrl = `${process.env.VUE_APP_API_URL}/upload-image/`;

export default {
  name: 'GEditImage',

  props: {
    // value a value with any fields, but should expect imageUrl and image: ID
    value: Object,
  },

  data() {
    return {
      imageInBytes: null,
      uploading: false,
    };
  },

  methods: {
    onFinishedLoading() {
      this.imageInBytes = null;
      this.uploading = false;
    },

    updateFields(objectWithFields = {}) {
      this.$emit('input', {
        ...this.value,
        ...objectWithFields,
      });
    },

    async upload(event) {
      const file = event.target.files[0];
      this.$refs.file.value = null; // clear input so next input triggers change.

      // Check for validity
      if (!file) { return showErrorNotification(); }

      // Read file so we dont have to wait for upload
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageInBytes = e.target.result;
      };
      reader.readAsDataURL(file);

      this.uploading = true;

      // Actually upload the file
      const formData = new FormData();
      formData.append('image', file);

      const config = {
        body: formData,
        method: 'post',
        credentials: 'include',
      };

      fetch(imageUploadUrl, config)
        .then(response => response.json())
        .then((data) => {
          this.updateFields({
            imageUrl: data.url,
            image: data.id,
          });
        })
        .catch(errors => handleErrors(errors));
    },
  },
};
</script>

<style>
.edit_image:hover {
  opacity: 0.7;
}
</style>
