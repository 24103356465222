var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md8: "", lg6: "" } },
        [
          _c("translate-from", {
            attrs: { asset: _vm.asset },
            on: {
              "update:asset": function($event) {
                _vm.asset = $event
              }
            }
          }),
          _c("translate-to", {
            attrs: { asset: _vm.asset },
            on: {
              "update:asset": function($event) {
                _vm.asset = $event
              },
              "set-translation-id": function($event) {
                _vm.translationId = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticClass: "tab-section",
          staticStyle: { "max-height": "85vh" },
          attrs: { xs12: "", md4: "", lg6: "" }
        },
        [
          _c("translate-tabs", {
            attrs: { "translation-id": _vm.translationId }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }