import Vue from 'vue';

const rules = {
  required: (v: string | number) => (!!v && !!v.toString().trim()) || v === '0' || v === 0 || 'required',
  emailOrNull: (v: string) => !v || /^\S+@\S+\.\S+$/.test(v) || 'invalid email',
  maxLength80: (v: string) => !v || (v && v.length <= 80) || 'max characters 80',
  createMinLength: (l: number) => (v: string) => !v || (v && v.length >= l) || `Min characters ${l}`,
};

const validation = {
  email: [rules.required, rules.emailOrNull],
  name: [rules.required, rules.maxLength80],
  password: [rules.required, rules.createMinLength(8)],
};

Vue.prototype.$validation = validation;
Vue.prototype.$rules = rules;
