<template>
  <v-select
    :value="value"
    :items="items"
    dense
    hide-details
    v-bind="$attrs"
    @input="$emit('input', $event)"
  >
    <template
      slot="item"
      slot-scope="data"
    >
      <g-language-chip :language="data.item" />
    </template>

    <template
      slot="selection"
      slot-scope="data"
    >
      <g-language-chip
        v-show="data.item.code"
        :language="data.item"
      />
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'GSelectLanguage',

  props: {
    items: Array,
    value: Object,
  },
};
</script>
