<template>
  <div class="pa-4">
    <span v-if="assetId">{{ $t('history.asset_id_history') }}</span>
    <span v-else>{{ $t('history.translation_history') }}</span>

    <div v-if="history && history.length > 0">
      <transition
        v-for="(entry) in history"
        :key="entry.id"
        appear
        name="slide-fade"
      >
        <div class="entry my-2 px-4 py-2">
          <v-tooltip
            v-if="entry.byUser"
            bottom
          >
            <template v-slot:activator="{on}">
              <v-avatar
                size="60"
                class="my-2 mr-4"
                v-on="on"
              >
                <v-img
                  v-if="entry.byUser.imageUrl"
                  :src="entry.byUser.imageUrl"
                />
              </v-avatar>
            </template>
            <span>{{ entry.byUser.fullName }}</span>
          </v-tooltip>

          <v-avatar
            v-if="entry.byApiKey"
            size="60"
            class="my-2 mr-4"
            v-on="on"
          >
            {{ $t('history.changed_by_api_key') }}<br>
            {{ entry.byApiKey.name }}
          </v-avatar>

          <div style="flex-grow: 1">
            <p class="my-0 gray">{{ timeAgoInWords(entry.timestamp) }}</p>
            <i
              v-if="entry.oldValue || entry.oldValue === ''"
              class="my-0 old_value"
            >{{ entry.oldValue }}</i>
            <i
              v-else
              class="my-0 gray"
            >{{ $t('global.created') }}</i>
            <p class="my-0">{{ entry.value }}</p>
          </div>

          <v-tooltip
            v-if="entry.byAutomatedTranslation"
            bottom
          >
            <template v-slot:activator="{on}">
              <v-icon
                small
                class="pa-1"
                style="border-radius: 50px;"
                v-on="on"
              >
                mdi-robot
              </v-icon>
            </template>
            <span>{{ $t('history.changed_by_automated_translation') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="entry.byImport"
            bottom
          >
            <template v-slot:activator="{on}">
              <v-icon
                small
                class="pa-1"
                style="border-radius: 50px;"
                v-on="on"
              >
                publish
              </v-icon>
            </template>
            <span>{{ $t('history.changed_by_import') }}</span>
          </v-tooltip>
        </div>
      </transition>
    </div>

    <div v-else>
      {{ $t('history.not_found') }}
    </div>
  </div>
</template>
<script>
import { state } from '@/store';
import { timeAgoInWords } from '@/utils/datetime';
import client from '@/client';
import events from '@/events';

export default {
  name: 'History',

  props: {
    assetId: { type: String, required: false, default: null },
    translationId: { type: String, required: false, default: null },
  },

  data() {
    return {
      historyLoaded: false,
      history: null,
      interval: null,
    }
  },

  computed: {
    user() {
      return state.user;
    },
  },

  created() {
    this.setHistory();
    this.interval = setInterval(this.setHistory, 10000);
    events.savedTranslation.on(this.setHistory);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    timeAgoInWords,

    async setHistory() {
      if (this.assetId) {
        this.history = await client.getAssetHistory(this.assetId);
      } else if (this.translationId) {
        this.history = await client.getTranslationHistory(this.translationId);
      }

      this.historyLoaded = true;
    },
  }
};
</script>

<style scoped lang="scss">
  $color-light: white;
  $color-dark: #424242;

  .theme--light {
    .gray {
      color: #acacac;
    }
    .old_value {
      color: #acacac;
    }
    .entry {
      background-color: $color-light;
    }
  }

  .theme--dark {
    .gray {
      color: #acacac;
    }
    .old_value {
      color: #acacac;
    }
    .entry {
      background-color: $color-dark;
    }
  }

  .entry {
    display:flex;
    align-items: center;
    flex-direction: row;
    border-radius: 6px;
  }

  .old_value {
    text-decoration: line-through;
  }
</style>
