import Vue from 'vue';
import Router from 'vue-router';
import PortalVue from 'portal-vue';
import UUID from 'vue-uuid';
import Meta from 'vue-meta'
import VueMask from 'v-mask'

import '@/components/global/register';
import '@/plugins/sentry';
import '@/plugins/analytics';
import '@/plugins/graphql';
import '@/plugins/i18n';
import '@/plugins/vuetify';
import '@/utils/upload';
import '@/plugins/validation';
import "@/plugins/offEventsMixin";

Vue.prototype.$graphiqlUrl = process.env.VUE_APP_GRAPHIQL_URL;
Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL;

// 3rd party plugins
Vue.use(VueMask);
Vue.use(Meta);
Vue.use(Router);
Vue.use(PortalVue); // https://linusborg.github.io/portal-vue/#/guide
// @ts-ignore
Vue.use(UUID);

Vue.config.productionTip = false;
