import Vue from 'vue';
import Router from 'vue-router';

import get from 'lodash/get';

import store from '@/store';

import Home from '@/views/Home.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import CreateAccount from '@/views/CreateAccount.vue';
import ProjectWrapper from '@/views/ProjectWrapper.vue';
import MachineTranslations from '@/views/MachineTranslations.vue';
import ProjectSettings from '@/views/ProjectSettings.vue';
import Translate from '@/views/Translate.vue';
import Export from '@/views/Export.vue';
import Develop from '@/views/Develop.vue';
import Import from '@/views/Import.vue';
import TranslationOverview from '@/views/TranslationOverview.vue';
import getRoutePermissions from './utils/getRoutePermissions';
import Empty from '@/views/Empty';
import vueI18n from '@/plugins/i18n';
import events from '@/events';
import Billing from '@/views/Billing';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'project-wrapper',
      path: '/project/:projectSlug',
      component: ProjectWrapper,
      children: [
        {
          path: 'project-settings',
          name: 'project-settings',
          component: ProjectSettings,
          get title() { return vueI18n.t('navigation.settings') },
          icon: 'settings',
          meta: { roles: ['admin'] },
        },
        {
          path: 'translate',
          name: 'translate',
          component: Translate,
          get title() { return vueI18n.t('navigation.translate') },
          icon: 'translate',
          meta: { roles: ['admin', 'translator', 'manager'] },
        },
        {
          path: 'translation-overview',
          name: 'translation-overview',
          component: TranslationOverview,
          get title() { return vueI18n.t('navigation.overview') },
          icon: 'list',
          meta: { roles: ['admin', 'developer', 'manager'] },
        },
        {
          path: 'auto-translate',
          name: 'auto-translate',
          component: MachineTranslations,
          get title() { return vueI18n.t('navigation.auto_translate') },
          icon: 'mdi-robot',
          meta: { roles: ['admin', 'manager'] },
        },
        {
          path: 'develop',
          name: 'develop',
          component: Develop,
          get title() { return vueI18n.t('navigation.develop') },
          icon: 'code',
          meta: { roles: ['admin', 'developer', 'manager'] },
        },
        {
          path: 'import',
          name: 'import',
          component: Import,
          get title() { return vueI18n.t('navigation.import') },
          icon: 'publish',
          meta: { roles: ['admin', 'developer', 'manager'] },
        },
        {
          path: 'export',
          name: 'export',
          component: Export,
          get title() { return vueI18n.t('navigation.export') },
          icon: 'save_alt',
          meta: { roles: ['admin', 'developer', 'manager'] },
        },
        {
          path: 'billing',
          name: 'billing',
          component: Billing,
          get title() { return vueI18n.t('navigation.billing') },
          icon: 'payment',
          meta: { roles: ['admin', 'manager'] },
        },
      ],
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      get title() { return vueI18n.t('navigation.home') },
      icon: 'home',
    },
    {
      path: '/create-account',
      name: "create-account",
      component: CreateAccount,
    },
    {
      path: '/reset-password',
      name: "reset-password",
      component: ResetPassword,
    },
    {
      path: '*',
      name: "404",
      component: Empty,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authenticated = () => next();
  const unauthencated = () => next('/');
  const checkRouteAndGoNext = () => {
    const projectSlug = get(to, 'params.projectSlug');
    if (!projectSlug) {
      return authenticated();
    }

    getRoutePermissions(to, projectSlug)
      ? authenticated()
      : unauthencated();
  };

  if (store.state.loggedIn !== null) {
    checkRouteAndGoNext();
  } else {
    events.authenticated.once(checkRouteAndGoNext);
    events.unauthenticated.once(checkRouteAndGoNext);
  }
});

export default router;
