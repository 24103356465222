var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.languageObj
    ? _c(
        "v-chip",
        _vm._b(
          {
            staticClass: "tiny-chip pa-0 pr-2 ma-1",
            attrs: { outlined: "" },
            on: {
              click: function($event) {
                return _vm.$emit("click")
              },
              input: function($event) {
                return _vm.$emit("input")
              }
            }
          },
          "v-chip",
          _vm.$attrs,
          false
        ),
        [
          _vm.languageObj.imageUrl
            ? _c("v-img", {
                staticClass: "flag mr-2",
                attrs: { eager: "", src: _vm.languageObj.imageUrl }
              })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.languageObj.code.toUpperCase()) + " "),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }