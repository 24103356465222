
























































































































































import plans from "@/utils/plans";

export default {
  name: 'Pricing',

  props: {
    value: { type: String, required: true },
  },

  data() {
    return {
      plans,
    }
  },

  methods: {
    setValue(value) {
      this.$emit('input', value)
    },
  }
};
