import Vue from 'vue';

Vue.mixin({
    data() {
      return {
        eventOffMethods: [],
      }
    },

    beforeDestroy() {
      // @ts-ignore
      this.eventOffMethods.forEach(call => call());
    },

    methods: {
      $handleOff(off: () => {}) {
        // @ts-ignore
        this.eventOffMethods.push(off);
      },
    },
  });
