var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "100%" } },
    [
      _vm.label
        ? _c("v-label", [
            _c(
              "div",
              { style: "min-width: " + _vm.minLabelWidth + "; z-index: 999" },
              [_vm._v(_vm._s(_vm.label) + ":")]
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center"
          }
        },
        [
          _c(
            "div",
            {
              style: "min-height: 51px; min-width: " + _vm.minInputWidth + "; "
            },
            [
              _vm.edit
                ? _c(
                    "v-text-field",
                    _vm._b(
                      {
                        ref: "input",
                        staticStyle: { "margin-top": "-2px" },
                        attrs: {
                          value: _vm.value,
                          dense: "",
                          text: "",
                          autofocus: ""
                        },
                        on: {
                          keypress: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.toggleEdit()
                          },
                          input: function($event) {
                            return _vm.$emit("input", $event)
                          }
                        }
                      },
                      "v-text-field",
                      _vm.$attrs,
                      false
                    )
                  )
                : _c("span", { staticStyle: { "text-overflow": "ellipsis" } }, [
                    _vm._v(_vm._s(_vm.value || _vm.$t("global.unset")))
                  ])
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticStyle: { "margin-bottom": "26px" },
              attrs: { icon: "", disabled: _vm.edit && !_vm.fieldIsValid },
              on: {
                click: function($event) {
                  return _vm.toggleEdit()
                }
              }
            },
            [
              _vm.edit
                ? _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" checkmark ")
                  ])
                : _c("v-icon", { attrs: { small: "" } }, [_vm._v(" edit ")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }