







































































import { state } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import { GQLProjectType } from '../../types';
import plans, { defaultPlan, getPlanObj, getPricingText } from "@/utils/plans";
import Pricing from "@/components/billing/Pricing.vue";
import CreditCardForm from "@/components/billing/CreditCardForm.vue";
import client from "@/client";
import { handleOptionalErrors } from "@/utils/notifications";

export default {
  name: 'Billing',
  components: { CreditCardForm, Pricing },
  data() {
    return {
      plans,
      defaultPlan,
      newPlan: null,
      stats: null,
      creditCards: [],
      project: cloneDeep(state.project) as GQLProjectType,
    };
  },

  computed: {
    creditCard() {
      return this.creditCards.length > 0 && this.creditCards[0];
    },

    currentPlanObj() {
      return getPlanObj(this.project.plan) || plans[0];
    },

    translationsLeft() {
      return this.stats && this.currentPlanObj.translations - this.stats.translations;
    },

    usersLeft() {
      return this.stats && this.currentPlanObj.users - this.stats.users;
    },

    exceededPlan() {
      return this.translationsLeft < 0 || this.usersLeft < 0;
    },
  },

  mounted() {
    this.setCards();
    this.setStats();
  },

  methods: {
    getPricingText,

    async setCards() {
      this.creditCards = await client.getCreditCards(this.project.id);
    },

    async setStats() {
      this.stats = await client.getUsageStatsForProject(this.project.id);
    },

    setPlan(plan) {
      if (this.project.plan === plan) {
        return;
      }
      if (plan === defaultPlan || this.creditCard) {
        this.project.plan = plan
      } else {
        this.newPlan = plan;
      }
    },

    async submitCreditCard() {
      this.project.plan = this.newPlan;
      this.newPlan = null;
      const { project, errors } = await client.selectPlan({
        id: this.project.id,
        plan: this.project.plan,
      });
      handleOptionalErrors(errors);
      this.project.trailExpiryDate = project.trailExpiryDate;
      this.setCards();
    },

    cancel() {
      this.newPlan = null;
    },
  },
};
