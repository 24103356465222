import Vue from 'vue';
import router from './router';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import App from '@/App.vue';

import '@/plugins/install';
import { authTokenInterceptor } from '@/utils/jwt';
import axiosApiInstance from '@/utils/api';

axiosApiInstance.interceptors.request.use(authTokenInterceptor);

// someday:
// Pluralization
// Character limit for translations per asset
// Hire translators
// Google translate implementation
// Intergrations (vue/react)
// Add help with translating (suggestions, etc)
// Read variables in translation, and ensure translations contain these variables
new Vue({
  i18n,
  router,
  render: h => h(App),
  vuetify,
}).$mount('#app');
