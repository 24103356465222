























import Vue from 'vue';
import store from '@/store';
import Tag from '@/components/tags/Tag.vue';
import { tagFields } from "@/graphql/types/tagFields";
import {toggle} from "@/utils/array";

export default Vue.extend({
  name: 'ChipTagSelect',

  components: { Tag },

  props: {
    value: { type: Array, required: false, default: () => [] }, // list of tag IDs
  },

  data() {
    return {
      options: [] as tagFields[],
      showAddNewTagModal: false,
    }
  },

  created() {
    store.onStateIsFetched(() => {
      this.options = store.getTagOptions();
    })
  },

  methods: {
    toggleTag(tag) {
      this.$emit('input', toggle(this.value, tag.id));
    },
  },
});
