




















































import Vue from "vue";
import Tag from "@/components/tags/Tag.vue";
import client from "@/client";
import store from "@/store";
import { handleErrors } from "@/utils/notifications";
import { TagCreateGenericType } from "../../../types/globalTypes";
import { tagFields } from "@/graphql/types/tagFields";
import {getRandomColor} from "@/utils/color";
import events from "@/events";

export default Vue.extend({
  name: 'AddTagModal',

  components: { Tag },

  props: {
    value: { type: Boolean, required: true },
  },

  data() {
    return {
      text: this.$t('tags.text_label') as string,
      color: getRandomColor(),
    }
  },

  computed: {
    tag(): TagCreateGenericType {
      return {
        text: this.text,
        color: this.color,
        project: store.getProjectId(),
      }
    },
  },

  methods: {
    async save() {
      // @ts-ignore
      const isValid: boolean = this.$refs.form.validate();
      if (!isValid) { return }

      const { errors, ok, tag } = await client.createTag(this.tag);
      if (ok) {
        store.addTag(tag as tagFields);
        events.tagsChanged.emit();
        this.close();
      } else {
        handleErrors(errors)
      }
    },

    close() {
      this.$emit('input', false);
    },
  },
});
