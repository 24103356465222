var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: {
        value: true,
        "max-width": "600",
        title: _vm.$t("view_meta.reset_password.title"),
        icon: "person",
        persistent: ""
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.submitForm } },
                [
                  _vm._v(" Send "),
                  _c("v-icon", { attrs: { right: "", dark: "", medium: "" } }, [
                    _vm._v(" how_to_reg ")
                  ])
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": _vm.showPassword ? "visibility" : "visibility_off",
              rules: _vm.$validation.password,
              "validate-on-blur": "",
              type: _vm.showPassword ? "text" : "password",
              label: _vm.$t("authentication.create_password"),
              counter: ""
            },
            on: {
              "click:append": function($event) {
                _vm.showPassword = !_vm.showPassword
              }
            },
            model: {
              value: _vm.newPassword,
              callback: function($$v) {
                _vm.newPassword = $$v
              },
              expression: "newPassword"
            }
          }),
          _c("button", {
            staticStyle: { display: "none" },
            attrs: { type: "submit" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }