var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: { "max-width": "600", title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c("v-btn", { on: { click: _vm.cancel } }, [
                _vm._v(
                  " " + _vm._s(_vm.cancelText || _vm.$t("actions.cancel")) + " "
                )
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "error",
                  attrs: { type: "button" },
                  on: { click: _vm.confirm }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.deleteText || _vm.$t("actions.delete")) +
                      " "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _vm._v(
        " " + _vm._s(_vm.text || _vm.$t("global.delete_confirmation")) + " "
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }