import { handleErrors } from './notifications';
import { postGraphql } from '@/plugins/graphql';
import mutations from '../graphql/mutations';

export async function createTranslation(assetId: string, language: string, value: string) {
  const input = {
    asset: assetId,
    language,
    value,
  };

  const response = await postGraphql(mutations.createTranslation, { input });
  const { createTranslation, errors } = response;
  if (!(createTranslation && createTranslation.ok)) {
    handleErrors(errors);
  }

  return response;
}
