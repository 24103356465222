<template>
  <div class="loader_wrapper">
    <div class="loader" />
  </div>
</template>

<script>
export default {
  name: 'GLoader',
};
</script>

<style>
.loader_wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.loader {
  box-shadow: 0 0 0 1000vh rgba(107, 107, 107, 0.3);
  position: absolute;
  z-index: 999;
}
</style>
