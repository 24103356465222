var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-text-field",
    _vm._b(
      {
        attrs: {
          value: _vm.value,
          "append-icon": _vm.showPassword ? "visibility" : "visibility_off",
          "prepend-icon": "vpn_key",
          rules: _vm.$validation.password,
          "validate-on-blur": "",
          type: _vm.showPassword ? "text" : "password",
          label: _vm.$t("authentication.login_form.password"),
          counter: ""
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          },
          "click:append": function($event) {
            _vm.showPassword = !_vm.showPassword
          }
        }
      },
      "v-text-field",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }