




















































































import Vue from 'vue';
import debounce from 'lodash/debounce';
import store, { state } from '@/store';
import { handleOptionalErrors } from '@/utils/notifications';
import DarkModeToggle from '@/components/navigation/DarkModeToggle.vue';
import UserLanguage from '@/components/home/UserLanguage.vue';
import client from '@/client';

export default Vue.extend({
  name: 'UserProfile',

  components: { UserLanguage, DarkModeToggle },

  data() {
    return {
      state,
      imageUrl: state.user.imageUrl,
      isShowingChangePasswordModal: false,
      isValid: true,
    };
  },

  watch: {
    'state.user': {
      handler() {
        this.debouncedSave();
      },
      deep: true,
    },
  },

  methods: {
    openChangePasswordModal() {
      store.openChangePasswordModal();
    },

    //@ts-ignore
    debouncedSave: debounce(function save() { this.save(); }, 500),

    async save() {
      const input = {
        id: this.state.user.id,
        firstName: this.state.user.firstName,
        email: this.state.user.email,
        lastName: this.state.user.lastName,
      } as any;

      if (this.state.user.image) { // this is only put if its updated, so don't always include it
        input.image = this.state.user.image;
      }

      const { errors } = await client.updateUser(input);
      handleOptionalErrors(errors);
    },
  },
});
