


















































































import Vue from 'vue';
import { state } from "@/store";
import getRoutePermissions from '@/utils/getRoutePermissions';
import client from '@/client';
import {Route} from "vue-router";
import {statsFields} from "@/graphql/types/statsFields";

export default Vue.extend({
  name: 'Project',

  props: { project: { type: Object, required: true } },

  data() {
    return {
      projectDetails: {},
      stats: {} as statsFields,
      projectStatsAreFetched: false,
      state,
    }
  },

  computed: {
    projectPage(): any {
      return this.availableRoutes && this.availableRoutes[0] && this.availableRoutes[0].name
    },

    availableRoutes(): Route[] {
      if (this.state.user.roles) {
        // @ts-ignore
        return this.$router.options.routes
          .find(route => route.name === 'project-wrapper')
          .children
          .filter(route => getRoutePermissions(route, this.project.slug));
      } else return []
    },
  },

  async created() {
    this.stats = await client.getStatsForProject(this.project.id);
    if (this.stats) {
      this.stats.translated = this.$n(this.stats.translated, 'percent');
      this.projectStatsAreFetched = true;
    }
  },

  methods: {
    getTranslatedStatsName(key) {
      return {
        users: this.$t("stats.users"),
        assets: this.$t("stats.assets"),
        languages: this.$t("stats.languages"),
        translated: this.$t("stats.translated"),
      }[key]
    },
  },
});
