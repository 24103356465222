<template>
  <div class="wrapper">
    <div class="card-form">
      <div class="card-list">
        <div
          class="card-item"
          :class="{'-active': isCardFlipped}"
        >
          <div class="card-item__side -front">
            <div
              ref="focusElement"
              class="card-item__focus"
              :class="{'-active': focusElementStyle}"
              :style="focusElementStyle"
            />
            <div class="card-item__cover">
              <img
                :src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'"
                class="card-item__bg"
              >
            </div>

            <div class="card-item__wrapper">
              <div class="card-item__top">
                <img
                  src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                  class="card-item__chip"
                >
                <div class="card-item__type">
                  <transition name="slide-fade-up">
                    <img
                      v-if="getCardType"
                      :key="getCardType"
                      :src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'"
                      alt=""
                      class="card-item__typeImg"
                    >
                  </transition>
                </div>
              </div>
              <label
                ref="cardNumber"
                for="cardNumber"
                class="card-item__number"
              >
                <template v-if="getCardType === 'amex'">
                  <span
                    v-for="(n, $index) in amexCardMask"
                    :key="$index"
                  >
                    <transition name="slide-fade-up">
                      <div
                        v-if="$index > 4 && $index < 14 && card.number.length > $index && n.trim() !== ''"
                        class="card-item__numberItem"
                      >*</div>
                      <div
                        v-else-if="card.number.length > $index"
                        :key="$index"
                        class="card-item__numberItem"
                        :class="{'-active': n.trim() === ''}"
                      >
                        {{ card.number[$index] }}
                      </div>
                      <div
                        v-else
                        :key="$index + 1"
                        class="card-item__numberItem"
                        :class="{'-active': n.trim() === ''}"
                      >{{ n }}</div>
                    </transition>
                  </span>
                </template>

                <template v-else>
                  <span
                    v-for="(n, $index) in otherCardMask"
                    :key="$index"
                  >
                    <transition name="slide-fade-up">
                      <div
                        v-if="$index > 4 && $index < 15 && card.number.length > $index && n.trim() !== ''"
                        class="card-item__numberItem"
                      >*</div>
                      <div
                        v-else-if="card.number.length > $index"
                        :key="$index"
                        class="card-item__numberItem"
                        :class="{'-active': n.trim() === ''}"
                      >
                        {{ card.number[$index] }}
                      </div>
                      <div
                        v-else
                        :key="$index + 1"
                        class="card-item__numberItem"
                        :class="{'-active': n.trim() === ''}"
                      >{{ n }}</div>
                    </transition>
                  </span>
                </template>
              </label>
              <div class="card-item__content">
                <label
                  ref="cardName"
                  for="cardName"
                  class="card-item__info"
                >
                  <div class="card-item__holder">Card Holder</div>
                  <transition name="slide-fade-up">
                    <div
                      v-if="card.name.length"
                      key="1"
                      class="card-item__name"
                    >
                      <transition-group name="slide-fade-right">
                        <span
                          v-for="(n, $index) in card.name.replace(/\s\s+/g, ' ')"
                          v-if="$index === $index"
                          :key="$index + 1"
                          class="card-item__nameItem"
                        >{{ n }}</span>
                      </transition-group>
                    </div>
                    <div
                      v-else
                      key="2"
                      class="card-item__name"
                    >Full Name</div>
                  </transition>
                </label>
                <div
                  ref="cardDate"
                  class="card-item__date"
                >
                  <label
                    for="cardMonth"
                    class="card-item__dateTitle"
                  >Expires</label>
                  <label
                    for="cardMonth"
                    class="card-item__dateItem"
                  >
                    <transition name="slide-fade-up">
                      <span
                        v-if="card.month"
                        :key="card.month"
                      >{{ card.month }}</span>
                      <span
                        v-else
                        key="2"
                      >MM</span>
                    </transition>
                  </label>
                  /
                  <label
                    for="cardYear"
                    class="card-item__dateItem"
                  >
                    <transition name="slide-fade-up">
                      <span
                        v-if="card.year"
                        :key="card.year"
                      >{{ String(card.year).slice(2,4) }}</span>
                      <span
                        v-else
                        key="2"
                      >YY</span>
                    </transition>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-item__side -back">
            <div class="card-item__cover">
              <img
                :src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'"
                class="card-item__bg"
              >
            </div>
            <div class="card-item__band" />
            <div class="card-item__cvv">
              <div class="card-item__cvvTitle">CVV</div>
              <div class="card-item__cvvBand">
                <span
                  v-for="(n, $index) in card.cvv"
                  :key="$index"
                >
                  *
                </span>
              </div>
              <div class="card-item__type">
                <img
                  v-if="getCardType"
                  :src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'"
                  class="card-item__typeImg"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-form__inner">
        <div class="card-input">
          <label
            for="cardNumber"
            class="card-input__label"
          >Card Number</label>
          <input
            id="cardNumber"
            v-model="card.number"
            v-mask="generateCardNumberMask"
            type="text"
            class="card-input__input"
            data-ref="cardNumber"
            autocomplete="off"
            @focus="focusInput"
            @blur="blurInput"
          >
        </div>
        <div class="card-input">
          <label
            for="cardName"
            class="card-input__label"
          >Card Holders</label>
          <input
            id="cardName"
            v-model="card.name"
            type="text"
            class="card-input__input"
            data-ref="cardName"
            autocomplete="off"
            @focus="focusInput"
            @blur="blurInput"
          >
        </div>
        <div class="card-form__row">
          <div class="card-form__col">
            <div class="card-form__group">
              <label
                for="cardMonth"
                class="card-input__label"
              >Expiration Date</label>
              <select
                id="cardMonth"
                v-model="card.month"
                class="card-input__input -select"
                data-ref="cardDate"
                @focus="focusInput"
                @blur="blurInput"
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Month
                </option>
                <option
                  v-for="n in 12"
                  :key="n"
                  :value="n < 10 ? '0' + n : n"
                  :disabled="n < minCardMonth"
                >
                  {{ n < 10 ? '0' + n : n }}
                </option>
              </select>
              <select
                id="cardYear"
                v-model="card.year"
                class="card-input__input -select"
                data-ref="cardDate"
                @focus="focusInput"
                @blur="blurInput"
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Year
                </option>
                <option
                  v-for="(n, $index) in 12"
                  :key="n"
                  :value="$index + minCardYear"
                >
                  {{ $index + minCardYear }}
                </option>
              </select>
            </div>
          </div>
          <div class="card-form__col -cvv">
            <div class="card-input">
              <label
                for="cardCvv"
                class="card-input__label"
              >CVV</label>
              <input
                id="cardCvv"
                v-model="card.cvv"
                v-mask="'####'"
                type="text"
                class="card-input__input"
                maxlength="4"
                autocomplete="off"
                @focus="flipCard(true)"
                @blur="flipCard(false)"
              >
            </div>
          </div>
        </div>

        <p class="text--secondary">
          {{ payingForText }}
        </p>

        <p class="text--secondary">
          Other invoicing details will have to be filled after the trail period
        </p>

        <v-btn
          color="primary"
          @click="submit"
          :loading="submitting"
        >
          Submit
        </v-btn>

        <v-btn class="ml-4" style="color: black" @click="cancel">Cancel</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// See on github: https://github.com/muhammederdem/credit-card-form
import client from '@/client';
import {
  handleErrors,
  showSuccessNotification,
  showWarningNotification
} from '@/utils/notifications';

export default {
  name: 'CreditCardForm',

  props: {
    payingForText: { type: String, required: false, default: null },
  },

  data() {
    return {
      card: {
        name: "",
        number: "",
        month: "",
        year: "",
        cvv: "",
      },

      submitting: false,
      currentCardBackground: Math.floor(Math.random()* 25 + 1), // just for fun :D
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false
    };
  },

  computed: {
    getCardType() {
      let number = this.card.number;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp('^9792')
      if (number.match(re) != null) return 'troy';

      return "visa"; // default type
    },

    generateCardNumberMask () {
      return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
    },

    minCardMonth () {
      if (this.card.year === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    }
  },

  watch: {
    'card.year': {
      handler() {
        if (this.card.month < this.minCardMonth) {
          this.card.month = "";
        }
      }
    },
  },

  mounted() {
    this.cardNumberTemp = this.otherCardMask;
    document.getElementById("cardNumber").focus();
  },

  methods: {
    cancel() {
      this.resetState();
      this.$emit('cancel');
    },

    resetState() {
      this.card = {
        name: "",
        number: "",
        month: "",
        year: "",
        cvv: "",
      };
      this.submitting = false;
    },

    async submit() {
      const requiredKeys = [
        'name',
        'number',
        'month',
        'year',
      ];
      const emptyKeys = requiredKeys.filter(key => !this.card[key]);
      if (emptyKeys.length > 0) {
        return showWarningNotification("Please fill " + emptyKeys.join(", "));
      }

      if (this.card.number.length < 15) {
        return showWarningNotification("Please fill a valid number");
      }

      this.submitting = true;
      const { errors, ok } = await client.createCreditCard({
        typeName: this.getCardType,
        holderName: this.card.name,
        number: this.card.number,
        month: parseInt(this.card.month),
        year: parseInt(this.card.year),
        cvv: this.card.cvv,
      });

      if (!ok) {
        return handleErrors(errors);
      }

      this.card = {
        name: "",
        number: "",
        month: "",
        year: "",
        cvv: "",
      };

      showSuccessNotification("Plan Upgraded!");
      this.$emit('submit');
    },

    flipCard (status) {
      this.isCardFlipped = status;
    },

    focusInput (e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
      }
    },

    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    }
  }
};
</script>

<style scoped lang="scss">

  .card-input {
    margin-bottom: 20px;
    &__label {
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 500;
      color: #1a3b5d;
      width: 100%;
      display: block;
      user-select: none;
    }
    &__input {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      box-shadow: none;
      border: 1px solid #ced6e0;
      transition: all 0.3s ease-in-out;
      font-size: 18px;
      padding: 5px 15px;
      background: none;
      color: #1a3b5d;
      font-family: "Source Sans Pro", sans-serif;

      &:hover,
      &:focus {
        border-color: #3d9cff;
      }

      &:focus {
        box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
      }
      &.-select {
        -webkit-appearance: none;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
        background-size: 12px;
        background-position: 90% center;
        background-repeat: no-repeat;
        padding-right: 30px;
      }
    }
  }

  .theme--dark {
    &__inner {
      background: #000000;
      box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    }
  }

  @import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

  body {
    background: #ddeefc;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
  }
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  .wrapper {
    min-height: 100vh;
    display: flex;
    padding: 50px 15px;
    @media screen and (max-width: 700px), (max-height: 500px) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  .card-form {
    max-width: 570px;
    margin: auto;
    width: 100%;

    @media screen and (max-width: 576px) {
      margin: 0 auto;
    }

    &__inner {
      background: #fff;
      // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
      box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
      border-radius: 10px;
      padding: 35px;
      padding-top: 180px;

      @media screen and (max-width: 480px) {
        padding: 25px;
        padding-top: 165px;
      }
      @media screen and (max-width: 360px) {
        padding: 15px;
        padding-top: 165px;
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;
      @media screen and (max-width: 480px) {
        flex-wrap: wrap;
      }
    }

    &__col {
      flex: auto;
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 480px) {
        margin-right: 0;
        flex: unset;
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.-cvv {
        max-width: 150px;
        @media screen and (max-width: 480px) {
          max-width: initial;
        }
      }
    }

    &__group {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .card-input__input {
        flex: 1;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__button {
      width: 100%;
      height: 55px;
      background: #2364d2;
      border: none;
      border-radius: 5px;
      font-size: 22px;
      font-weight: 500;
      font-family: "Source Sans Pro", sans-serif;
      box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
      margin-top: 20px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        margin-top: 10px;
      }
    }
  }

  .card-item {
    max-width: 430px;
    height: 270px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    width: 100%;

    @media screen and (max-width: 480px) {
      max-width: 310px;
      height: 220px;
      width: 90%;
    }

    @media screen and (max-width: 360px) {
      height: 180px;
    }

    &.-active {
      .card-item__side {
        &.-front {
          transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
        }
        &.-back {
          transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
          // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
        }
      }
    }

    &__focus {
      position: absolute;
      z-index: 3;
      border-radius: 5px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.65);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(8, 20, 47);
        height: 100%;
        border-radius: 5px;
        filter: blur(25px);
        opacity: 0.5;
      }

      &.-active {
        opacity: 1;
      }
    }

    &__side {
      border-radius: 15px;
      overflow: hidden;
      // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
      box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
      transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
      transform-style: preserve-3d;
      transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      backface-visibility: hidden;
      height: 100%;

      &.-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
        z-index: 2;
        padding: 0;
        // background-color: #2364d2;
        // background-image: linear-gradient(
        //   43deg,
        //   #4158d0 0%,
        //   #8555c7 46%,
        //   #2364d2 100%
        // );
        height: 100%;

        .card-item__cover {
          transform: rotateY(-180deg)
        }
      }
    }
    &__bg {
      max-width: 100%;
      display: block;
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &__cover {
      height: 100%;
      background-color: #1c1d27;
      position: absolute;
      height: 100%;
      background-color: #1c1d27;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 15px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 2, 29, 0.45);
      }
    }

    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      padding: 0 10px;

      @media screen and (max-width: 480px) {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 360px) {
        margin-bottom: 15px;
      }
    }

    &__chip {
      width: 60px;
      @media screen and (max-width: 480px) {
        width: 50px;
      }
      @media screen and (max-width: 360px) {
        width: 40px;
      }
    }

    &__type {
      height: 45px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      max-width: 100px;
      margin-left: auto;
      width: 100%;

      @media screen and (max-width: 480px) {
        height: 40px;
        max-width: 90px;
      }
      @media screen and (max-width: 360px) {
        height: 30px;
      }
    }

    &__typeImg {
      max-width: 100%;
      object-fit: contain;
      max-height: 100%;
      object-position: top right;
    }

    &__info {
      color: #fff;
      width: 100%;
      max-width: calc(100% - 85px);
      padding: 10px 15px;
      font-weight: 500;
      display: block;

      cursor: pointer;

      @media screen and (max-width: 480px) {
        padding: 10px;
      }
    }

    &__holder {
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: 6px;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    &__wrapper {
      font-family: "Source Code Pro", monospace;
      padding: 25px 15px;
      position: relative;
      z-index: 4;
      height: 100%;
      text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
      user-select: none;
      @media screen and (max-width: 480px) {
        padding: 20px 10px;
      }
    }

    &__name {
      font-size: 18px;
      line-height: 1;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
    &__nameItem {
      display: inline-block;
      min-width: 8px;
      position: relative;
    }

    &__number {
      font-weight: 500;
      line-height: 1;
      color: #fff;
      font-size: 27px;
      margin-bottom: 35px;
      display: inline-block;
      padding: 10px 15px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        font-size: 21px;
        margin-bottom: 15px;
        padding: 10px 10px;
      }

      @media screen and (max-width: 360px) {
        font-size: 19px;
        margin-bottom: 10px;
        padding: 10px 10px;
      }
    }

    &__numberItem {
      width: 16px;
      display: inline-block;
      &.-active {
        width: 30px;
      }

      @media screen and (max-width: 480px) {
        width: 13px;

        &.-active {
          width: 16px;
        }
      }

      @media screen and (max-width: 360px) {
        width: 12px;

        &.-active {
          width: 8px;
        }
      }
    }

    &__content {
      color: #fff;
      display: flex;
      align-items: flex-start;
    }

    &__date {
      flex-wrap: wrap;
      font-size: 18px;
      margin-left: auto;
      padding: 10px;
      display: inline-flex;
      width: 80px;
      white-space: nowrap;
      flex-shrink: 0;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }

    &__dateItem {
      position: relative;
      span {
        width: 22px;
        display: inline-block;
      }
    }

    &__dateTitle {
      opacity: 0.7;
      font-size: 13px;
      padding-bottom: 6px;
      width: 100%;

      @media screen and (max-width: 480px) {
        font-size: 12px;
        padding-bottom: 5px;
      }
    }
    &__band {
      background: rgba(0, 0, 19, 0.8);
      width: 100%;
      height: 50px;
      margin-top: 30px;
      position: relative;
      z-index: 2;
      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 360px) {
        height: 40px;
        margin-top: 10px;
      }
    }

    &__cvv {
      text-align: right;
      position: relative;
      z-index: 2;
      padding: 15px;
      .card-item__type {
        opacity: 0.7;
      }

      @media screen and (max-width: 360px) {
        padding: 10px 15px;
      }
    }
    &__cvvTitle {
      padding-right: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 5px;
    }
    &__cvvBand {
      height: 45px;
      background: #fff;
      margin-bottom: 30px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      color: #1a3b5d;
      font-size: 18px;
      border-radius: 4px;
      box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

      @media screen and (max-width: 480px) {
        height: 40px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 360px) {
        margin-bottom: 15px;
      }
    }
  }

  .card-list {
    margin-bottom: -130px;

    @media screen and (max-width: 480px) {
      margin-bottom: -120px;
    }
  }


  .slide-fade-up-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-up-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-up-enter {
    opacity: 0;
    transform: translateY(15px);
    pointer-events: none;
  }
  .slide-fade-up-leave-to {
    opacity: 0;
    transform: translateY(-15px);
    pointer-events: none;
  }

  .slide-fade-right-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-right-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-right-enter {
    opacity: 0;
    transform: translateX(10px) rotate(45deg);
    pointer-events: none;
  }
  .slide-fade-right-leave-to {
    opacity: 0;
    transform: translateX(-10px) rotate(45deg);
    pointer-events: none;
  }
</style>
