var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-combobox",
    _vm._b(
      {
        staticClass: "inline-select",
        staticStyle: { width: "100%" },
        attrs: {
          value: _vm.value,
          items: _vm.items,
          "item-text": "value",
          "hide-no-data": "",
          "search-input": _vm.search,
          placeholder: _vm.$t("assets.asset_id"),
          "menu-props": { disableKeys: true, openOnClick: false }
        },
        on: {
          "update:searchInput": function($event) {
            _vm.search = $event
          },
          "update:search-input": function($event) {
            _vm.search = $event
          },
          keyup: _vm.handleInput
        },
        nativeOn: {
          keydown: function($event) {
            return _vm.handleKeyPress($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var parent = ref.parent
              var item = ref.item
              return [
                _c("span", [
                  _c("span", { staticStyle: { color: "gray" } }, [
                    _vm._v(_vm._s(_vm.getFirstCategoriesText(item)))
                  ]),
                  _vm._v(_vm._s(_vm.getLastCategoryText(item)) + " ")
                ])
              ]
            }
          }
        ])
      },
      "v-combobox",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }