var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab", { attrs: { href: "#1" } }, [
            _vm._v(_vm._s(_vm.$t("history.title")))
          ]),
          _c("v-tab", { attrs: { href: "#2" } }, [
            _vm._v(_vm._s(_vm.$t("comments.title")))
          ])
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { staticClass: "tab-item", attrs: { value: "1" } },
            [
              _vm.translationId
                ? _c("history", {
                    key: _vm.translationId + "11",
                    attrs: { "translation-id": _vm.translationId }
                  })
                : _c("v-subheader", { staticClass: "font-weight-light px-4" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("comments.translation_missing")) + " "
                    )
                  ])
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "tab-item", attrs: { value: "2" } },
            [
              _vm.translationId
                ? _c("comments", {
                    key: _vm.translationId + "21",
                    attrs: { "translation-id": _vm.translationId }
                  })
                : _c("v-subheader", { staticClass: "font-weight-light px-4" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("comments.translation_missing")) + " "
                    )
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }