





















































import Vue from 'vue';
import client from '@/client';
import store from '@/store';
import NewOrderModal from "@/components/machinetranslations/NewOrderModal.vue";
import OrderInfoModal from "@/components/machinetranslations/OrderInfoModal.vue";
import { machineTranslationsOrderFields } from "@/graphql/types/machineTranslationsOrderFields";
import {timeAgoInWords} from "@/utils/datetime";
let intervalHandler;

export default Vue.extend({
  name: 'MachineTranslationsTable',

  components: { NewOrderModal, OrderInfoModal },

  data() {
    return {
      headers: [
        { value: 'source', text: this.$t('translate.from') },
        { value: 'to', text: this.$t('translate.to') },
        { value: 'count', text: this.$t('order.char_count') },
        { value: 'status', text: this.$t('order.status') },
        { value: 'createdTimestamp', text: this.$t('global.created') },
      ],
      items: [] as machineTranslationsOrderFields[],
      addModal: false,
      selectedId: null,
    }
  },

  watch: {
    addModal(addModal) {
      if (!addModal) {
        this.fetchItems();
      }
    },
  },

  created() {
    this.fetchItems();
    intervalHandler = setInterval(this.fetchItems, 30000);
  },

  destroyed() {
    clearInterval(intervalHandler);
  },

  methods: {
    timeAgoInWords,

    selectItem(item) {
      this.selectedId = item.id;
    },

    async fetchItems() {
      this.items = await client.getMachineTranslationOrders(store.getProjectId());
    },
  },
});
