import Vue from 'vue';
import get from 'lodash/get';
import { init } from '@sentry/browser';
import { Vue as VueIntergration } from '@sentry/integrations';

// eslint-disable-next-line no-constant-condition
if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntergration({ Vue, attachProps: true })],
    release: process.env.VUE_APP_RELEASE,
    environment: process.env.VUE_APP_ENVIRONMENT,
    beforeSend(event) {

      // Filter abort errors
      const errorValue = get(event, 'exception.values[0].value');
      if (errorValue.includes("AbortError")) {
        return null
      }

      return event;
    }
  });
}
