var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    { staticClass: "mobile-navigation", attrs: { fixed: "", app: "" } },
    [
      _c("navigation-item", {
        attrs: {
          to: { name: "home" },
          text: _vm.$t("navigation.home"),
          icon: "home"
        }
      }),
      _vm._l(_vm.availableRoutes, function(route) {
        return _c("navigation-item", {
          key: route.name,
          attrs: {
            to: { name: route.name },
            text: route.title,
            icon: route.icon
          }
        })
      }),
      _c("navigation-item", {
        attrs: { active: _vm.chat, icon: "chat", text: _vm.$t("global.chat") },
        nativeOn: {
          click: function($event) {
            _vm.chat = !_vm.chat
          }
        }
      }),
      _c("navigation-item", {
        attrs: { icon: "logout", text: _vm.$t("authentication.logout") },
        nativeOn: {
          click: function($event) {
            return _vm.logout($event)
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }