var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "mx-2", attrs: { wrap: "", row: "" } },
    [
      _c("g-edit-image", {
        staticClass: "ma-4",
        staticStyle: {
          height: "208px",
          width: "208px",
          "border-radius": "12px",
          overflow: "hidden"
        },
        on: { change: _vm.save },
        model: {
          value: _vm.state.user,
          callback: function($$v) {
            _vm.$set(_vm.state, "user", $$v)
          },
          expression: "state.user"
        }
      }),
      _c(
        "v-flex",
        { attrs: { xs12: "", md8: "", lg6: "", "mx-4": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "my-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { sm6: "", xs12: "" } },
                [
                  _c("g-toggle-text-field", {
                    staticStyle: { height: "60px" },
                    attrs: {
                      label: _vm.$t("users.first_name"),
                      rules: _vm.$validation.name,
                      required: ""
                    },
                    model: {
                      value: _vm.state.user.firstName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.state.user,
                          "firstName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "state.user.firstName"
                    }
                  }),
                  _c("g-toggle-text-field", {
                    staticStyle: { height: "60px" },
                    attrs: {
                      label: _vm.$t("users.last_name"),
                      rules: _vm.$validation.name,
                      required: ""
                    },
                    model: {
                      value: _vm.state.user.lastName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.state.user,
                          "lastName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "state.user.lastName"
                    }
                  }),
                  _c("v-text-field", {
                    staticStyle: { height: "60px" },
                    attrs: {
                      disabled: "",
                      label: _vm.$t("users.email"),
                      rules: _vm.$validation.email
                    },
                    model: {
                      value: _vm.state.user.email,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.state.user,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "state.user.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticStyle: { "align-items": "center" },
                  attrs: { sm6: "", xs12: "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticStyle: { "align-items": "center" } },
                    [
                      _c("span", { staticClass: "mr-1" }, [
                        _vm._v(_vm._s(_vm.$t("users.theme")))
                      ]),
                      _c("dark-mode-toggle")
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticStyle: { "align-items": "center", display: "flex" }
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("users.language")))]),
                      _c("user-language")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4 mr-4",
                      attrs: {
                        color: "primary",
                        to: {
                          name: "project-settings",
                          params: { projectSlug: "new-project" }
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("projects.new")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4",
                      on: { click: _vm.openChangePasswordModal }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("authentication.change_password")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }