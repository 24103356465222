

















































































































import Vue from 'vue';
import store, { state } from '@/store';
import { handleErrors, showWarningNotification } from '@/utils/notifications';
import client from '@/client';
import {FormatChoice} from "../../../types/globalTypes";
import events from "@/events";

export default Vue.extend({
  name: 'ExportData',

  data() {
    return {
      state,
      language: null,
      languages: state.project.languages.map(l => l.id),
      fileFormat: 1,
      downloadUrl: null,
      loading: false,
      includeEmpty: false,
      languageAsKey: false,
      fromLanguage: null,
    };
  },

  watch: {
    languageAsKey(languageAsKey) {
      if (languageAsKey) {
        this.$nextTick(() => {
          const innerForm = this.$refs.innerForm;
          //@ts-ignore
          innerForm.scrollTop = innerForm.scrollHeight;
        });
      }
    },
  },

  created() {
    store.onStateIsFetched(() => {
      this.language = state.project.languages[0];
      this.fileFormat = state.fileFormats[0].value;
    });
  },

  methods: {
    openMessageModal() {
      events.openMessageModal.emit();
    },

    async exportData() {
      if (this.languages.length === 0) {
        showWarningNotification(this.$t("global.no_language_selected"));
        return;
      }

      this.downloadUrl = null;
      this.loading = true;

      const input = {
        format: this.fileFormat as unknown as FormatChoice,
        fromLanguage: null,
        includeEmpty: this.includeEmpty,
        languageIds: this.languages,
      };

      if (this.languageAsKey && this.fromLanguage) {
        input.fromLanguage = this.fromLanguage;
      }

      setTimeout(() => {
        if (this.loading) {
          showWarningNotification(this.$t('export.large_export_message'));
        }
      }, 5 * 1000);

      const { ok, errors, exportedData } = await client.exportData(input);

      this.loading = false;

      if (ok && exportedData) {
        this.downloadUrl = exportedData.url;
        events.exportCreated.emit();
      } else {
        handleErrors(errors);
      }
    },
  },
});
