import fromUnixTime from 'date-fns/fromUnixTime';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import i18n, {$t} from '@/plugins/i18n';

export function timeAgoInWords(timestamp: number | null | undefined, displayInDateFormatAfterMinutes = 60 * 24 * 3) {
  if (timestamp === null || timestamp === undefined) {
    return "";
  }

  const lastOnline = fromUnixTime(timestamp);
  const minutesSinceOnline = Math.abs(differenceInMinutes(lastOnline, new Date()));
  const time = format(lastOnline, 'HH:mm');

  if (displayInDateFormatAfterMinutes && minutesSinceOnline > displayInDateFormatAfterMinutes) {
    return i18n.d(lastOnline);
  }

  if (minutesSinceOnline < 3) {
    return "just now";
  }

  if (minutesSinceOnline < 60) {
    return `${minutesSinceOnline} minutes ago`;
  }

  const hoursSinceOnline = Math.abs(differenceInHours(lastOnline, new Date()));
  if (hoursSinceOnline < 24) {
    return `${hoursSinceOnline} hours ago`;
  }

  const daysSinceOnline = Math.abs(differenceInDays(lastOnline, new Date()));
  if (daysSinceOnline === 1) {
    return `Yesterday at ${time}`;
  }

  if (daysSinceOnline < 7) {
    return `${daysSinceOnline} ${$t("time.days")} ${$t("time.ago")}`;
  }

  return `${Math.floor(daysSinceOnline / 7)} ${$t("time.weeks")} ${$t("time.ago")}`;
}
