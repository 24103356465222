var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    _vm._b(
      {
        attrs: {
          value: _vm.value && _vm.show,
          persistent: _vm.persistent,
          "no-click-animation": _vm.persistent
        },
        on: {
          input: _vm.close,
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.close($event)
          }
        }
      },
      "v-dialog",
      _vm.$attrs,
      false
    ),
    [
      _vm.title
        ? _c(
            "v-toolbar",
            [
              _c("span", { staticClass: "v-toolbar__title" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm.icon
                ? _c(
                    "v-icon",
                    { staticClass: "mr-2 ml-auto", attrs: { color: "white" } },
                    [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { class: { has_toolbar: !!_vm.title } },
        [
          _c("v-card-title", [_vm._t("title")], 2),
          _c("v-card-text", [_vm._t("default")], 2),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "pa-4" },
            [
              _vm._t("actions", [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-0 ml-auto",
                    attrs: { color: "primary" },
                    on: { click: _vm.close }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("actions.close")) + " ")]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }