<template>
  <v-bottom-navigation
    class="mobile-navigation"
    fixed
    app
  >
    <navigation-item
      :to="{name: 'home'}"
      :text="$t('navigation.home')"
      icon="home"
    />

    <navigation-item
      v-for="route in availableRoutes"
      :key="route.name"
      :to="{name: route.name}"
      :text="route.title"
      :icon="route.icon"
    />

    <navigation-item
      :active="chat"
      icon="chat"
      :text="$t('global.chat')"
      @click.native="chat = !chat"
    />

    <navigation-item
      icon="logout"
      :text="$t('authentication.logout')"
      @click.native="logout"
    />
  </v-bottom-navigation>
</template>
<script>
import { state } from '@/store';
import { logout } from '../../utils/auth';
import getRoutePermissions from '../../utils/getRoutePermissions';
import chatclient from '@/utils/chatclient';
import syncStateWithStorage from '@/mixins/sync-state-with-storage';
import NavigationItem from '@/components/navigation/NavigationItem';

export default {
  components: { NavigationItem },

  mixins: [syncStateWithStorage(['smallSidebar', 'chat'])],

  data() {
    return {
      state,
      smallSidebar: true,
      chat: false,
    };
  },

  computed: {
    availableRoutes() {
      const { projectSlug } = this.$route.params;
      if (!projectSlug) {
        return [];
      }

      return this.$router.options.routes
        .find(route => route.name === 'project-wrapper').children
        .filter(route => getRoutePermissions(route, projectSlug));
    },
  },

  watch: {
    chat() {
      this.setChat();
    },
  },

  mounted() {
    this.setChat();
    chatclient.addClosedCallback(() => {
      this.chat = false;
    });
  },

  methods: {
    setChat() {
      if (this.chat) {
        chatclient.show()
      } else {
        chatclient.hide()
      }
    },

    logout,
  },
};
</script>

<style lang="scss">
  .mobile-navigation {
    overflow-x: scroll;
    overflow-y: hidden;
    justify-content: unset !important;
  }
</style>
