import Vue from 'vue';
import router from "@/router";

// Old version
import VueAnalytics from 'vue-analytics';
Vue.use(VueAnalytics, {
  id: 'UA-158336836-1',
  router,
  sendHitTask: process.env.NODE_ENV === 'production'
});

// New version:
import VueGtag from "vue-gtag";
import { userFields } from "@/graphql/types/userFields";
import { projectFields } from "@/graphql/types/projectFields";
const enabled = process.env.NODE_ENV === 'production';

if (enabled) {
// TODO Test this after deploying
  Vue.use(VueGtag, {
    config: {
      id: 'UA-158336836-1',
      enabled: process.env.NODE_ENV === 'production',
      pageTrackerEnabled: true,
      pageTrackerScreenviewEnabled: true,
      appName: "TranslationHut",
    },
  }, router);
}

function setSessionData(data: object) {
  //@ts-ignore
  if (enabled) { Vue.$gtag.customMap(data); }
}



export default {
  setUser(user: userFields) { setSessionData({
    'email1': user.email,
    'username2': user.username,
  })},
  //@ts-ignore
  setProject(project: projectFields) { setSessionData({
    'projectName3': project.name,
    'projectId4': project.id,
  })},
}
