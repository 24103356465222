var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: { value: true, "max-width": "600" },
      on: { input: _vm.hideModal },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _vm.secret
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$emit("input", false)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("actions.close")) + " ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", type: "submit" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.submit($event)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("actions.add")) + " ")]
                      )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-carousel",
        {
          attrs: {
            height: "360",
            "hide-delimiter-background": "",
            "hide-delimiters": "",
            touchless: "",
            "show-arrows": false,
            value: _vm.secret ? 2 : 1
          }
        },
        [
          _c(
            "v-carousel-item",
            { attrs: { value: 1 } },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("api_keys.create")))
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        staticClass: "mt-4",
                        staticStyle: { "max-width": "350px" },
                        attrs: {
                          dark: this.$vuetify.theme.isDark,
                          light: !this.$vuetify.theme.isDark,
                          label: _vm.$t("global.name")
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c("button", {
                    staticStyle: { display: "none" },
                    attrs: { type: "submit" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-carousel-item",
            { attrs: { value: 2 } },
            [_c("display-secret", { attrs: { secret: _vm.secret } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }