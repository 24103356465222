import { render, staticRenderFns } from "./TranslateTabs.vue?vue&type=template&id=3551236b&"
import script from "./TranslateTabs.vue?vue&type=script&lang=js&"
export * from "./TranslateTabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VSubheader,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3551236b')) {
      api.createRecord('3551236b', component.options)
    } else {
      api.reload('3551236b', component.options)
    }
    module.hot.accept("./TranslateTabs.vue?vue&type=template&id=3551236b&", function () {
      api.rerender('3551236b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/translate/TranslateTabs.vue"
export default component.exports