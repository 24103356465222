const DARK_MODE_KEY = "textmanager.00001.prefs.darkmode";

function browserIsLightMode(): boolean | null | undefined {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches;
}

export function getDarkMode(): boolean {
  const DEFAULT = false;
  const userPreference = localStorage.getItem(DARK_MODE_KEY);

  if (userPreference) {
    return userPreference === `${true}`;
  }

  return browserIsLightMode() !== true || DEFAULT;
}

export function setDarkMode(isDarkMode: boolean) {
  localStorage.setItem(DARK_MODE_KEY, `${isDarkMode}`);
}
