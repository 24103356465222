<template>
  <v-dialog
    :value="displayModal"
    v-bind="$attrs"
    style="max-height: 500px"
    @input="close"
    @keydown.esc="close"
  >
    <v-tabs
      v-model="tab"
      dark
    >
      <v-tab href="#1">{{ $t('assets.edit') }}</v-tab>
      <v-tab href="#2">{{ $t('history.title') }}</v-tab>
      <v-tab href="#3">{{ $t('comments.title') }}</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="tab-items"
    >
      <v-tab-item
        value="1"
        class="tab-items"
      >
        <edit-asset-form
          v-if="displayModal"
          class="tab-items"
          :asset="asset"
          @close="close"
        />
      </v-tab-item>

      <v-tab-item
        value="2"
        class="tab-items"
      >
        <history
          :key="asset.id"
          :asset-id="asset.id"
          class="tab-items"
        />
      </v-tab-item>

      <v-tab-item
        value="3"
        class="tab-items"
      >
        <comments
          :key="asset.id"
          :asset-id="asset.id"
          class="tab-items"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import EditAssetForm from '@/components/assets/EditAssetForm';
import History from '@/components/History';
import Comments from '@/components/Comments';
import events from '@/events';

export default {
  name: 'EditAssetDialog',

  components: { Comments, History, EditAssetForm },

  data() {
    return {
      displayModal: false,
      asset: {},
      tab: "1",
    };
  },

  created() {
    this.$handleOff(events.openAsset.on(asset => {
      this.asset = cloneDeep(asset);
      this.displayModal = true;
    }))
  },

  methods: {
    close() {
      this.displayModal = false;
      events.fetchAssets.emit();
      this.asset = {};

      setTimeout(() => {
        this.$emit('input', false);
      }, 250);
    }
  },
};
</script>

<style scoped>
  .tab-items {
    min-width: 500px;
    max-width: 90vw;
    min-height: 500px;
    max-height: calc(90vh - 140px);
  }
</style>
