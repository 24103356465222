var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "center-page px-2 px-md-4 py-4" }, [
    _c(
      "div",
      { staticClass: "px-md-2", staticStyle: { width: "100%" } },
      [
        _c("div", { staticClass: "translate-toolbar pt-2 pb-4" }, [
          _c(
            "span",
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mx-1",
                                staticStyle: { opacity: ".6" },
                                attrs: { icon: "" },
                                on: { click: _vm.downloadTable }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("download")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("assets.export_table.button_tooltip")))
                  ])
                ]
              ),
              _c(
                "g-toggle-icon",
                {
                  attrs: {
                    "tooltip-text": _vm.$t("filters.select_columns_tooltip")
                  },
                  model: {
                    value: _vm.displayColumnSelect,
                    callback: function($$v) {
                      _vm.displayColumnSelect = $$v
                    },
                    expression: "displayColumnSelect"
                  }
                },
                [_vm._v(" mdi-table-column-plus-before ")]
              ),
              _c(
                "g-toggle-icon",
                {
                  attrs: {
                    "tooltip-text": _vm.$t("filters.dense_view_tooltip")
                  },
                  model: {
                    value: _vm.dense,
                    callback: function($$v) {
                      _vm.dense = $$v
                    },
                    expression: "dense"
                  }
                },
                [_vm._v(" view_headline ")]
              ),
              _c(
                "g-toggle-icon",
                {
                  attrs: {
                    "tooltip-text": _vm.$t("filters.show_filters_tooltip")
                  },
                  model: {
                    value: _vm.displayFilters,
                    callback: function($$v) {
                      _vm.displayFilters = $$v
                    },
                    expression: "displayFilters"
                  }
                },
                [_vm._v(" filter_list ")]
              ),
              _c(
                "g-toggle-icon",
                {
                  attrs: {
                    "tooltip-text": _vm.$t("filters.edit_mode_tooltip")
                  },
                  model: {
                    value: _vm.editRows,
                    callback: function($$v) {
                      _vm.editRows = $$v
                    },
                    expression: "editRows"
                  }
                },
                [_vm._v(" edit ")]
              ),
              _vm.state.isAdmin
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.displayAddAsset = true
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("assets.add")) + " "),
                      _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _c("edit-asset-dialog", { attrs: { "max-width": "600" } }),
        _vm.displayAddAsset
          ? _c("add-assets-dialog", {
              attrs: { "max-width": "600", title: _vm.$t("assets.new") },
              on: {
                input: function($event) {
                  return _vm.closeAddAssetDialog()
                }
              }
            })
          : _vm._e(),
        _vm.displayColumnSelect
          ? _c(
              "g-modal",
              {
                attrs: { "max-width": "600", title: _vm.$t("global.columns") },
                model: {
                  value: _vm.displayColumnSelect,
                  callback: function($$v) {
                    _vm.displayColumnSelect = $$v
                  },
                  expression: "displayColumnSelect"
                }
              },
              [
                _c("g-chip-select", {
                  attrs: { items: _vm.headerOptions, mandatory: "" },
                  model: {
                    value: _vm.displayHeaders,
                    callback: function($$v) {
                      _vm.displayHeaders = $$v
                    },
                    expression: "displayHeaders"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "v-expand-transition",
          [
            _c("asset-filters", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.displayFilters,
                  expression: "displayFilters"
                }
              ],
              model: {
                value: _vm.filters,
                callback: function($$v) {
                  _vm.filters = $$v
                },
                expression: "filters"
              }
            })
          ],
          1
        ),
        _c(
          "v-card-text",
          { staticClass: "pa-0 table-wrapper" },
          [
            _c("asset-table", {
              attrs: {
                filters: _vm.filters,
                dense: _vm.dense,
                "display-headers": _vm.displayHeaders,
                "edit-rows": _vm.editRows
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }