var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-alert", { attrs: { type: "warning" } }, [
        _vm._v(_vm._s(_vm.$t("api_keys.add_key_warning")))
      ]),
      _c("p", [_vm._v(_vm._s(_vm.$t("api_keys.warning_list_title")))]),
      _c("ol", { staticClass: "mb-4 pb-4" }, [
        _c("li", [_vm._v(_vm._s(_vm.$t("api_keys.add_form.close_warning")))]),
        _c("li", [
          _vm._v(" " + _vm._s(_vm.$t("api_keys.list_item.1")) + ", "),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href:
                  "https://hackernoon.com/how-to-use-environment-variables-keep-your-secret-keys-safe-secure-8b1a7877d69c"
              }
            },
            [
              _vm._v(
                _vm._s(_vm.$t("api_keys.add_form.keep_secure_link_text")) + "!"
              )
            ]
          )
        ])
      ]),
      _c("h4", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(_vm.$t("api_keys.your_secret")) + ":")
      ]),
      _c("code", { staticClass: "pa-2 mb-4" }, [_vm._v(_vm._s(_vm.secret))]),
      _c("h4", { staticClass: "mt-4" }, [
        _vm._v(
          _vm._s(_vm.$t("api_keys.add_form.secret_usage_explanation")) + ":"
        )
      ]),
      _c("code", { staticClass: "pa-2" }, [
        _vm._v("Authorization: Api-Key " + _vm._s(_vm.secret))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }