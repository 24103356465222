var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "previous-export-list",
        staticStyle: { "min-height": "80vh" },
        attrs: {
          headers: _vm.headers,
          items: _vm.exportedData,
          "mobile-breakpoint": 1,
          "disable-sort": "",
          "items-per-page": 10
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [_vm._v(" " + _vm._s(item.name) + " ")]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.timeAgoInWords(item.createdOnTimestamp)) +
                        " "
                    )
                  ]),
                  _c("td", [_vm._v(" " + _vm._s(item.type) + " ")]),
                  _c("td", { staticStyle: { width: "30px" } }, [
                    _c(
                      "a",
                      {
                        staticClass: "mr-2 ml-auto",
                        attrs: {
                          href: item.url,
                          download: "",
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "v-btn",
                          { staticClass: "ml-1", attrs: { icon: "" } },
                          [_c("v-icon", [_vm._v("mdi-download")])],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }