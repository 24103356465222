import { captureException as sentryCaptureException, addBreadcrumb } from '@sentry/browser';

const production = process.env.VUE_APP_ENVIRONMENT === 'production';

function captureException(exception: Error) {
  if (!production) {
    console.error(exception);
    throw exception;
  } else {
    sentryCaptureException(JSON.parse(JSON.stringify(exception)));
  }
}

function log(message: string) {
  if (!production) {
    console.info(message);
  } else {
    addBreadcrumb({ message });
  }
}

export { captureException, log };
