









export default {
  name: 'AssetSearchHelp',
};
