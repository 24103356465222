<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab href="#1">{{ $t('history.title') }}</v-tab>
      <v-tab href="#2">{{ $t('comments.title') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        value="1"
        class="tab-item"
      >
        <history
          v-if="translationId"
          :key="translationId + '11'"
          :translation-id="translationId"
        />
        <v-subheader
          v-else
          class="font-weight-light px-4"
        >
          {{ $t('comments.translation_missing') }}
        </v-subheader>
      </v-tab-item>

      <v-tab-item
        value="2"
        class="tab-item"
      >
        <comments
          v-if="translationId"
          :key="translationId + '21'"
          :translation-id="translationId"
        />
        <v-subheader
          v-else
          class="font-weight-light px-4"
        >
          {{ $t('comments.translation_missing') }}
        </v-subheader>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Comments from '@/components/Comments';
import History from '@/components/History';
export default {
  name: 'TranslateTabs',

  components: { History, Comments },

  props: {
    translationId: { type: String, required: false, default: null },
  },

  data() {
    return {
      tab: "1",
    }
  },
};
</script>
