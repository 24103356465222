import isNil from 'lodash/isNil';
import store from '@/store'
import config from '@/constants/config';

export default (storedStates = [], settings={}) => {
  settings.watch = settings.watch || true;
  settings.storagePrefix = settings.storagePrefix || "";
  settings.prefixWithProjectId = settings.prefixWithProjectId || true;

  return {
    created() {
      this.loadState();
      if (settings.watch !== false) {
        this.watchState();
      }
    },

    beforeDestroy() {
      this.saveState();
    },

    methods: {
      loadState() {
        storedStates.forEach(this.setFromStorage);
      },

      saveState() {
        storedStates.forEach(this.setToStorage);
      },

      watchState() {
        storedStates.forEach((keyState) => {
          this.$watch(keyState, this.saveState, {
            deep: true,
          });
        });
      },

      getKey(stateKey) {
        const { prefixWithProjectId, storagePrefix } = settings;
        let prefix = storagePrefix;

        if (prefixWithProjectId) {
          prefix += store.state.project.id || 'noproject';
        }

        return `${config.localStoragePrefix}.${prefix}${stateKey}`;
      },

      setToStorage(stateKey) {
        const value = this[stateKey];
        if (!isNil(value)) {
          const stringValue = JSON.stringify(value);
          localStorage.setItem(this.getKey(stateKey), stringValue);
          // console.log('set', this.getKey(stateKey), stringValue);
        }
      },

      setFromStorage(stateKey) {
        const stringValue = localStorage.getItem(this.getKey(stateKey));
        const value = stringValue && JSON.parse(stringValue);

        if (!isNil(value)) {
          this[stateKey] = value;
          // console.log('get', this.getKey(stateKey), stringValue);
        }
      },
    },
  }
};
