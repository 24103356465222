

















import Vue from 'vue';
import client from "@/client";
import store from "@/store";
import {usageFields} from "@/graphql/types/usageFields";
export default Vue.extend({
  name: 'UsageInfo',

  data() {
    return {
      usage: {} as usageFields,
    };
  },

  async created() {
    this.usage = await client.getUsageForProject(store.getProjectId());
  },
});
