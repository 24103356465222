import { render, staticRenderFns } from "./ProjectWrapper.vue?vue&type=template&id=e8c51218&"
import script from "./ProjectWrapper.vue?vue&type=script&lang=js&"
export * from "./ProjectWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e8c51218')) {
      api.createRecord('e8c51218', component.options)
    } else {
      api.reload('e8c51218', component.options)
    }
    module.hot.accept("./ProjectWrapper.vue?vue&type=template&id=e8c51218&", function () {
      api.rerender('e8c51218', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ProjectWrapper.vue"
export default component.exports