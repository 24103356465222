var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { "margin-left": "-12px", "margin-right": "-12px" },
      attrs: { wrap: "", row: "" }
    },
    _vm._l(_vm.projects, function(project) {
      return _c(
        "v-flex",
        { key: project.id, attrs: { xs12: "", sm6: "" } },
        [_c("project", { staticClass: "ma-4", attrs: { project: project } })],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }