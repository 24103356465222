



































































































































































import Vue from 'vue';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import store, { state } from '@/store';
import getIds from '../utils/getIds';
import SelectLanguages from '@/components/SelectLanguages.vue';
import Users from '@/components/projectsettings/Users.vue';
import ManageTags from '@/components/projectsettings/ManageTags.vue';
import client from '@/client';
import {
  handleErrors,
  showWarningNotification
} from '@/utils/notifications';
import { GQLProjectType } from "../../types";
import {ProjectUpdateGenericType} from "../../types/globalTypes";
import events from "@/events";
import {projectDetailFields} from "@/graphql/types/projectDetailFields";
import { getPricingText } from "@/utils/plans";

export default Vue.extend({
  name: 'ProjectSettings',

  components: { SelectLanguages, Users, ManageTags },

  data() {
    return {
      state,
      project: cloneDeep(state.project) as GQLProjectType,
      addLanguageModal: false,
      loading: false,
      cancelSaveHandle: () => {},
    };
  },

  computed: {
    isLoadingNewProject(): boolean {
      //@ts-ignore
      return this.$route.params.projectSlug === "new-project" || !this.project.id
    },
  },

  watch: {
    project: {
      deep: true,
      handler() {
        if (this.cancelSaveHandle) {
          this.cancelSaveHandle();
        }
        //@ts-ignore
        this.cancelSaveHandle = this.debouncedSave();
      },
    },
  },

  methods: {
    //@ts-ignore
    debouncedSave: debounce(function save() { this.save(); }, 1000),

    getPricingText,

    async save() {
      const currentSlug = this.$route.params.projectSlug;
      if (!store.getUserIsAdmin(currentSlug)) { return; }
      if (!this.project.name) { return; }

      const input = omit(this.project, ['imageUrl', 'trailExpiryDate']) as ProjectUpdateGenericType;
      if (this.project.users) { input.users = getIds(this.project.users as any); }
      if (this.project.languages) { input.languages = getIds(this.project.languages as any); }

      const { ok, errors, project } = await client.updateProjectWithAbort(input);
      if (!ok) {
        handleErrors(errors);
        return;
      } else {
        store.setProject(this.project as projectDetailFields);
      }

      // Handle updated slug
      const newSlug = project && project.slug;
      if (newSlug && currentSlug !== newSlug) {
        store.updateProjectSlug(currentSlug, newSlug);
        await this.$router.replace({
          params: { projectSlug: newSlug },
        });
      }
    },

    confirmDeleteLanguage(languageId) {
      events.openConfirmDeleteDialog.emit({ callback: () => this.deleteLanguage(languageId) });
    },

    deleteLanguage(languageId) {
      if (!this.project.languages) { return; }
      if (this.project.languages.length < 2) {
        showWarningNotification(this.$t('project_settings.one_language_required'));
      } else {
        const index = this.project.languages.findIndex((v: any) => v.id === languageId);
        this.project.languages.splice(index, 1);
      }
    },

    askDeleteConfirmation() {
      events.openConfirmDeleteDialog.emit({ callback: () => this.delete() });
    },

    async delete() {
      this.project.isDeleted = true;
      await this.save();
      events.refetchState.emit();
      this.$router.push({ name: 'home' });
    },
  },
});
