import { captureException } from '@sentry/browser';

const cloudName = process.env.VUE_APP_CLOUDINARY_NAME;
const uploadPreset = process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
const url = `https://api.cloudinary.com/v1_1/${cloudName}/raw/upload`;

export default async function upload(file: File) {
  if (!file) { throw Error("No file was supplied to 'upload()'"); }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', uploadPreset);

  const options = {
    body: formData,
    method: 'post',
    credentials: 'omit' as RequestCredentials,
  };

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response: Response) => response.json())
      .then((json: any) => resolve(json))
      .catch((error: Error) => {
        captureException(error);
        reject(error);
      });
  });
}
