<template>
  <g-inline-language-select
    v-model="appLanguage"
    :items="languageOptions"
    item-value="code"
  />
</template>

<script>
import store from '@/store';
import callbackOnCondition from '@/utils/callbackOnCondition';
import vueI18n from '@/plugins/i18n';

export default {
  name: 'UserLanguage',

  data() {
    return {
      languageOptions: [],
      appLanguage: this.$i18n.locale,
    }
  },

  watch: {
    appLanguage: store.setLocale,
  },

  created() {
    callbackOnCondition(
      () => store.getAllLanguages().length > 0,
      // combine available messages with flags from backend
      () => { this.languageOptions = vueI18n.availableLocales.map(store.getLanguageByCode) }
    );
  },
};
</script>
