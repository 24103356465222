var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "textarea-wrapper pa-4",
      staticStyle: { "min-height": "200px" }
    },
    [
      _c(
        "v-layout",
        { staticStyle: { "justify-content": "space-between" } },
        [
          _c("g-inline-language-select", {
            attrs: {
              "disable-default": "",
              label: _vm.$t("translate.to") + ":"
            },
            model: {
              value: _vm.translateTo,
              callback: function($$v) {
                _vm.translateTo = $$v
              },
              expression: "translateTo"
            }
          }),
          _c(
            "g-toggle-icon",
            {
              staticClass: "my-auto ml-1",
              model: {
                value: _vm.displayFilters,
                callback: function($$v) {
                  _vm.displayFilters = $$v
                },
                expression: "displayFilters"
              }
            },
            [_vm._v(" filter_list ")]
          )
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-layout",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.displayFilters,
                  expression: "displayFilters"
                }
              ],
              staticClass: "pt-4",
              attrs: { wrap: "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [_c("v-label", { staticClass: "mb-0" }, [_vm._v("Filter:")])],
                1
              ),
              _c("v-checkbox", {
                staticClass: "mr-4 mt-0",
                attrs: {
                  label:
                    _vm.$t("filters.translated_in") + " " + _vm.translateToCode
                },
                on: {
                  change: function($event) {
                    _vm.filters.untranslatedInTargetLanguage = false
                  }
                },
                model: {
                  value: _vm.filters.translatedInTargetLanguage,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "translatedInTargetLanguage", $$v)
                  },
                  expression: "filters.translatedInTargetLanguage"
                }
              }),
              _c("v-checkbox", {
                staticClass: "mt-0",
                attrs: {
                  label:
                    _vm.$t("filters.untranslated_in") +
                    " " +
                    _vm.translateToCode
                },
                on: {
                  change: function($event) {
                    _vm.filters.translatedInTargetLanguage = false
                  }
                },
                model: {
                  value: _vm.filters.untranslatedInTargetLanguage,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "untranslatedInTargetLanguage", $$v)
                  },
                  expression: "filters.untranslatedInTargetLanguage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      !_vm.asset.id
        ? _c("v-label", { attrs: { color: "mt-4" } }, [
            _vm._v(" " + _vm._s(_vm.$t("translate.not_found")) + ". "),
            _vm.isFiltered
              ? _c("a", { on: { click: _vm.resetFilters } }, [
                  _vm._v(_vm._s(_vm.$t("filters.reset")))
                ])
              : _vm._e()
          ])
        : _c("v-text-field", {
            staticClass: "my-4 textarea",
            attrs: {
              placeholder:
                _vm.translateToCode + " " + _vm.$t("translate.translation"),
              solo: "",
              flat: ""
            },
            on: {
              keypress: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.saveTranslation($event)
              }
            },
            model: {
              value: _vm.translationValue,
              callback: function($$v) {
                _vm.translationValue = $$v
              },
              expression: "translationValue"
            }
          }),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-items": "end",
            "align-items": "center"
          }
        },
        [
          _c("v-checkbox", {
            staticStyle: { "flex-grow": "1" },
            attrs: { label: _vm.$t("translate.next_on_save") },
            model: {
              value: _vm.nextOnSave,
              callback: function($$v) {
                _vm.nextOnSave = $$v
              },
              expression: "nextOnSave"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto",
              attrs: { icon: "" },
              on: { click: _vm.previousTranslation }
            },
            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
            1
          ),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.nextTranslation } },
            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", outlined: "" },
              on: { click: _vm.saveTranslation }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.nextOnSave
                      ? _vm.$t("translate.save_and_next")
                      : _vm.$t("actions.save")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }