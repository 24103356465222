<template>
  <machine-translations-table />
</template>

<script>
import vueI18n from '@/plugins/i18n';
import MachineTranslationsTable from '@/components/machinetranslations/MachineTranslationsTable';

export default {
  name: 'MachineTranslations',
  components: { MachineTranslationsTable },
  metaInfo: {
    get title() { return vueI18n.t("view_meta.machine_translations.title") },
  },
};
</script>
