import { print } from 'graphql/language/printer';
import { captureException } from '@sentry/browser';

import queries from '@/graphql/queries';
import mutations from '@/graphql/mutations';
import { Result } from "@/types";
import axiosApiInstance, { createResponse } from '@/utils/api';

function createResult(response: any): Result {
  const errors = response.errors || [];

  if (!response.data) {
    return {
      errors: errors.length ? errors : ['Something went wrong'],
    };
  }

  // Combine all errors into an array
  errors.push(...Object
    .values(response.data)
    // @ts-ignore
    .flatMap(obj => obj && obj.errors)
    .filter(v => !!v));

  return {
    errors,
    ...response.data,
  };
}

// convert some error string to graphql-structure error response
function createErrorResponse(errorMessage: string): Result {
  return createResult({
    errors: [{
      field: null,
      message: errorMessage,
    }],
  });
}

function postGraphql(queryString, variables = {}, fetchOptions = {}): Promise<any> {
  const data = JSON.stringify({
    query: `${print(queryString)}`,
    variables,
  });

  return new Promise((resolve, reject) => {
    axiosApiInstance.post('/', data, fetchOptions)
      .then(response => resolve(createResponse(response)))
      .catch((error) => {
        // Failed fetch is always an unexpected error, since expected
        // errors will be in "response.errors". Send it to sentry.
        captureException(error);
        reject(createErrorResponse(error));
      });
  });
}

export {
  queries, mutations, postGraphql,
};
