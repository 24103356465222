var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: { "max-width": "400", value: true },
      on: {
        input: function($event) {
          return _vm.$emit("input", false)
        }
      }
    },
    [
      _c("p", [
        _vm._v(" " + _vm._s(_vm.$t("orders.ordered_by")) + " "),
        _c("b", [_vm._v(_vm._s(_vm.order.byUser.fullName))]),
        _vm._v(", "),
        _c("b", [
          _vm._v(_vm._s(_vm.timeAgoInWords(_vm.order.createdTimestamp)))
        ])
      ]),
      _c(
        "p",
        { staticClass: "mb-2" },
        [
          _vm._v(" " + _vm._s(_vm.$t("translate.from")) + " "),
          _c("g-language-chip", {
            attrs: { "language-id": _vm.order.source.id }
          })
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "mt-0" },
        [
          _vm._v(" " + _vm._s(_vm.$t("translate.to")) + " "),
          _c("g-language-chip", { attrs: { "language-id": _vm.order.to.id } })
        ],
        1
      ),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(_vm._s(_vm.$t("translate.translations")) + ":")
      ]),
      _vm.order.translations.length === 0
        ? _c("p", [_vm._v(_vm._s(_vm.$t("global.none")))])
        : _vm._e(),
      _vm._l(_vm.order.translations, function(translation) {
        return _c("p", { key: translation.id, staticClass: "mb-0" }, [
          _vm._v(
            " " +
              _vm._s(translation.fromValue) +
              " > " +
              _vm._s(translation.toValue) +
              " "
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }