<template>
  <g-modal
    :value="true"
    max-width="600"
    :title="$t('view_meta.create_account.title')"
    icon="person"
    persistent
  >
    <v-form
      ref="form"
      @submit.prevent="submit"
    >
      <email-field v-model.trim="email" />
      <password-field v-model.trim="password" />
      <button
        style="display: none"
        @click="submit()"
      />
    </v-form>

    <v-alert
      :value="errors.length > 0"
      color="error"
    >
      <h3
        v-for="error in errors"
        :key="error"
      >
        {{ error.message }}
      </h3>
    </v-alert>

    <template #actions>
      <v-spacer />
      <v-btn
        class="mr-2"
        @click="logout()"
      >
        {{ $t('authentication.go_to_login') }}
      </v-btn>

      <v-btn
        color="primary"
        @click="submit"
      >
        {{ $t('authentication.create_account') }}
        <v-icon
          right
          dark
          medium
        >
          how_to_reg
        </v-icon>
      </v-btn>
    </template>
  </g-modal>
</template>

<script>
import {
  handleErrors,
} from '@/utils/notifications';
import { authenticate, logout } from '@/utils/auth';
import vueI18n from '@/plugins/i18n';
import EmailField from '@/components/EmailField';
import client from '@/client';
import PasswordField from '@/components/PasswordField';

export default {
  name: 'CreateAccount',

  components: { PasswordField, EmailField },

  metaInfo: {
    get title() { return vueI18n.t("view_meta.create_account.title") },
  },

  data() {
    return {
      failedCreateAccount: false,
      email: null,
      password: null,
      errors: [],
    };
  },

  created() {
    logout(false);
  },

  methods: {
    logout,

    async submit() {
      if (await this.$refs.form.validate()) {
        const { ok, errors } =
          await client.createAccount({
            email: this.email,
            password: this.password,
          });
        if (!ok) {
          handleErrors(errors);
          return;
        }

        await authenticate();
        this.$router.push('/');
      }
    },
  },
};
</script>
