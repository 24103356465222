var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "center-page pa-2" },
    [
      _c(
        "v-card",
        { staticClass: "tab-section" },
        [
          _c(
            "v-tabs",
            {
              attrs: { centered: "", dark: "", "icons-and-text": "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab",
                { attrs: { href: "#tab-1" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("exports.create_export_tab")) + " "
                  ),
                  _c("v-icon", [_vm._v("mdi-download")])
                ],
                1
              ),
              _c(
                "v-tab",
                { attrs: { href: "#tab-2" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("exports.previous_exports_tab")) + " "
                  ),
                  _c("v-icon", [_vm._v("mdi-history")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "tab-1" } },
                [_c("export-data")],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-2" } },
                [_c("previous-exports")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }