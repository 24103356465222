var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: {
        value: true,
        "max-width": "600",
        title: _vm.$t("view_meta.create_account.title"),
        icon: "person",
        persistent: ""
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  on: {
                    click: function($event) {
                      return _vm.logout()
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("authentication.go_to_login")) + " "
                  )
                ]
              ),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.submit } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("authentication.create_account")) + " "
                  ),
                  _c("v-icon", { attrs: { right: "", dark: "", medium: "" } }, [
                    _vm._v(" how_to_reg ")
                  ])
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c("email-field", {
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "email"
            }
          }),
          _c("password-field", {
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "password"
            }
          }),
          _c("button", {
            staticStyle: { display: "none" },
            on: {
              click: function($event) {
                return _vm.submit()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-alert",
        { attrs: { value: _vm.errors.length > 0, color: "error" } },
        _vm._l(_vm.errors, function(error) {
          return _c("h3", { key: error }, [
            _vm._v(" " + _vm._s(error.message) + " ")
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }