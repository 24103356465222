


















import Vue from 'vue';
import store, { state } from '@/store';
import Projects from '@/components/home/Projects.vue';
import UserProfile from '@/components/home/UserProfile.vue';
import vueI18n from "@/plugins/i18n";

export default Vue.extend({
  components: { UserProfile, Projects },

  metaInfo: {
    get title() { return vueI18n.t("view_meta.home.title") as string },
  },

  data() {
    return {
      state,
    };
  },

  created() {
    store.resetState('project');
  }
});
