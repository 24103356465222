var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "page py-4", attrs: { wrap: "", column: "" } },
    [
      _c(
        "form",
        { key: _vm.formkey || "123", staticClass: "drag-and-drop-zone" },
        [
          _c("input", {
            ref: "file",
            staticClass: "file-input",
            attrs: { id: "file", type: "file", name: "file", accept: ".json" },
            on: { change: _vm.upload }
          }),
          _c(
            "div",
            { staticClass: "drag-and-drop-content" },
            [
              _vm.uploading
                ? _c("p", [_vm._v("Uploading...")])
                : _c("p", [_vm._v("Choose a .json file or drag it here")]),
              _c(
                "v-btn",
                {
                  staticClass: "mx-auto",
                  attrs: {
                    loading: _vm.uploading,
                    disabled: _vm.uploading,
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$refs.file.click()
                    }
                  }
                },
                [
                  _vm._v(" Upload "),
                  _c("v-icon", { staticClass: "ml-2" }, [_vm._v("mdi-upload")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "g-modal",
            {
              attrs: {
                width: "400",
                title: _vm.$t("import.select_language"),
                persistent: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 mb-2 ml-auto",
                          on: {
                            click: function($event) {
                              return _vm.cancelAskingForLanguage()
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 mb-2",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.doneAskingForLanguage()
                            }
                          }
                        },
                        [_vm._v(" Done ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.isAskingForLanguage,
                callback: function($$v) {
                  _vm.isAskingForLanguage = $$v
                },
                expression: "isAskingForLanguage"
              }
            },
            [
              _c(
                "p",
                { staticClass: "mb-4" },
                [
                  _c("v-icon", { attrs: { medium: "" } }, [_vm._v("warning")]),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("This might overwrite your current translations")
                  ])
                ],
                1
              ),
              _c("p", [
                _vm._v("What is the language in the translation file?")
              ]),
              _c("p", [
                _vm._v("file name: "),
                _c("b", [_vm._v(_vm._s(_vm.fileName))])
              ]),
              _c("g-select-language", {
                staticClass: "mt-4",
                attrs: {
                  items: _vm.state.project.languages,
                  rules: [_vm.$rules.required],
                  "no-data-text": "No languages added yet",
                  required: ""
                },
                model: {
                  value: _vm.language,
                  callback: function($$v) {
                    _vm.language = $$v
                  },
                  expression: "language"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "format-help pa-4 mb-4" },
        [_c("format-help")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }