<template>
  <div
    class="textarea-wrapper pa-4"
    style="min-height: 200px"
  >
    <v-layout style="justify-content: space-between">
      <g-inline-language-select
        v-model="translateTo"
        disable-default
        :label="$t('translate.to') + ':'"
      />

      <g-toggle-icon
        v-model="displayFilters"
        class="my-auto ml-1"
      >
        filter_list
      </g-toggle-icon>
    </v-layout>

    <v-expand-transition>
      <v-layout
        v-show="displayFilters"
        class="pt-4"
        wrap
      >
        <v-flex xs12>
          <v-label class="mb-0">Filter:</v-label>
        </v-flex>

        <v-checkbox
          v-model="filters.translatedInTargetLanguage"
          :label="`${$t('filters.translated_in')} ${translateToCode}`"
          class="mr-4 mt-0"
          @change="filters.untranslatedInTargetLanguage = false"
        />
        <v-checkbox
          v-model="filters.untranslatedInTargetLanguage"
          :label="`${$t('filters.untranslated_in')} ${translateToCode}`"
          class="mt-0"
          @change="filters.translatedInTargetLanguage = false"
        />
      </v-layout>
    </v-expand-transition>

    <v-label
      v-if="!asset.id"
      color="mt-4"
    >
      {{ $t('translate.not_found') }}.
      <a
        v-if="isFiltered"
        @click="resetFilters"
      >{{ $t('filters.reset') }}</a>
    </v-label>

    <!--    TODO fix multiline -->
    <!--    <v-textarea-->
    <!--      v-else-->
    <!--      v-model="translationValue"-->
    <!--      :placeholder="`${translateToCode} ${$t('translate.translation')}`"-->
    <!--      auto-grow-->
    <!--      solo-->
    <!--      flat-->
    <!--      :rows="2"-->
    <!--      class="my-4 textarea"-->
    <!--      @keypress="handleTextAreaKeyPress"-->
    <!--    />-->

    <v-text-field
      v-else
      v-model="translationValue"
      :placeholder="`${translateToCode} ${$t('translate.translation')}`"
      solo
      flat
      class="my-4 textarea"
      @keypress.enter="saveTranslation"
    />

    <div style="display: flex; justify-items: end; align-items: center">
      <v-checkbox
        v-model="nextOnSave"
        style="flex-grow: 1"
        :label="$t('translate.next_on_save')"
      />

      <v-btn
        class="ml-auto"
        icon
        @click="previousTranslation"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="nextTranslation"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <v-btn
        color="primary"
        outlined
        @click="saveTranslation"
      >
        {{
          nextOnSave
            ? $t('translate.save_and_next')
            : $t('actions.save')
        }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import store, { state } from '@/store';
import displayEnterTip from '@/utils/displayEnterTip';
import { getEventIsSubmitForm } from '@/utils/eventShortcuts';
import {
  handleErrors,
  showSavedNotification,
  showWarningNotification
} from '@/utils/notifications';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import MODELS from '@/constants/models';
import syncStateWithStorage from '@/mixins/sync-state-with-storage';
import client from '@/client';
import events from '@/events';

export default {
  name: 'TranslateTo',

  mixins: [syncStateWithStorage(['translateTo', 'currentPage', 'nextOnSave'])],

  props: { asset: { type: Object, default: () => MODELS.asset, required: false } },

  data() {
    return {
      translationValue: null, // Value of `To:` field
      displayFilters: false,
      nextOnSave: false,
      translateTo: null, // language ID
      filters: {
        translatedInTargetLanguage: false,
        untranslatedInTargetLanguage: false,
      },
      // to be able to go to next/previous translation
      currentPage: 0,
      amountOfItems: 1, // Total amount of items with the set filter
    }
  },

  computed: {
    isFiltered() {
      return this.filters.translatedInTargetLanguage || this.filters.untranslatedInTargetLanguage
    },

    translateToCode() {
      try {
        return state.languages.find(language => language.id === this.translateTo).code;
      } catch {
        return '';
      }
    },
  },

  watch: {
    translateTo() {
      this.setTranslationValue();
    },

    asset() {
      this.setTranslationValue();
    },

    filters: {
      handler() {
        this.fetchDebouncedTranslationByPage();
      },
      deep: true,
    },
  },

  created() {
    store.onStateIsFetched(() => {
      this.fetchTranslationByPage();

      // If current selected language is not a valid option, set to null
      if (!state.project.languages.map(({id}) => id).includes(this.translateTo)) {
        this.translateTo = null
      }

      try {
        // Set translateTo to something...
        this.translateTo = this.translateTo || state.project.languages[1].id;
      } catch (e) {}
    });
  },

  methods: {
    handleTextAreaKeyPress(event) {
      displayEnterTip();

      if (getEventIsSubmitForm(event)) {
        this.saveTranslation()
      }
    },

    async saveTranslation() {
      const translation = this.getTranslation(this.translateTo) || {};
      const { id } = translation;
      const hadTranslation = !!translation.value;
      const hasTranslation = !!this.translationValue;

      if (hadTranslation && !hasTranslation) {
        showWarningNotification(this.$t("translate.is_required"));
        return;
      }

      if (!hasTranslation) {
        this.nextOnSave && this.nextTranslation();
        return;
      }

      if (id) {
        await this.updateTranslation(id);
      } else {
        await this.createTranslation();
      }

      if (this.nextOnSave) {
        this.nextTranslation()
      } else {
        // update comments and history:
        this.$nextTick(() => {
          this.$nextTick(() => {
            events.savedTranslation.emit();
          });
        });
      }
    },

    async updateTranslation(id) {
      const { ok, errors } = await client.updateTranslation({
        id,
        value: this.translationValue,
      });
      if (!(ok)) {
        handleErrors(errors);
      } else {
        showSavedNotification()
      }
    },

    async createTranslation() {
      const { ok, errors, translation } = await client.createTranslation({
        asset: this.asset.id,
        language: this.translateTo,
        value: this.translationValue,
      });
      if (!ok) {
        handleErrors(errors);
        return
      }

      const translations = [...this.asset.translations, translation];
      const newAsset = Object.assign({}, this.asset, { translations });
      this.$emit("update:asset", newAsset);
    },

    resetFilters() {
      this.filters.translatedInTargetLanguage = false;
      this.filters.untranslatedInTargetLanguage = false;
    },

    getTranslation(languageId) {
      return this.asset.translations.find(({language}) => language.id === languageId);
    },

    setTranslationValue() {
      const translation = this.getTranslation(this.translateTo);
      if (translation) {
        this.$emit('set-translation-id', translation.id);
        this.translationValue = this.getTranslation(this.translateTo).value;
      } else {
        this.translationValue = "";
        this.$emit('set-translation-id', null);
      }
    },

    nextTranslation(params) {
      this.currentPage = this.currentPage + 1;

      if (this.currentPage >= this.amountOfItems) {
        this.currentPage = 0;
      }

      this.fetchTranslationByPage(params);
    },

    previousTranslation() {
      this.currentPage = this.currentPage - 1;

      if (this.currentPage < 0) {
        this.currentPage = this.amountOfItems - 1;
      }

      this.fetchTranslationByPage();
    },

    fetchTranslationByPage (attempts = 0) {
      if (attempts > 3) {
        this.$emit('update:asset', MODELS.asset);
        return;
      }

      store.onStateIsFetched(async () => {
        const vars = {
          limit: 1, // get 1 item
          offset: this.currentPage,
          projectId: state.project.id,
        };

        if (this.filters.translatedInTargetLanguage) {
          vars.translatedInLanguageId = this.translateTo;
        }

        if (this.filters.untranslatedInTargetLanguage) {
          vars.untranslatedInLanguageId = this.translateTo;
        }

        const { totalCount, results } = await client.getTranslateAsset(vars);
        const asset = results && results[0];
        this.$emit('update:asset', results[0]);

        if (totalCount) {
          this.amountOfItems = totalCount;
        }

        // if after filtering the totalCount is smaller, current page can be bigger
        // than totalCount. If so, select the last page available.
        if (this.currentPage > totalCount) {
          this.currentPage = totalCount - 1
        }

        if (asset) {
          this.$emit('update:asset', asset);
        } else {
          this.nextTranslation(attempts + 1);
        }
      });
    },

    fetchDebouncedTranslationByPage: debounce(function bounced() { this.fetchTranslationByPage(); }, 200),
  },
};
</script>
