var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("v-card", { staticClass: "ma-4" }, [_c("api-keys")], 1),
      _c(
        "v-card",
        { staticClass: "ma-4 pa-4" },
        [
          _c("h2", { staticClass: "title mb-4" }, [_vm._v("Getting started")]),
          _c("v-divider", { staticClass: "divider" }),
          _c("h1", { staticClass: "title" }, [_vm._v("Export data")]),
          _c("p", { staticClass: "mt-0 py-4 mb-0" }, [_vm._v("Curl Example:")]),
          _c("pre", { staticClass: "mb-4" }, [
            _c("code", { staticClass: "multiline" }, [
              _vm._v(
                "curl '" +
                  _vm._s(_vm.$apiUrl) +
                  "/export/xliff/EN-US?&include_empty=true&base_language=EN-US'\\\n     -H 'Authorization: Api-Key YOURSECRET --output EN-US.xliff'"
              )
            ])
          ]),
          _c("p", [
            _vm._v("Export files are directly available with "),
            _c("code", [_vm._v("GET")]),
            _vm._v(" requests. Simply...")
          ]),
          _c("ul", [
            _c("li", [
              _vm._v("Include your authorization header "),
              _c("code", [_vm._v("Authorization: Api-Key YOURSECRET")]),
              _vm._v(", these are always project-bound")
            ]),
            _c("li", [
              _vm._v(" Query the url "),
              _c("code", [_vm._v(_vm._s(_vm.$apiUrl) + "/export/po/ES-ES")]),
              _vm._v(", where "),
              _c("code", [_vm._v("ES-ES")]),
              _vm._v(" is the language code and "),
              _c("code", [_vm._v("po")]),
              _vm._v(" can be any of the file types: "),
              _c(
                "ul",
                _vm._l(_vm.fileFormats, function(format) {
                  return _c("li", { key: format }, [
                    _c("code", [_vm._v(_vm._s(format))])
                  ])
                }),
                0
              )
            ]),
            _c("li", [
              _vm._v("Optionally include the search parameter "),
              _c("code", [_vm._v("include_empty=true")]),
              _vm._v(" to include missing translations with an empty value")
            ]),
            _c("li", [
              _vm._v("Optionally include a base language "),
              _c("code", [_vm._v("base_language=NL-NL")]),
              _vm._v(" to use translation values as keys, instead of Asset IDs")
            ]),
            _c("li", [
              _vm._v("Optionally include a search parameter "),
              _c("code", [_vm._v("languages=AF,EN-US")]),
              _vm._v(
                " to export multiple languages. Leave out the language code in the URL. If you export multiple language files the response will be a zipped file."
              )
            ])
          ]),
          _c("v-divider", { staticClass: "divider" }),
          _c("h1", { staticClass: "title" }, [_vm._v("GraphQL API")]),
          _c("p", [
            _vm._v(" Our API uses "),
            _c(
              "a",
              { attrs: { href: "https://graphql.org/", target: "_blank" } },
              [_vm._v("GraphQL")]
            ),
            _vm._v(
              ". For some this might take some getting used to, but don't worry, you can simply give it a try in our "
            ),
            _c("a", { attrs: { href: _vm.$graphiqlUrl, target: "_blank" } }, [
              _vm._v("GraphiQL environment")
            ]),
            _vm._v(
              ". Here you can login with your email and password, and tryout most queries and mutations available for your API Key. "
            )
          ]),
          _c("p", [_vm._v("Use the following mutation to login:")]),
          _c("pre", { staticClass: "my-4" }, [
            _c("code", { staticClass: "multiline" }, [
              _vm._v(
                'mutation {\n  login(loginInput: { email: "YOUREMAIL", password:"YOURPASSWORD" }) {\n    ok\n    errors {\n      field\n      messages\n    }\n  }\n}'
              )
            ])
          ]),
          _c("p", [
            _vm._v(
              "And, for example, query all available assets with their translations:"
            )
          ]),
          _c("pre", { staticClass: "my-4" }, [
            _c("code", { staticClass: "multiline" }, [
              _vm._v(
                "{\n  assets {\n    id\n    value\n    translations {\n      value\n      languageCode\n    }\n  }\n}"
              )
            ])
          ]),
          _c(
            "a",
            { attrs: { href: _vm.graphiqlLink, target: "_blank" } },
            [
              _c("v-btn", { attrs: { color: "primary" } }, [
                _vm._v("Go to GraphiQL")
              ])
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "not_clear_link",
              on: { click: _vm.openMessageModal }
            },
            [_vm._v("Something not clear? Let us know")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }