var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._b(
      {
        attrs: {
          value: _vm.value,
          items: _vm.items,
          dense: "",
          "hide-details": ""
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(data) {
              return [_c("g-language-chip", { attrs: { language: data.item } })]
            }
          },
          {
            key: "selection",
            fn: function(data) {
              return [
                _c("g-language-chip", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: data.item.code,
                      expression: "data.item.code"
                    }
                  ],
                  attrs: { language: data.item }
                })
              ]
            }
          }
        ])
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }