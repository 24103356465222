export function hexToRgb(hex): [number, number, number] {
  // check if valid
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const resultHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(resultHex);

  if (result) {
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ];
  }
  return [0, 0, 0];
}

export function getContrastColor(color): string {
  const THRESHOLD = 192;
  const [r, g, b] = hexToRgb(color);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness > THRESHOLD ? '#000000' : '#ffffff';
}

export function getRandomColor() {
  const OPTIONS = [
    "#77dd77",
    "#836953",
    "#89cff0",
    "#99c5c4",
    "#9adedb",
    "#aa9499",
    "#aaf0d1",
    "#b2fba5",
    "#b39eb5",
    "#bdb0d0",
    "#bee7a5",
    "#befd73",
    "#c1c6fc",
    "#c6a4a4",
    "#cb99c9",
    "#cef0cc",
    "#cfcfc4",
    "#d6fffe",
    "#d8a1c4",
    "#dea5a4",
    "#deece1",
    "#dfd8e1",
    "#e5d9d3",
    "#e9d1bf",
    "#f49ac2",
    "#f4bfff",
    "#fdfd96",
    "#ff6961",
    "#ff964f",
    "#ff9899",
    "#ffb7ce",
    "#ca9bf7"
  ];
  const index = Math.round((OPTIONS.length - 1) * Math.random());
  return OPTIONS[index];
}
