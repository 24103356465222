const plans = [
  { text: 'Basic', value: 'basic_01', translations: 5000, users: 3 },
  { text: 'Pro', value: 'pro_01', translations: 50000, users: 10 },
  { text: 'Premium', value: 'premium_01', translations: 100000, users: 20 },
];

const valueToObj = {};
plans.forEach(plan => { valueToObj[plan.value] = plan });

export const getPlanObj = (value: string) => valueToObj[value] || {};
export const getPricingText = (value: string) => getPlanObj(value).text || '';
export const defaultPlan = plans[0].value;
export default plans;
