<template>
  <div class="pa-4">
    <v-subheader class="font-weight-light px-0">
      <span v-if="assetId">{{ $t('comments.asset_id_comments') }}</span>
      <span v-else>{{ $t('comments.translation_comments') }}</span>
    </v-subheader>
    <div
      v-if="comments && comments.length > 0"
      class="comments"
    >
      <transition
        v-for="(comment) in comments"
        :key="comment.id"
        appear
        name="slide-fade"
      >
        <div
          class="comment_wrapper"
          :class="{
            'current_user': comment.user.id === user.id,
            'other_user': comment.user.id !== user.id
          }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{on}">
              <v-avatar
                size="40"
                class="my-2"
                v-on="on"
              >
                <v-img
                  v-if="comment.user.imageUrl"
                  :src="comment.user.imageUrl"
                />
              </v-avatar>
            </template>
            <span>{{ comment.user.fullName }}</span>
          </v-tooltip>

          <p class="comment my-2">{{ comment.text }}</p>
        </div>
      </transition>
    </div>
    <div v-else>
      {{ $t('comments.not_found') }}
    </div>

    <v-textarea
      v-model="newCommentText"
      class="comment_area mt-4"
      :background-color="darkMode ? '#424242' : 'white'"
      solo
      flat
      hide-details
      @keypress="handleTextAreaKeyPress"
    />
    <v-btn
      class="over_textarea"
      :disabled="!newCommentText"
      text
      outlined
      @click="addComment"
    >
      {{ $t('comments.add') }}
    </v-btn>
  </div>
</template>

<script>
import { state } from "@/store";
import { handleErrors } from '../utils/notifications';
import { getEventIsSubmitForm } from '@/utils/eventShortcuts';
import displayEnterTip from '@/utils/displayEnterTip';
import client from '@/client';

export default {
  name: 'Comments',

  props: {
    assetId: { type: String, required: false, default: null },
    translationId: { type: String, required: false, default: null },
  },

  data() {
    return {
      commentsLoaded: false,
      newCommentText: '',
      comments: null,
      interval: null,
    }
  },

  computed: {
    darkMode() {
      return state.darkMode;
    },

    user() {
      return state.user;
    },
  },

  created() {
    this.setComments();
    this.interval = setInterval(this.setComments, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    handleTextAreaKeyPress(event) {
      displayEnterTip();
      if (getEventIsSubmitForm(event)) {
        this.addComment()
      }
    },

    async setComments() {
      if (this.assetId) {
        this.comments = await client.getAssetComments(this.assetId);
      } else if (this.translationId) {
        this.comments = await client.getTranslationComments(this.translationId);
      }

      this.commentsLoaded = true;
    },

    async addComment() {
      const text = this.newCommentText;
      this.newCommentText = '';
      if (!text) {
        return;
      }

      this.comments.push({
        text,
        user: this.user,
      });

      const { ok, errors } = await client.createComment({
        text,
        user: this.user.id,
        asset: this.assetId,
        translation: this.translationId,
      });
      if (!ok) {
        handleErrors(errors);
      }
    },
  },
};
</script>

<style lang="scss">
  $comment_color-light: white;
  $comment_color-dark: #424242;

  .theme--light {
    .comment, .comment::before {
      box-shadow: 0 0 6px rgba( $comment_color-light, .3);
      background-color: $comment_color-light !important;
    }
  }

  .theme--dark {
    .comment, .comment::before {
      box-shadow: 0 0 6px rgba( $comment_color-dark, .3);
      background-color: $comment_color-dark !important;
    }
  }

  .comment_area textarea {
    padding: 12px !important;
  }

  .comments {
    display: flex;
    flex-direction: column;
  }

  .comment_wrapper {
    display: flex;
    flex-direction: row;

    &.current_user {
      flex-direction: row-reverse;
    }
  }

  .comment {
    border-radius: 5px;
    padding: 10px 18px;
    position: relative;
    vertical-align: top;
    white-space: pre-line; // to keep whitespace from textarea
  }

  .comment::before {
    content: "\00a0";
    display: block;
    height: 16px;
    position: absolute;
    top: 11px;
    transform:             rotate( 29deg ) skew( -35deg );
    -moz-transform:    rotate( 29deg ) skew( -35deg );
    -ms-transform:     rotate( 29deg ) skew( -35deg );
    -o-transform:      rotate( 29deg ) skew( -35deg );
    -webkit-transform: rotate( 29deg ) skew( -35deg );
    width:  20px;
  }

  .current_user .comment {
    margin-right: 24px;
  }

  .current_user .comment::before {
    right: -9px;
  }

  .other_user .comment {
    margin-left: 24px;
  }

  .other_user .comment::before {
    left: -9px;
  }

  .over_textarea {
    margin-top: -48px;
    z-index: 12;
    margin-right: 12px;
    margin-left: auto;
    float: right;
  }
</style>
