<template>
  <div class="notifications">
    <v-alert
      v-for="(notification, key) in notifications"
      :key="key"
      :type="notification.type"
      class="notification"
      dismissible
      :value="notification.visible"
      transition="scroll-y-reverse-transition"
    >
      {{ notification.message }}
    </v-alert>
  </div>
</template>

<script>
import events from '@/events';

export default {
  data() {
    return {
      notifications: {},
    };
  },

  created() {
    events.notification.on((message, type, duration) => {
      const showTimeInMiliSeconds = duration;
      const key = this.$uuid.v4();
      const visible = false;
      const notification = { message, type, visible };

      // mount invisible
      this.$set(this.notifications, key, notification);

      // Animate visiblily = true
      setTimeout(() => { notification.visible = true; }, 100);

      // Animate visiblily = false
      setTimeout(() => { notification.visible = false; }, showTimeInMiliSeconds);

      // destroy component
      setTimeout(() => { this.$delete(this.notifications, key); }, showTimeInMiliSeconds + 2000);
    });
  },
};
</script>

<style lang="scss">
.notification {
  z-index: 999999;
  width: 500px;
  max-width: 90vw;
  margin:  auto 12px 12px auto;
}

.notifications {
  z-index: 999999;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  bottom: 12px;
  max-height: 30vh;
}
</style>
