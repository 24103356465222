















import events from '@/events';
import { LoadScreenPayload } from "@/utils/withLoadScreen";

function delay(delay) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

export default {
  name: 'LoadScreen',

  data() {
    return {
      opened: {} as Record<number, boolean>,
      loadScreens: {} as Record<number, LoadScreenPayload>,
    };
  },

  computed: {
    loadScreenList() {
      return Object.values(this.loadScreens)
    }
  },

  created() {
    this.$handleOff(events.openLoadScreen.on(this.handleOpenLoadScreen))
    this.$handleOff(events.closeLoadScreen.on(this.handleCloseLoadScreen))
  },

  methods: {
    handleOpenLoadScreen(payload: LoadScreenPayload) {
      this.$set(this.loadScreens, payload.id,  payload);
      this.$set(this.opened, payload.id,  true);
    },

    async handleCloseLoadScreen(payload: LoadScreenPayload) {
      this.opened[payload.id] = false
      await delay(250) // for animation
      delete this.loadScreens[payload.id]
    },
  }
};
