var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("formats.available_formats")))
    ]),
    _c("p", [
      _vm._v(
        " " +
          _vm._s(_vm.$t("formats.import_explanation")) +
          " " +
          _vm._s(_vm.$t("formats.available_formats")) +
          " "
      ),
      _c("a", { attrs: { href: "#nested-json" } }, [
        _vm._v(_vm._s(_vm.$t("formats.nested_json")))
      ]),
      _vm._v(" " + _vm._s(_vm.$t("global.and")) + " "),
      _c("a", { attrs: { href: "#flat-json" } }, [
        _vm._v(_vm._s(_vm.$t("formats.flat_json")))
      ])
    ]),
    _c("a", { on: { click: _vm.openMessageModal } }, [
      _vm._v(_vm._s(_vm.$t("formats.missing_a_format")))
    ]),
    _c("h4", { staticClass: "subtitle-1", attrs: { id: "nested-json" } }, [
      _vm._v(" " + _vm._s(_vm.$t("formats.nested_json")) + " ")
    ]),
    _vm._m(0),
    _c("h4", { staticClass: "subtitle-1", attrs: { id: "flat-json" } }, [
      _vm._v(" " + _vm._s(_vm.$t("formats.flat_json")) + " ")
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "code-wrap" }, [
      _c("pre", [
        _c("code", { staticClass: "multiline" }, [
          _vm._v(
            '\n{\n  "login": {\n    "form": {\n      "username": "Username",\n      "password": "Password"\n    },\n    "message": {\n      "success": "Login successful. Welcome back!",\n      "invalid_credentials": "Login failed: please check your credentials."\n    }\n  }\n}\n      '
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "code-wrap" }, [
      _c("pre", [
        _c("code", { staticClass: "multiline" }, [
          _vm._v(
            '\n{\n  "login.form.username": "Username",\n  "login.form.password": "Password",\n  "login.message.success" "Login successful. Welcome back!",\n  "login.message.invalid_credentials": "Login failed: check your credentials.",\n}\n    '
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }