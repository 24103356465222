var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-select",
        {
          staticClass: "inline-select",
          attrs: {
            value: _vm.value,
            items: _vm.options,
            "item-value": "id",
            "hide-details": "",
            flat: "",
            dense: "",
            solo: "",
            multiple: "",
            "background-color": "transparent"
          },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function(data) {
                return [
                  _c(
                    "span",
                    [
                      _c("tag", {
                        attrs: {
                          tag: data.item,
                          small: _vm.small,
                          "x-small": _vm.xSmall
                        }
                      }),
                      _vm.value.includes(data.item.id)
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "ml-2",
                              attrs: { small: _vm.small, "x-small": _vm.xSmall }
                            },
                            [_vm._v("close")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "selection",
              fn: function(data) {
                return [
                  _c("tag", {
                    attrs: {
                      tag: data.item,
                      small: _vm.small,
                      "x-small": _vm.xSmall
                    }
                  })
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "label" }, [
            _c(
              "p",
              { style: "cursor: pointer; font-size: " + _vm.fontSize + "px" },
              [_vm._v(_vm._s(_vm.$t("tags.add")))]
            )
          ]),
          _c("template", { slot: "append-item" }, [
            _c(
              "span",
              {
                staticClass: "px-4",
                staticStyle: { cursor: "pointer", "font-size": "12px" },
                on: {
                  click: function($event) {
                    _vm.showAddNewTagModal = true
                  }
                }
              },
              [
                _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                _vm._v(" " + _vm._s(_vm.$t("tags.add_text")) + " ")
              ],
              1
            )
          ]),
          _c("template", { slot: "no-data" }, [_c("span")])
        ],
        2
      ),
      _vm.showAddNewTagModal
        ? _c("add-tag-modal", {
            model: {
              value: _vm.showAddNewTagModal,
              callback: function($$v) {
                _vm.showAddNewTagModal = $$v
              },
              expression: "showAddNewTagModal"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }