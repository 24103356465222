import Vue from 'vue';
// @ts-ignore
import Vuetify from 'vuetify/lib';
import { getDarkMode } from '@/utils/darkMode';

Vue.use(Vuetify);

const darkThemeContent = "#7ec6f7";

import nl from 'vuetify/src/locale/nl'
import en from 'vuetify/src/locale/en'

export default new Vuetify({
  lang: {
    locales: { nl, en },
    current: 'en',
  },

  theme: {
    dark: getDarkMode(),

    themes: {
      light: {
        primary: "#3f51b5",
        secondary: "#6bb3d4",
        accent: "#00bcd4",
        error: "#f44336",
        warning: "#ffc107",
        info: "#00bcd4",
        success:"#4caf50",
        anchor:"#3f51b5"
      },
      dark: {
        primary: "#4684b2",
        secondary: darkThemeContent,
        accent: darkThemeContent, // mainly used for checkbox colors etc
        error: "#ca3729",
        warning: "#bf9504",
        info: "#4b5adb",
        success:"#4fb754",
        anchor: darkThemeContent,
      },
    },
  },
});
