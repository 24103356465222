<template>
  <router-view v-if="state.project.id" />
</template>

<script>
import store, { state } from '@/store';
import { handleErrors } from '../utils/notifications';
import { getLanguageCodeFromBrowser } from '@/utils/language';
import client from '@/client';
import events from '@/events';

export default {
  name: 'ProjectWrapper',

  data() {
    return {
      state,
    };
  },

  computed: {
    currentProjectSlug() {
      return this.$route.params.projectSlug;
    },
  },

  watch: {
    currentProjectSlug() {
      this.setProjectData();
    },
  },

  created() {
    this.setProjectData();
  },

  beforeDestroy() {
    store.resetState('project')
  },

  methods: {
    async setProjectData() {
      if (!this.currentProjectSlug) {
        return;
      }

      if (this.currentProjectSlug === 'new-project') {
        this.createNewProject();
        return;
      }

      store.setProject(await client.getProject(this.currentProjectSlug));
    },

    async createNewProject() {
      store.resetState('project');
      const { project, ok, errors } = await client.createProject(this.$i18n.locale);
      if (!ok) {
        handleErrors(errors);
        return;
      }

      store.addProject(project);
      await this.$router.push({
        params: { projectSlug: project.slug },
      });
      events.refetchState.emit();
    },
  },
};
</script>
