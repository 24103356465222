/// <reference types="typed-graphql" />
import gql from 'graphql-tag';

const fragments = {
  language: gql`
    fragment languageFields on LanguageType {
      id
      code
      imageUrl
      type
    }
  `,

  tag: gql`
    fragment tagFields on TagType {
      id
      color
      text
    }
  `,

  comment: gql`
    fragment commentFields on CommentType {
      id
      text
      user {
        id
        fullName
        imageUrl
      }
    }
  `,

  assetHistory: gql`
    fragment assetHistoryFields on AssetHistoryType {
      id
      oldValue
      value
      byImport
      timestamp

      byApiKey {
        name
      }

      byUser {
        imageUrl
        fullName
      }
    }
  `,

  translationHistory: gql`
    fragment translationHistoryFields on TranslationHistoryType {
      id
      oldValue
      value
      byImport
      byAutomatedTranslation
      timestamp

      byApiKey {
        name
      }

      byUser {
        imageUrl
        fullName
      }
    }
  `,

  project: gql`
    fragment projectFields on ProjectType {
      name
      imageUrl
      slug
      id
    }
  `,

  exportedData: gql`
    fragment exportedDataFields on ExportedDataType {
      createdOnTimestamp
      url
      type
      name
    }
  `,

  asset: gql`
    fragment assetFields on AssetType {
      id
      value
      description
    }
  `,

  stats: gql`
    fragment statsFields on StatsType {
      users
      assets
      languages
      translated
    }
  `,

  usage: gql`
    fragment usageFields on UsageType {
      machineTranslationsLimit
      machineTranslationsLeft
    }
  `,

  publicUser: gql`
    fragment publicUserFields on UserType {
      fullName
      email
      id
      lastOnline
      imageUrl
      thumbnailImageUrl
      isOnline
      lastOnlineTimestamp
    }
  `,

  machineTranslationOrderDetails: null,
  projectDetail: null,
  user: null,
  machineTranslationsOrder: null,
};

fragments.machineTranslationsOrder =   gql`
  fragment machineTranslationsOrderFields on MachineTranslationOrderType {
    createdTimestamp
    count
    status
    id
    source { id }
    to { id }
  }
`;

fragments.machineTranslationOrderDetails = gql`
  fragment machineTranslationOrderDetailsFields on MachineTranslationOrderType {
    byUser {
      ...publicUserFields
    }
    count
    created
    createdTimestamp
    id
    status
    source { id }
    to { id }
    translations {
      fromValue
      toValue
    }
  }
  ${fragments.publicUser}
`;

fragments.projectDetail = gql`
  fragment projectDetailFields on ProjectType {
    ...projectFields
    description
    plan
    trailExpiryDate
    languages {
      ...languageFields
    }
    tags {
      ...tagFields
    }
    users {
      ...publicUserFields
    }
  }
  ${fragments.project}
  ${fragments.language}
  ${fragments.publicUser}
  ${fragments.tag}
`;

fragments.user = gql`
    fragment userFields on UserType {
      imageUrl
      lastOnlineTimestamp
      username
      email
      id
      fullName
      firstName
      lastName
      locale
      isPasswordSet

      roles {
        project {
          id
          slug
        }
        roles
      }

      projects (isDeleted: false) {
        ...projectFields
      }
    }

    ${fragments.project}
`;

export default fragments;
