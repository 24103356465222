<template>
  <div>
    <h3 class="title">{{ $t('formats.available_formats') }}</h3>

    <p>
      {{ $t('formats.import_explanation') }}
      {{ $t('formats.available_formats') }}
      <a href="#nested-json">{{ $t('formats.nested_json') }}</a> {{ $t('global.and') }}
      <a href="#flat-json">{{ $t('formats.flat_json') }}</a>
    </p>

    <a @click="openMessageModal">{{ $t('formats.missing_a_format') }}</a>

    <h4
      id="nested-json"
      class="subtitle-1"
    >
      {{ $t('formats.nested_json') }}
    </h4>
    <div class="code-wrap">
      <pre><code class="multiline">
{
  "login": {
    "form": {
      "username": "Username",
      "password": "Password"
    },
    "message": {
      "success": "Login successful. Welcome back!",
      "invalid_credentials": "Login failed: please check your credentials."
    }
  }
}
      </code></pre>
    </div>

    <h4
      id="flat-json"
      class="subtitle-1"
    >
      {{ $t('formats.flat_json') }}
    </h4>
    <div class="code-wrap">
      <pre><code class="multiline">
{
  "login.form.username": "Username",
  "login.form.password": "Password",
  "login.message.success" "Login successful. Welcome back!",
  "login.message.invalid_credentials": "Login failed: check your credentials.",
}
    </code></pre>
    </div>
  </div>
</template>

<script>
import events from '@/events';

export default {
  name: 'FormatHelp',

  methods: {
    openMessageModal() {
      events.openMessageModal.emit();
    }
  },
};
</script>

<style scoped>
  .subtitle-1 {
    font-weight: bold !important;
    margin-top: 30px;
  }
</style>
