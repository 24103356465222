var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-4" },
    [
      _c("v-subheader", { staticClass: "font-weight-light px-0" }, [
        _vm.assetId
          ? _c("span", [_vm._v(_vm._s(_vm.$t("comments.asset_id_comments")))])
          : _c("span", [
              _vm._v(_vm._s(_vm.$t("comments.translation_comments")))
            ])
      ]),
      _vm.comments && _vm.comments.length > 0
        ? _c(
            "div",
            { staticClass: "comments" },
            _vm._l(_vm.comments, function(comment) {
              return _c(
                "transition",
                { key: comment.id, attrs: { appear: "", name: "slide-fade" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "comment_wrapper",
                      class: {
                        current_user: comment.user.id === _vm.user.id,
                        other_user: comment.user.id !== _vm.user.id
                      }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-avatar",
                                      _vm._g(
                                        {
                                          staticClass: "my-2",
                                          attrs: { size: "40" }
                                        },
                                        on
                                      ),
                                      [
                                        comment.user.imageUrl
                                          ? _c("v-img", {
                                              attrs: {
                                                src: comment.user.imageUrl
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(comment.user.fullName))])]
                      ),
                      _c("p", { staticClass: "comment my-2" }, [
                        _vm._v(_vm._s(comment.text))
                      ])
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        : _c("div", [_vm._v(" " + _vm._s(_vm.$t("comments.not_found")) + " ")]),
      _c("v-textarea", {
        staticClass: "comment_area mt-4",
        attrs: {
          "background-color": _vm.darkMode ? "#424242" : "white",
          solo: "",
          flat: "",
          "hide-details": ""
        },
        on: { keypress: _vm.handleTextAreaKeyPress },
        model: {
          value: _vm.newCommentText,
          callback: function($$v) {
            _vm.newCommentText = $$v
          },
          expression: "newCommentText"
        }
      }),
      _c(
        "v-btn",
        {
          staticClass: "over_textarea",
          attrs: { disabled: !_vm.newCommentText, text: "", outlined: "" },
          on: { click: _vm.addComment }
        },
        [_vm._v(" " + _vm._s(_vm.$t("comments.add")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }