var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: { label: _vm.$t("filters.search") },
        model: {
          value: _vm.search,
          callback: function($$v) {
            _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "search"
        }
      }),
      _vm._l(_vm.pagedLanguageOptions, function(language) {
        return _c(
          "div",
          { key: language.id, staticClass: "flag-chip-wrapper" },
          [
            _c("g-language-chip", {
              staticClass: "v-card--hover",
              attrs: { language: language },
              on: {
                click: function($event) {
                  return _vm.toggleLanguage(language)
                }
              }
            })
          ],
          1
        )
      }),
      _c("v-pagination", {
        staticClass: "mt-4 mb-2",
        attrs: { length: _vm.amountOfPages },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }