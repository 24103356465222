var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "px-4 py-1",
      staticStyle: { display: "flex", "flex-direction": "column" }
    },
    [
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "flex-start" } },
        [
          _c("g-inline-language-select", {
            attrs: {
              items: _vm.state.project.languages,
              "disable-default": "",
              label: _vm.$t("translate.from") + ":"
            },
            model: {
              value: _vm.translateFrom,
              callback: function($$v) {
                _vm.translateFrom = $$v
              },
              expression: "translateFrom"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "py-2",
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            height: "100%",
            "flex-grow": "1"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "min-height": "160px"
              }
            },
            [
              _vm.translateFromValue
                ? _c("h3", { staticClass: "subtitle-1 font-italic" }, [
                    _vm._v(" " + _vm._s(_vm.translateFromValue) + " ")
                  ])
                : _c("v-label", [
                    _c(
                      "span",
                      {
                        staticClass: "font-italic",
                        staticStyle: { opacity: "0.5" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("translate.no_from_value")) + " "
                        )
                      ]
                    )
                  ]),
              _c("v-autocomplete", {
                staticClass: "text-with-select-on-click",
                staticStyle: {
                  "margin-left": "-12px",
                  "flex-grow": "unset",
                  "flex-shrink": "1"
                },
                attrs: {
                  value: _vm.selectedAssetId || _vm.asset.id,
                  items: _vm.assetSearchResult,
                  "item-text": "value",
                  "item-value": "id",
                  "hide-details": "",
                  flat: "",
                  dense: "",
                  solo: "",
                  "no-filter": "",
                  "no-data-text": _vm.loadingAssetSearch
                    ? "Loading"
                    : _vm.assetSearch
                    ? "No assets found"
                    : "No assets available",
                  loading: !!(_vm.assetSearch && _vm.loadingAssetSearch),
                  placeholder: _vm.$t("filters.search"),
                  "search-input": _vm.assetSearch
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.assetSearch = $event
                  },
                  "update:search-input": function($event) {
                    _vm.assetSearch = $event
                  },
                  focus: function($event) {
                    _vm.assetSearch = ""
                  },
                  focusout: function($event) {
                    _vm.assetSearch = null
                  },
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return (function() {})($event)
                  },
                  input: _vm.selectAssetId
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var selected = ref.selected
                      return [
                        !selected && _vm.assetSearch === null && _vm.asset.value
                          ? _c(
                              "v-label",
                              { staticStyle: { cursor: "pointer" } },
                              [
                                _vm._v(" " + _vm._s(_vm.asset.value) + " "),
                                _c("a", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.$t("translate.change_asset_id")
                                      ) +
                                      ")"
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("div", { staticClass: "description_scroll" }, [
                _vm.asset.description
                  ? _c(
                      "p",
                      {
                        staticClass: "py-4 subtitle-2",
                        staticStyle: {
                          "text-align": "justify",
                          "white-space": "pre-line"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.asset.description) + " ")]
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }