var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "locale" } },
    [
      _vm.state.isMobile ? _c("mobile-navigation") : _c("sidebar"),
      _c("v-content", [_c("router-view")], 1),
      _c("load-screen"),
      _c("notifications"),
      _c("user-message-dialog"),
      _c("confirm-delete-dialog"),
      _c("login-dialog", { key: this.$router.currentRoute.name }),
      _vm.state.showChangePassword ? _c("set-password-modal") : _vm._e(),
      _c("portal-target", { attrs: { name: "modal" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }