<template>
  <v-chip
    v-if="languageObj"
    class="tiny-chip pa-0 pr-2 ma-1"
    outlined
    v-bind="$attrs"
    @click="$emit('click')"
    @input="$emit('input')"
  >
    <v-img
      v-if="languageObj.imageUrl"
      eager
      class="flag mr-2"
      :src="languageObj.imageUrl"
    />
    {{ languageObj.code.toUpperCase() }}
    <slot />
  </v-chip>
</template>

<script>
import { state } from "@/store"

export default {
  name: 'GLanguageChip',

  props: {
    language: Object,
    languageId: String,
  },

  data() {
    return {
      state,
    }
  },

  computed: {
    languageObj () {
      if (this.language) {
        return this.language;
      }

      if (this.state.project && this.state.project.languages && this.languageId) {
        return this.state.languages.find(({id}) => id === this.languageId)
      }

      return null
    }
  },
};
</script>

<style lang="scss" scoped>
  .flag {
    border-radius: 8px 0 0 8px;
    height: 20px;
    max-width: 30px;
    object-fit: cover;
  }
</style>
