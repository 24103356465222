<template>
  <div
    v-touch="{
      left: () => smallSidebar = true,
      right: () => smallSidebar = false
    }"
    class="drawer-wrapper"
    :class="{'collapsed': smallSidebar}"
  >
    <v-navigation-drawer
      style="min-height: 640px;"
      class="drawer pa-2"
      :class="{'collapsed': smallSidebar}"
      :mini-variant="smallSidebar"
      fixed
      permanent
      dark
      stateless
      app
    >
      <div
        class="sidebar-toggle"
        :class="{'collapsed': smallSidebar}"
      >
        <div class="sidebar-toggle--inner">
          <v-btn
            icon
            class="sidebar-toggle--button"
            @click="smallSidebar = !smallSidebar"
          >
            <v-icon>
              {{
                smallSidebar
                  ? 'mdi-chevron-right'
                  : 'mdi-chevron-left'
              }}
            </v-icon>
          </v-btn>
        </div>
      </div>

      <v-list
        dark
        class="my-0 sidebar"
      >
        <v-list-item
          v-if="state.loggedIn"
          :to="{name: 'home'}"
          exact
          active-class="hide"
        >
          <v-list-item-action class="white--text">
            <v-avatar size="30">
              <v-img
                v-if="state.project.imageUrl"
                :src="state.project.imageUrl"
              />
            </v-avatar>
          </v-list-item-action>

          <v-list-item-content
            class="white--text"
            style="text-overflow: ellipsis"
          >
            <v-list-item-title>
              <h3 class="capitalize_first_letter">{{ state.project.name }}</h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="state.loggedIn"
          exact
          :to="{name: 'home'}"
        >
          <v-list-item-action class="white--text">
            <v-avatar size="30">
              <v-img
                v-if="state.user.imageUrl"
                :src="state.user.imageUrl"
              />
            </v-avatar>
          </v-list-item-action>

          <v-list-item-content class="white--text">
            <v-list-item-title>
              <h3>{{ state.user.firstName }}</h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="$route.params.projectSlug">
          <v-list-item
            v-for="route in availableRoutes"
            :key="route.name"
            :to="{name: route.name}"
          >
            <v-list-item-action
              class="white--text"
              style="padding:4px;"
            >
              <v-icon
                v-if="route.icon"
                medium
              >
                {{ route.icon }}
              </v-icon>
            </v-list-item-action>

            <v-list-item-content
              v-if="route.title"
              class="white--text"
            >
              <v-list-item-title>
                <h3>{{ route.title }}</h3>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item
          class="chat-item"
          :class="{'v-list-item--active': chat}"
          @click="chat = !chat"
        >
          <v-list-item-action
            class="white--text"
            style="padding:4px;"
          >
            <v-icon medium>chat</v-icon>
          </v-list-item-action>

          <v-list-item-content class="white--text">
            <v-list-item-title>
              <h3>{{ $t('global.chat') }}</h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="logout-item"
          @click="logout"
        >
          <v-list-item-action
            class="white--text"
            style="padding:4px;"
          >
            <v-icon medium>logout</v-icon>
          </v-list-item-action>

          <v-list-item-content class="white--text">
            <v-list-item-title>
              <h3>{{ $t('authentication.logout') }}</h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import Touch from 'vuetify/es5/directives/touch';
import { state } from '@/store';
import { logout } from '../../utils/auth';
import getRoutePermissions from '../../utils/getRoutePermissions';
import chatclient from '@/utils/chatclient';
import syncStateWithStorage from '@/mixins/sync-state-with-storage';

export default {
  mixins: [syncStateWithStorage(['smallSidebar', 'chat'])],

  directives: {
    Touch,
  },

  data() {
    return {
      state,
      smallSidebar: true,
      chat: false,
    };
  },

  computed: {
    availableRoutes() {
      const { projectSlug } = this.$route.params;

      return this.$router.options.routes
        .find(route => route.name === 'project-wrapper').children
        .filter(route => getRoutePermissions(route, projectSlug));
    },
  },

  watch: {
    chat() {
      this.setChat();
    },
  },

  mounted() {
    this.setChat();
    chatclient.addClosedCallback(() => {
      this.chat = false;
    });
  },

  methods: {
    setChat() {
      if (this.chat) {
        chatclient.show()
      } else {
        chatclient.hide()
      }
    },

    logout,
  },
};
</script>

<style lang="scss" scoped>
  .v-list-item__title h3 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hide {
    display: none;
  }

  .logout-item {
    position: absolute;
    bottom: 12px;
  }

  .chat-item {
    position: absolute;
    bottom: 69px;
  }

  .sidebar {
    .v-list__tile__action {
      width: 60px;
    }

    .v-list__tile {
      height: 55px;
    }
  }

  .sidebar-toggle {
    z-index: 9;
    top: 0;
    bottom: 0;
    position: absolute;
    right: -20px;
  }

  .sidebar-toggle--inner {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .sidebar-toggle--button {
    width: 40px;
    height: 40px;
    background-color: #4684b2;
    z-index: 9;
    margin: auto;
  }

  .v-navigation-drawer, .v-navigation-drawer__content {
    // display collapse/expand button
    overflow-x: visible !important;
    overflow: visible !important;
  }

  .drawer-wrapper {
    // Device can hover:
    @media (hover: hover) {
      .sidebar-toggle {
        opacity: 0;
        transition: visibility 0s linear 0s, opacity 200ms;
      }

      &:hover .sidebar-toggle {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 1024px) and (min-width: 600px) {
    .drawer-wrapper {
      z-index: 15;
      position: fixed;
      width: 80px;
      overflow: visible;
    }
  }

  @media only screen and (max-width: 600px) {
    .drawer-wrapper {
      z-index: 15;
      position: fixed;
      overflow: visible;
    }

    .v-navigation-drawer {
      &.collapsed {
        width: 0 !important;
        padding-right: 0 !important;
        padding-left: 0 !important;

        .sidebar-toggle {
          right: -24px;
        }
      }
    }
  }
</style>
