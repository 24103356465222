<template>
  <v-text-field
    :value="value"
    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
    prepend-icon="vpn_key"
    :rules="$validation.password"
    validate-on-blur
    :type="showPassword ? 'text' : 'password'"
    :label="$t('authentication.login_form.password')"
    counter
    v-bind="$attrs"
    @input="$emit('input', $event)"
    @click:append="showPassword = !showPassword"
  />
</template>

<script>
export default {
  name: 'PasswordField',

  props: {
    value: String,
  },

  data() {
    return {
      showPassword: false,
    }
  },
};
</script>
