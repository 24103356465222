var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-4",
      staticStyle: { "max-width": "1000px", margin: "auto" }
    },
    [
      _c("new-order-modal", {
        model: {
          value: _vm.addModal,
          callback: function($$v) {
            _vm.addModal = $$v
          },
          expression: "addModal"
        }
      }),
      _vm.selectedId
        ? _c("order-info-modal", {
            attrs: { id: _vm.selectedId },
            on: {
              input: function($event) {
                _vm.selectedId = null
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mb-4",
          staticStyle: { display: "flex", "justify-content": "end" }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  _vm.addModal = true
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orders.new_machine_translations_order")) +
                  " "
              ),
              _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items || [],
          "hide-default-footer": "",
          options: { itemsPerPage: 500 },
          "mobile-breakpoint": 1
        },
        on: { "click:row": _vm.selectItem },
        scopedSlots: _vm._u([
          {
            key: "item.source",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("g-language-chip", {
                  attrs: { "language-id": item.source.id }
                })
              ]
            }
          },
          {
            key: "item.to",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("g-language-chip", { attrs: { "language-id": item.to.id } })
              ]
            }
          },
          {
            key: "item.createdTimestamp",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm.timeAgoInWords(item.createdTimestamp)) + " "
                )
              ]
            }
          }
        ])
      }),
      _c(
        "p",
        {
          staticClass: "py-4 mt-4",
          staticStyle: { margin: "auto", opacity: ".6" }
        },
        [_vm._v(" " + _vm._s(_vm.$t("orders.machine_order_explanation")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }