var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    { attrs: { icon: "" } },
    [
      _vm.state.darkMode
        ? _c("v-icon", { attrs: { small: "" }, on: { click: _vm.setLight } }, [
            _vm._v(" mdi-moon-waning-crescent ")
          ])
        : _c("v-icon", { attrs: { small: "" }, on: { click: _vm.setDark } }, [
            _vm._v(" mdi-white-balance-sunny ")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }